import * as Realm from 'realm-web';

// TODO: Add your Stitch app's App ID
const APP_ID =
   process.env.REACT_APP_REALM_APP_ID || 'pathways_aco-swqzz';

// TODO: Initialize the app client
//const app = Stitch.hasAppClient(APP_ID)
//  ? Stitch.getAppClient(APP_ID)
//  : Stitch.initializeAppClient(APP_ID);
const app = new Realm.App({ id: APP_ID });

export { app };
