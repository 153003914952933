import React, { useState, useMemo } from 'react';
import { Grid } from 'semantic-ui-react';
import { useStitchAuth } from '../../StitchAuth';
import RegisterContactForm from './RegisterContactForm';
import RegisterAboutForm from './RegisterAboutForm';
import RegisterLoginForm from './RegisterLoginForm';
import RegisterSingleSuccessModal from './RegisterSingleSuccessModal';
import RegisterSingleFailureModal from './RegisterSingleFailureModal';
import {
   PERSONA,
   USER_ROLES,
} from '../../Common/Constants/UserConstants';
import '../../Common/Layout.css';

const gridStyle = {
   margin: 0,
   marginTop: '-70px',
   paddingBottom: '5em',
   paddingLeft: '2em',
};

const RegisterSingleUser = () => {
   const initialUserValue = {
      firstName: '',
      lastName: '',
      email: '',
      npi: '',
      password: '',
      phoneNumber: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      namePublic: '',
      newTitle: '',
      tin: '',
      personas: PERSONA.PROVIDER, // Default to provider persona for ACO app
   };
   const [newUser, setNewUser] = useState(initialUserValue);

   const {
      userCustomData,
      actions: { handleSingleSignup },
   } = useStitchAuth();
   const [registerSuccess, setRegisterSuccess] = useState(false);
   const [registerFailure, setRegisterFailure] = useState(false);
   const [processing, setProcessing] = useState(false);

   const [newUserPasswordError, setNewUserPasswordError] =
      useState(false);
   const [newUserEmailError, setNewUserEmailError] = useState(false);
   const [firstNameError, setFirstNameError] = useState(false);
   const [lastNameError, setLastNameError] = useState(false);
   const [personasError, setPersonasError] = useState(false);
   const [npiError, setNpiError] = useState(false);
   const [tinError, setTinError] = useState(false);
   const showPersonaDropdown = useMemo(() => {
      return userCustomData?.role === USER_ROLES.ADMIN;
   }, [userCustomData]);

   const refreshState = () => {
      setNewUser(initialUserValue);
      setRegisterSuccess(false);
   };

   const validateForm = () => {
      let valid = true;
      if (newUser.firstName === '') {
         setFirstNameError(true);
      }
      if (newUser.lastName === '') {
         setLastNameError(true);
         valid = false;
      }
      if (newUser.email === '') {
         setNewUserEmailError(true);
         valid = false;
      }
      if (newUser.password.length < 8) {
         setNewUserPasswordError(true);
         valid = false;
      }
      if (newUser.personas === '') {
         setPersonasError(true);
         valid = false;
      }
      // check NPI & TIN is filled out if persona registered is Provider
      if (
         newUser.personas === PERSONA.PROVIDER &&
         newUser.npi.length !== 10
      ) {
         setNpiError(true);
         valid = false;
      }
      if (
         (newUser.personas === PERSONA.PROVIDER ||
            newUser.personas === PERSONA.OFFICE_MANAGER) &&
         newUser.tin.length !== 9
      ) {
         setTinError(true);
         valid = false;
      }

      return valid;
   };

   const handleAddAccount = async () => {
      if (!validateForm()) {
      } else {
         setProcessing(true);

         const userData = {
            name: newUser.firstName + ' ' + newUser.lastName,
            namePublic:
               newUser.personas === PERSONA.PROVIDER
                  ? `Dr. ${newUser.lastName}`
                  : newUser.firstName + ' ' + newUser.lastName,
            timeZone: userCustomData.timeZone,
            role: 'user',
            group_id: userCustomData.group_id,
            emailNotifications: {
               periodicAP: true,
               periodicMP: false,
               realtimeAP: false,
               realtimeMP: false,
            },
            email: newUser.email,
            npi: newUser.npi,
            phoneNumber: newUser.phoneNumber
               ? '+1' + newUser.phoneNumber.replace(/-/g, '')
               : '',
            address: {
               line1: newUser.address1,
               line2: newUser.address2,
               city: newUser.city,
               state: newUser.state,
               zipCode: newUser.zipCode,
            },
            tin: newUser.tin,
            personas: [newUser.personas],
            currentPersona: '',
            enrollmentDate: new Date().toISOString(),
            office: {},
            practiceManager: {},
         };

         const res = await handleSingleSignup(
            userData,
            newUser.password,
         );

         if (res.success) {
            setProcessing(false);
            setRegisterSuccess(true);
         } else {
            setProcessing(false);
            setRegisterFailure(true);
         }
      }
   };

   return (
      <>
         <div style={{ gridColumn: 3, color: '#333' }}>
            <Grid style={gridStyle}>
               <Grid.Row>
                  <RegisterAboutForm
                     newUser={newUser}
                     setNewUser={setNewUser}
                     firstNameError={firstNameError}
                     setFirstNameError={setFirstNameError}
                     lastNameError={lastNameError}
                     setLastNameError={setLastNameError}
                     personasError={personasError}
                     setPersonasError={setPersonasError}
                     npiError={npiError}
                     setNpiError={setNpiError}
                     tinError={tinError}
                     setTinError={setTinError}
                     showPersonaDropdown={showPersonaDropdown}
                  />
                  <RegisterContactForm
                     newUser={newUser}
                     setNewUser={setNewUser}
                  />
               </Grid.Row>
               <RegisterLoginForm
                  newUser={newUser}
                  setNewUser={setNewUser}
                  handleAddAccount={handleAddAccount}
                  processing={processing}
                  newUserPasswordError={newUserPasswordError}
                  setNewUserPasswordError={setNewUserPasswordError}
                  newUserEmailError={newUserEmailError}
                  setNewUserEmailError={setNewUserEmailError}
               />
            </Grid>
         </div>
         <RegisterSingleSuccessModal
            refreshState={refreshState}
            newUser={newUser}
            registerSuccess={registerSuccess}
         />
         <RegisterSingleFailureModal
            refreshState={() => setRegisterFailure(false)}
            registerFailure={registerFailure}
         />
      </>
   );
};

export default RegisterSingleUser;
