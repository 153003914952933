import moment from 'moment';

export const UNIX_TIMESTAMP_SEC_TO_MS = 1000;

export const getRelativeDateString = (
   userCustomData,
   utcTimeString,
) => {
   return moment(utcTimeString)
      .tz(userCustomData.timeZone)
      .calendar(null, {
         sameDay: '[Today] LT', // Today at 2:30 AM
         lastDay: '[Yesterday] LT', // Yesterday at 2:30 AM
         sameElse: 'MM/DD/YYYY',
         nextDay: '[Tomorrow] LT',
         nextWeek: '[in] d [days]',
      });
};

/*
 * @description gets the date from a week ago using today's date
 * @returns Tue Jul 25 2023 00:00:00 GMT-0400 (Eastern Daylight Time)
 */
export const getLastWeekFromToday = () => {
   const now = new Date();

   return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 7,
   );
};

/*
 * @description gets the dateTime 30days from today's date
 * @returns "2023-07-04T17:38:35.461Z" Ex. 07-04-23 from 08-03-23
 */
export const get30daysFromToday = () => {
   const tmpDate = new Date();
   tmpDate.setDate(tmpDate.getDate() - 30);
   return {
      today: new Date().toISOString(),
      last30DaysDate: tmpDate.toISOString(),
   };
};

/*
 * Returns current year
 * @returns {string} currentYear - e.g. "2023"
 */
export const getCurrentYear = () => {
   return new Date().getFullYear();
};

/*
 * Returns PREVIOUS year
 * @returns {string} prevYear - e.g. "2022"
 */
export const getPreviousYear = () => {
   return getCurrentYear() - 1;
};

export const DATE_TIME_FORMATS = Object.freeze({
   DEFAULT: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
   MINIMAL_DATE: 'M/D/YY',
   SHORT_DATE: 'MM/DD/YYYY',
   SHORT_DATE_TIME: 'MM/DD/YYYY hh:mm A',
   SHORT_DATE_TIME_REVERSE: 'hh:mm A MM/DD/YYYY',
   LONG_DATE_NO_COMMA: 'MMMM D YYYY',
   LONG_DATE: 'MMMM D, YYYY',
   LONG_DATE_TIME: 'MMMM D, YYYY hh:mm A',
   SHORT_TIME_SPACE: 'hh:mm A',
   TIME: 'HH:mm:ss',
   SAME_DAY: '[Today at] LT',
   LAST_DAY: '[Yesterday at] LT',
   LAST_WEEK: '[last] dddd',
   SAME_ELSE: 'MM/DD/YYYY LT',
   NEXT_DAY: '[Tomorrow at] LT',
});

/**
 *
 * @param {Moment | Date | string | number | (number | string)[] | MomentInputObject | null | undefined} dateTimeString -
 * Represents date and time, can be any valid input for moment.
 * Pathways DateTime strings are usually ISO 8601 in UTC time
 * @param {String} format - One of DATE_TIME_FORMATS
 * @returns {String} - Returns a string of date/time formatted to
 * requested formatted. If invalid date/time, returns "Invalid date/time"
 */
export const formatDateTime = (
   dateTimeString,
   format = DATE_TIME_FORMATS.DEFAULT,
   timezone,
) => {
   let momentDate = moment(dateTimeString);

   if (momentDate.isValid && timezone) {
      momentDate = momentDate.tz(timezone);
   }

   // Using invalid string to help surface bugs
   if (!momentDate.isValid()) {
      return 'Invalid date/time';
   }

   return momentDate.format(format);
};

/**
 * @description gets the last day of the previous month from the timeDate
 * @param {String} timeDate - time date in ISOString
 * @returns Tue Jul 25 2023 00:00:00 GMT-0400 (Eastern Daylight Time)
 */
export const getPrevMonthLastDate = (timeDate) => {
   const now = moment(timeDate);
   const lastMonth = now.clone().subtract(1, 'months').endOf('month');
   return lastMonth.toDate();
};
