import React from 'react';
import { Icon } from 'semantic-ui-react';
import { Blue } from '../Colors';
import { Sketch } from '../Colors';
import { numberWithCommas } from '../../utils';

export default function ArqPagination({
   startIdx,
   endIdx,
   numTotal,
   handleClickLeft,
   handleClickRight,
}) {
   const leftAngleColor =
      startIdx === 0 ? Sketch.Subtitle : Blue.BlueMedium2;

   const rightAngleColor =
      endIdx === numTotal ? Sketch.Subtitle : Blue.BlueMedium2;

   return (
      <span>
         <Icon
            name="angle left"
            size="large"
            style={{ color: leftAngleColor, marginTop: '-3px' }}
            onClick={handleClickLeft}
         />
         Showing {numberWithCommas(startIdx + 1)} -{' '}
         {numberWithCommas(endIdx)} of {numberWithCommas(numTotal)}
         <Icon
            name="angle right"
            size="large"
            style={{
               color: rightAngleColor,
               marginTop: '-3px',
            }}
            onClick={handleClickRight}
         />
      </span>
   );
}
