import { Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { InputColors, LabelColors, TextColors } from '../Colors';

export const StickyHeader = styled(Table.Header)`
   position: sticky !important;
   top: -2px !important;
   background-color: ${TextColors.SemanticWhite} !important;
   z-index: 1 !important;
`;

// Member List Table Components

export const ClickableTableHeaderCell = styled(Table.HeaderCell)`
   border-radius: 0px !important;
   overflow: hidden !important;
   text-overflow: clip !important;
   transition: background-color 0.3s;
   cursor: pointer !important;
   &:hover {
      background-color: ${InputColors.GreyBorder} !important;
   }
`;

export const MemberListTable = styled.table`
   border-collapse: collapse;
   border: 1px solid ${InputColors.GreyBorder};
`;

export const MemberListTableHeader = styled.thead`
   // Placeholder for additional CSS
`;

export const MemberListTableHeaderCell = styled.th`
   padding: 8px 12px;
   height: 60px;
   text-align: left;
   user-select: none;
   padding: 0.5rem;
   border-right: 1px solid ${InputColors.GreyBorder};
   border-radius: 0px !important;
   overflow: hidden !important;
   text-overflow: clip !important;
   transition: background-color 0.3s;
   cursor: pointer !important;

   &:hover {
      background-color: ${InputColors.GreyBorder} !important;
   }
`;

export const MemberListTableHeaderRow = styled.tr`
   position: relative !important;
   background-color: ${LabelColors.SemanticWhite};
   font-weight: 700;
`;

export const MemberListTableBody = styled.tbody`
   // Placeholder for additional CSS
`;

export const MemberListTableBodyRow = styled.tr`
   border-top: 1px solid var(--border, ${InputColors.GreyBorder});
`;

export const MemberListTableBodyCell = styled.td`
   height: 78px;
   padding: 12px;
   border-right: 1px solid var(--border, ${InputColors.GreyBorder});
`;
