import React from 'react';
import { Icon } from 'semantic-ui-react';
import {
   ToggleOnButton,
   ToggleOffButton,
} from '../../../Common/StyledComponents/Buttons/Buttons';

const toggleStyle = {
   padding: '8px 7px',
   height: '36px',
};

const FilterToggle = ({
   toggleSelected,
   displayText,
   onClickHandler,
}) => {
   return (
      <span style={{ marginLeft: '7px' }}>
         {toggleSelected ? (
            <ToggleOnButton
               style={toggleStyle}
               onClick={() => {
                  onClickHandler();
               }}
            >
               {displayText}
               <Icon style={{ marginLeft: '4px' }} name="check" />
            </ToggleOnButton>
         ) : (
            <ToggleOffButton
               style={toggleStyle}
               onClick={() => {
                  onClickHandler();
               }}
            >
               {displayText}
            </ToggleOffButton>
         )}
      </span>
   );
};

export default FilterToggle;
