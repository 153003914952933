import { checkNpiFormat } from '../../../Common/arqUtils';

export const createPcpListForFullAccess = (responseData) => {
   return [
      { key: 'all', text: 'All PCPs', value: 'all' },
      ...responseData.map((pcp) => {
         const formattedNpi = checkNpiFormat(pcp.providerId);
         return {
            key: formattedNpi,
            text: `${pcp.lastName}, ${pcp.firstName} (${formattedNpi})`,
            value: formattedNpi,
         };
      }),
   ];
};

export const createPcpListForNpiList = (responseData) => {
   return [
      {
         key: 'none',
         text: 'Choose a provider',
         value: 'none',
      },
      ...responseData.map((pcp) => {
         const formattedNpi = checkNpiFormat(pcp.providerId);
         return {
            key: formattedNpi,
            text: `${pcp.lastName}, ${pcp.firstName} (${formattedNpi})`,
            value: formattedNpi,
         };
      }),
   ];
};

export const createPcpListForSingleProviderMatched = (
   responseData,
) => {
   const matchedProviderData = responseData[0];
   const formattedNpi = checkNpiFormat(
      matchedProviderData.providerId,
   );
   const singleProviderDropdownOption = {
      key: formattedNpi,
      text: `${matchedProviderData.lastName}, ${matchedProviderData.firstName} (${formattedNpi})`,
      value: formattedNpi,
   };

   return [singleProviderDropdownOption];
};

export const createPcpListForSingleProviderNoMatch = (
   userCustomData,
) => {
   const singleProviderDropdownOption = {
      key: userCustomData.npi,
      text: `${userCustomData.name
         .substring(userCustomData.name.indexOf(' '))
         .toUpperCase()}, ${userCustomData.name
         .substring(0, userCustomData.name.indexOf(' '))
         .toUpperCase()} (${userCustomData.npi})`,
      value: userCustomData.npi,
   };

   return [singleProviderDropdownOption];
};

export const createPcpListNoNpi = () => {
   return [
      {
         key: 'none',
         text: 'No NPI Assigned',
         value: 'none',
      },
   ];
};
