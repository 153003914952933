import styled from 'styled-components';
import { Message } from 'semantic-ui-react';
import {
   MessageColors,
   TextColors,
   Blue,
   ButtonColors,
   InputColors,
} from '../Colors.js';

export const SuccessMessage = styled(Message)`
   background-color: ${MessageColors.GreenSuccess} !important;
   color: ${TextColors.CorrectText} !important;
`;

export const ErrorMessage = styled(Message)`
   background-color: ${MessageColors.RedError} !important;
   color: ${ButtonColors.RedErrorActive} !important;
   font-weight: 400px !important;
   box-shadow:
      0 0 0 1px ${TextColors.ErrorText} inset,
      0 0 0 0 transparent !important;
`;

export const BlueMessage = styled(Message)`
   background-color: ${Blue.BlueLight1} !important;
   color: ${Blue.BlueDark1} !important;
   box-shadow:
      0 0 0 1px ${Blue.BlueLight2} inset,
      0 0 0 0 transparent !important;
`;

export const DisableMessage = styled(Message)`
   background-color: ${InputColors.GreyDisabledBackground} !important;
   color: ${TextColors.GreyText} !important;
`;

export const WarningMessage = styled(Message)`
   background-color: ${MessageColors.WarningOrange} !important;
   color: ${TextColors.WarningDark} !important;
   font-family: Lato !important;
   font-style: normal !important;
   font-weight: 400 !important;
   font-size: 14px !important;
   line-height: 18px !important;
`;
