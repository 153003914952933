import React, { useMemo } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { menuItems } from '../../../Values';
import { Blue, TextColors } from '../../../StyledComponents/Colors';

const MenuItemWrapper = styled.span`
   padding: 0.75em 0 0.75em;
   color: ${(props) =>
      props.$menuitemselected
         ? `${Blue.BlueMedium1}`
         : `${TextColors.SemanticWhite}`};
   margin: 0 15px;
   display: flex;
   font-weight: ${(props) => (props.$menuitemselected ? 800 : 400)};
`;

const MenuItemText = styled.span`
   color: ${(props) =>
      props.$menuitemselected
         ? `${Blue.BlueMedium1}`
         : `${TextColors.SemanticWhite}`};
`;

const MenuLink = styled(Link)`
   background-color: ${(props) =>
      props.$menuselected
         ? `${Blue.BlueDark2}`
         : `${Blue.BlueDark1}`};
`;

const MainSideBar = ({ userCustomData }) => {
   const location = useLocation();
   const { menuSelection } = useLocation().payload || {};
   const enabledMenuItems = useMemo(
      () =>
         menuItems.filter((item) => {
            return item.restrictions.includes(
               userCustomData?.currentPersona,
            );
         }),
      [userCustomData],
   );

   const SupportMenuItem = (
      <a
         href="https://apollomed.atlassian.net/servicedesk/customer/portal/3"
         target="_blank"
         rel="noreferrer"
         className="menu-item"
      >
         <MenuItemWrapper>
            <Icon className="menu-item-icon" name="question circle" />
            <span className="menu-item-text">Support</span>
         </MenuItemWrapper>

         <ReactTooltip place="right" type="info" effect="solid" />
      </a>
   );

   return (
      <>
         {enabledMenuItems.map((item) => (
            <React.Fragment key={item.title}>
               {
                  <MenuLink
                     to={item.to}
                     className="menu-item"
                     $menuselected={
                        location.pathname.includes(item.to) &&
                        !menuSelection
                     }
                  >
                     <MenuItemWrapper
                        $menuitemselected={
                           location.pathname.includes(item.to) &&
                           !menuSelection
                        }
                     >
                        <Icon
                           className="menu-item-icon"
                           data-tip={item.title}
                           name={item.icon}
                        />
                        <MenuItemText
                           $menuitemselected={
                              location.pathname.includes(item.to) &&
                              !menuSelection
                           }
                           className="menu-item-text"
                        >
                           {item.title}
                        </MenuItemText>
                     </MenuItemWrapper>
                  </MenuLink>
               }

               <ReactTooltip
                  place="right"
                  type="info"
                  effect="solid"
               />
            </React.Fragment>
         ))}
         {SupportMenuItem}
      </>
   );
};

export default MainSideBar;
