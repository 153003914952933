import styled from 'styled-components';
import { Container } from 'semantic-ui-react';
import { Blue, Sketch, TextColors } from '../Colors';

/* === Drag and Drop style functions === */
const getBackgroundColor = (props) => {
   if (props.isDragAccept) {
      return Blue.BlueLight1;
   }
   if (props.isDragReject) {
      return TextColors.ErrorText;
   }
   if (props.isFocused) {
      return TextColors.BlueLight1;
   }
   return TextColors.SemanticWhiteGreyShade;
};

const getBorderColor = (props) => {
   if (props.isDragAccept) {
      return Blue.BlueDark1;
   }
   if (props.isDragReject) {
      return TextColors.ErrorText;
   }
   if (props.isFocused) {
      return TextColors.BlueDarkText;
   }
   return Sketch.Subtitle;
};

const getTextColor = (props) => {
   if (props.isDragAccept) {
      return Blue.BlueDark1;
   }
   if (props.isDragReject) {
      return TextColors.ErrorText;
   }
   if (props.isFocused) {
      return TextColors.BlueDarkText;
   }
   return TextColors.BlackText;
};
/* === END Drag and Drop style functions === */

export const CsvPreviewContainer = styled(Container)`
   overflow-y: auto !important;
   max-height: 462px !important;
   margin: auto !important;
   width: stretch !important;
`;

export const BulkUserResultsTable = styled(Container)`
   overflow-y: auto !important;
   max-height: 462px !important;
   margin: auto !important;
   width: stretch !important;
`;

export const CallTimerContainer = styled.div`
   background-color: ${Blue.BlueLight1} !important;
   border-radius: 4px !important;
   box-shadow: 4px 4px 4px rgba(188, 188, 188, 0.2) !important;
   color: ${Blue.BlueDark1} !important;
   display: flex !important;
   flex-wrap: nowrap !important;
   height: 42px !important;
   line-height: 26px !important;
   min-width: 261px !important;
   padding: 8px 8px !important;
   position: fixed !important;
   right: 2% !important;
   top: 60px !important;
   text-align: left !important;
   z-index: 1 !important;
`;

export const DropzoneContainer = styled.div`
   flex: 1;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 50px 80px 80px 80px;
   width: 600px;
   height: 236px;
   font-family: Lato;
   font-size: 14px;
   font-style: normal;
   border-width: 1px;
   border-radius: 2px;
   border-color: ${(props) => getBorderColor(props)};
   border-style: dashed;
   background-color: ${(props) => getBackgroundColor(props)};
   color: ${(props) => getTextColor(props)};
   outline: none;
   transition: border 0.24s ease-in-out;
`;

export const FileUploadingContainer = styled.div`
   flex: 1;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 30px 0 80px 0;
   width: 600px;
   height: 236px;
   font-family: Lato;
   font-size: 14px;
   font-style: normal;
   border-width: 1px;
   border-radius: 2px;
   border-color: ${Sketch.Subtitle};
   border-style: dashed;
   background-color: ${TextColors.SemanticWhite};
   color: ${TextColors.BlackText};
   outline: none;
   transition: border 0.24s ease-in-out;
   gap: 28px;
`;

export const SinglePersona = styled.div`
   color: ${TextColors.PeriwinklePurpleText};
   display: inline;
   height: 30px;
   line-height: 30px;
`;
