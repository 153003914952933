import { formatName } from './utils';
import { useLocation } from 'react-router-dom';
import { PERSONA } from './Constants/UserConstants';
import { URL } from './Constants/URLConstants';

/** DateTimeUtils */

// TODO: CM-719 Fill out JSDOC comments
/**
 *
 * @param {*} dateString
 * @param {*} userTimeZone
 * @returns
 */
export function getDateUploaded(dateString, userTimeZone) {
   const uploadDate = new Date(dateString);
   const dateToday = new Date();
   const dateYesterday = new Date(dateToday);

   dateYesterday.setDate(dateYesterday.getDate() - 1);

   if (
      getConvertedDateString(uploadDate, userTimeZone) ===
      getConvertedDateString(dateToday, userTimeZone)
   ) {
      return 'Today';
   } else if (
      getConvertedDateString(uploadDate, userTimeZone) ===
      getConvertedDateString(dateYesterday, userTimeZone)
   ) {
      return 'Yesterday';
   } else {
      return getConvertedDateString(uploadDate, userTimeZone);
   }
}

// TODO: CM-719 Fill out JSDOC comments
/**
 *
 * @param {*} date
 * @param {*} userTimeZone
 * @returns
 */
export function getConvertedDateString(date, userTimeZone) {
   return date.toLocaleDateString('en-US', {
      timeZone: userTimeZone,
   });
}

// TODO: CM-719 Fill out JSDOC comments
/**
 *
 * @param {*} dateString
 * @param {*} userTimeZone
 * @returns
 */
export function getConvertedDateTimeString(dateString, userTimeZone) {
   return new Date(dateString).toLocaleString('en-US', {
      timeZone: userTimeZone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      second: '2-digit',
      minute: '2-digit',
   });
}
/** End DateTimeUtils */

// TODO: CM-719 Fill out JSDOC comments
export function getUserUploaded(userId, users) {
   const userLst = users.filter((user) => user.id === userId);
   if (userLst.length > 0) {
      return userLst[0].name;
   } else {
      return 'N/A';
   }
}

// TODO: CM-719 Fill out JSDOC comments
export function formatNumbersOnly(str) {
   return str.replace(/(\D+)/g, '');
}

// TODO: CM-719 Fill out JSDOC comments
export function formatNameCharacters(str) {
   return str.replace(/[^a-zA-Z\s-]/gi, '');
}

// TODO: CM-719 Fill out JSDOC comments
export function formatInitials(user) {
   const name = formatName(user.name).replace(/ /g, '').split(',');
   return `${name[1][0]}${name[0][0]}`;
}

// TODO: CM-719 Fill out JSDOC comments
export const zipCodeConversion = (zip) => {
   if (typeof zip === 'undefined' || zip === null) {
      return '';
   }
   return zip.length > 5 ? zip.slice(0, 5) : zip;
};

// TODO: CM-719 Fill out JSDOC comments
export function useMembersListQuery() {
   const query = new URLSearchParams(useLocation().search);
   return {
      view: query.get('view'),
      npi: query.get('npi'),
      tin: query.get('tin'),
      cm: query.get('cm'),
      cc: query.get('cc'),
      sort: query.get('sort'),
      gic: query.get('gic'),
      hplan: query.get('hplan'),
      ipa: query.get('ipa'),
      page: query.get('page'),
      sortParam: query.get('sortParam'), // sort is a reserved function. so, we name sortParam
      memberId: query.get('memberId'),
      tableColumns: query.get('tableColumns'),
      messageType: query.get('messageType'),
      filter: query.get('filter'),
   };
}

// TODO: CM-719 Fill out JSDOC comments
export function getNewSearchParam(query, key, value) {
   query[key] = value;
   return Object.entries(query)
      .filter((keyValuePair) => keyValuePair[1] !== null)
      .map((keyValuePair) => `${keyValuePair[0]}=${keyValuePair[1]}`)
      .join('&');
}

// TODO: CM-719 Fill out JSDOC comments
export function updateSearchParams(
   history,
   query,
   pathname,
   key,
   value,
) {
   if (!value) {
      delete query[key];
   } else {
      query[key] = value;
   }

   const newSearch = Object.entries(query)
      .filter((v) => v[1] !== null)
      .map((v) => `${v[0]}=${v[1]}`)
      .join('&');

   history.push({
      pathname: pathname,
      search: newSearch,
   });
}

// TODO: CM-719 Fill out JSDOC comments
export function checkNpiFormat(npiStr) {
   if (npiStr && /^[a-zA-Z]/.test(npiStr)) {
      return npiStr.substring(1);
   } else {
      return npiStr;
   }
}

// TODO: CM-719 Fill out JSDOC comments
export function checkUserHasFullAccess(user) {
   const hasFullAccess = user.currentPersona === PERSONA.PR_REPS;

   return hasFullAccess;
}

// TODO: CM-719 Fill out JSDOC comments
export function prvdrTodoIntelligence(member) {
   const messages = [];
   if (member.awv_is_due === 'Y') {
      messages.push(
         'The patient is due for Annual Wellness Visit (AWV). Please schedule an AWV visit.',
      );
   }
   if (member.hcc_recapture_cnt > 0) {
      messages.push(
         `The patient has ${member.hcc_recapture_cnt} open Hierarchical Condition Categories (HCCs). Please review the recapturable HCCs below.`,
      );
   }

   return messages;
}

// TODO: CM-719 Fill out JSDOC comments
export const pathnameSelection = (persona) => {
   switch (persona) {
      // case PERSONA.EXECUTIVE:
      //   return '/prvdrview-awv-chase-list';
      // case PERSONA.OFFICE_MANAGER:
      //   return '/document-hub';
      // TODO: Hidden until ACO confirms
      // if feature is needed
      // case PERSONA.PR_REPS:
      //   return '/document-hub';
      case PERSONA.PROVIDER:
      default:
         return URL.AWV_CHASE_LIST;
   }
};

// Assumes no duplicates in the arrays and order
// does not matter
export const checkArrayEquality = (arrayA, arrayB) => {
   return (
      arrayA.length === arrayB.length &&
      arrayA.every((element) => {
         return arrayB.includes(element);
      })
   );
};

// TODO: CM-719 Fill out JSDOC comments
// Takes a string with multiple words separated by whitespaces
// and capitalizes only the first letter of each word
export const formatCapitalString = (inputStr) => {
   if (inputStr) {
      return inputStr
         .toLowerCase()
         .split(' ')
         .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
         .join(' ');
   }

   return '';
};

// TODO: CM-719 Fill out JSDOC comments
export function getFileType(fileName) {
   const extension = fileName.split('.').pop().toLowerCase();
   switch (extension) {
      case 'pdf':
         return 'PDF';
      case 'png':
      case 'jpeg':
      case 'jpg':
         return 'Image';
      case 'doc':
      case 'docx':
         return 'Microsoft Word';
      case 'ppt':
         return 'Powerpoint';
      case 'csv':
      case 'xls':
      case 'xlsx':
         return 'Excel';
      case 'txt':
         return 'Plain Text';
      case 'zip':
         return 'ZIP';
      default:
         return '';
   }
}

// TODO: CM-719 Fill out JSDOC comments
export function getContentType(fileName) {
   const extension = fileName.split('.').pop().toLowerCase();
   // TODO: move this to determine in backend as part of docList data
   // see types here: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
   switch (extension) {
      case 'pdf':
         return 'application/pdf';
      case 'png':
         return 'image/png';
      case 'jpeg':
      case 'jpg':
         return 'image/jpeg';
      case 'doc':
         return 'application/msword';
      case 'docx':
         return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'ppt':
         return 'application/vnd.ms-powerpoint';
      case 'csv':
         return 'text/csv';
      case 'xls':
         return 'application/vnd.ms-excel';
      case 'xlsx':
         return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'txt':
         return 'text/plain';
      case 'zip':
         return 'application/zip';
      default:
         return '';
   }
}

// TODO: CM-719 Fill out JSDOC comments
export const handleDownload = async (
   fileName, // file path
   selectedIndex, // file index on the list
   categories, // Ex: General, Members, Internal
   currentUser, // from useStitchAuth
   setLoadingFiles,
   handleDocDownload, // from useStitchAuth
   file, // entire file-like object from upload
) => {
   setLoadingFiles((prev) => new Set([...prev, selectedIndex]));
   const contentType = getContentType(fileName);

   const binaryObj =
      await currentUser.functions.downloadS3Reports(fileName);
   const binaryObjValues = Object.values(binaryObj);
   const binaryArr = new Uint8Array(binaryObjValues);

   const link = document.createElement('a');
   link.href = URL.createObjectURL(
      new Blob([binaryArr], { type: contentType }),
   );
   // Returns the name of the file and not the entire path
   link.download = fileName.substring(fileName.lastIndexOf('/') + 1);

   document.body.appendChild(link);
   link.click();

   await handleDocDownload(fileName, categories);
   setLoadingFiles((prev) => {
      const updatedList = new Set(prev);
      updatedList.delete(selectedIndex);
      return updatedList;
   });
};
