import React from 'react';
import {
   TabSwitcherItem,
   TabSwitcherMenu,
} from '../Common/StyledComponents/Navigation/TabSwitcher';

const ModeSelectionMenu = ({ mode, setMode }) => {
   const handleChangeMode = (e, { name }) => {
      setMode(name);
   };

   return (
      <TabSwitcherMenu>
         <TabSwitcherItem
            name="single"
            active={mode === 'single'}
            onClick={handleChangeMode}
         >
            Enroll single user
         </TabSwitcherItem>
         <TabSwitcherItem
            name="multiple"
            active={mode === 'multiple'}
            onClick={handleChangeMode}
         >
            Enroll multiple users
         </TabSwitcherItem>
      </TabSwitcherMenu>
   );
};

export default ModeSelectionMenu;
