import React, { useMemo } from 'react';
import { Table } from 'semantic-ui-react';
import InfoIconPopup from './InfoIconPopup';
import { AwvChaseListColumns } from '../../../Common/Values';
import { StickyHeader } from '../../../Common/StyledComponents/Tables/Tables';
import { COLUMN } from '../../../Common/Constants/MemberListConstants';

const stickyHeaderCellStyle = {
   paddingTop: '16px',
};

const getTableHeaderCellContents = (column, loadingToPrint) => {
   const colname = column.key;

   if (colname === COLUMN.G_CODE) {
      return (
         <>
            {column.text}&nbsp;
            {loadingToPrint ? null : (
               <InfoIconPopup
                  popupText={
                     'Suggested based on our CMS claims records for your patients. Check Noridian for G code eligibility.'
                  }
               />
            )}
         </>
      );
   } else if (colname === COLUMN.HCC_CNT_OPEN) {
      return (
         <>
            {column.text}&nbsp;
            {loadingToPrint ? null : (
               <InfoIconPopup
                  popupText={'Number of HCCs not yet recaptured.'}
               />
            )}
         </>
      );
   } else if (
      colname === COLUMN.RAF_MY ||
      colname === COLUMN.RAF_PY
   ) {
      return (
         <>
            {column.text}&nbsp;
            {loadingToPrint ? null : (
               <InfoIconPopup
                  popupText={
                     'Risk Adjustment Factor (RAF) is used to adjust the benchmark (historical expenditures you are compared against) in the ACO REACH program in order to appropriately reflect the expenditures for your patients.'
                  }
               />
            )}
         </>
      );
   } else {
      return column.text;
   }
};

const TableHeader = ({ loadingToPrint }) => {
   const awvColumnList = useMemo(() => {
      if (loadingToPrint) {
         // Hide columns from print view
         return AwvChaseListColumns.filter(
            (col) => col.key !== COLUMN.PAF,
         ).filter((col) => col.key !== COLUMN.PREV_AWV_RECORDS);
      } else {
         return AwvChaseListColumns;
      }
   }, [loadingToPrint]);

   return (
      <StickyHeader>
         <Table.Row>
            <Table.HeaderCell style={stickyHeaderCellStyle}>
               Name
            </Table.HeaderCell>
            <Table.HeaderCell style={stickyHeaderCellStyle}>
               Medicare ID
            </Table.HeaderCell>
            {awvColumnList.map((column) => (
               <Table.HeaderCell
                  key={column.key}
                  textAlign="right"
                  style={stickyHeaderCellStyle}
               >
                  {getTableHeaderCellContents(column, loadingToPrint)}
               </Table.HeaderCell>
            ))}
         </Table.Row>
      </StickyHeader>
   );
};

export default TableHeader;
