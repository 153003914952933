import React, { useState } from 'react';
import lodashMap from 'lodash/map';
import { useHistory } from 'react-router-dom';
import { Dropdown, Loader, Dimmer } from 'semantic-ui-react';
import { useStitchAuth } from '../../StitchAuth';
import { menuItems } from '../Values';
import { WorkspaceDropdownMenu } from '../StyledComponents/Dropdowns/Dropdowns';
import { TextColors } from '../StyledComponents/Colors';

export default function WorkspaceDropdown(props) {
   const { userCustomData, actions } = useStitchAuth();
   const [loading, setLoading] = useState(false);
   const history = useHistory();

   const handleWorkspaceChange = async (group_id) => {
      setLoading(true);
      const listOfMenus = menuItems.filter((item) =>
         item.restrictions.includes(userCustomData?.currentPersona),
      );
      await actions.handleWorkspaceChange(group_id);
      setLoading(false);
      if (listOfMenus.length > 0) {
         history.push({
            pathname: listOfMenus[0].to,
         });
      }
   };

   if (
      // TODO: Check user persona level instead of workspaceAccess
      userCustomData &&
      userCustomData.workspaceAccess
   ) {
      const workspaceOptions = lodashMap(
         userCustomData.workspaces,
         (workspace, index) => ({
            group_id: userCustomData.workspaces[index].group_id,
            name: userCustomData.workspaces[index].name,
            code: userCustomData.workspaces[index].code,
         }),
      );

      const currentWorkspace =
         workspaceOptions[
            workspaceOptions.findIndex(
               (aco) => aco.group_id === userCustomData.group_id,
            )
         ];

      return (
         <>
            <WorkspaceDropdownMenu
               icon={'angle down'}
               trigger={
                  <div
                     style={{
                        cursor: 'pointer',
                        userSelect: 'none',
                        display: 'inline',
                        height: '30px',
                        color: TextColors.SemanticText,
                     }}
                  >
                     {currentWorkspace.name}
                  </div>
               }
            >
               <Dropdown.Menu>
                  {workspaceOptions.map((aco, index) => (
                     <Dropdown.Item
                        key={index}
                        onClick={() => {
                           if (
                              userCustomData.group_id !== aco.group_id
                           ) {
                              handleWorkspaceChange(aco.group_id);
                           }
                        }}
                     >
                        {aco.name}
                     </Dropdown.Item>
                  ))}
               </Dropdown.Menu>
            </WorkspaceDropdownMenu>
            {loading && (
               <Dimmer active inverted>
                  <Loader inverted>Switching Workspace</Loader>
               </Dimmer>
            )}
         </>
      );
   } else {
      // User does not have access to other workspaces
      return null;
   }
}
