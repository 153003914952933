import React, { useState } from 'react';
import { Loader, Grid, Header } from 'semantic-ui-react';
import AppBar from '../Common/AppBar/AppBar';
import { useStitchAuth } from '../StitchAuth';
import RegisterSingleUser from './RegisterSingleUser/RegisterSingleUser';
import RegisterBulkUser from './RegisterBulkUser/RegisterBulkUser';
import ModeSelectionMenu from './ModeSelectionMenu';
import '../Common/Layout.css';

const gridStyle = {
   margin: 0,
   marginTop: '1em',
   paddingBottom: '5em',
   paddingLeft: '2em',
};

const RegisterUsers = () => {
   const { userCustomData } = useStitchAuth();
   const [mode, setMode] = useState('single');

   if (!userCustomData) {
      return (
         <div className="app-layout">
            <AppBar />
            <Loader inverted>Loading</Loader>
         </div>
      );
   } else {
      return (
         <div className="app-layout">
            <AppBar />
            <div style={{ gridColumn: 3, color: '#333' }}>
               <Grid style={gridStyle}>
                  <Grid.Row>
                     <Grid.Column>
                        <Grid.Row>
                           <Header>Register New Users</Header>
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: '23px' }}>
                           <ModeSelectionMenu
                              mode={mode}
                              setMode={setMode}
                           />
                        </Grid.Row>
                     </Grid.Column>
                  </Grid.Row>
               </Grid>
               {mode === 'single' ? (
                  <RegisterSingleUser />
               ) : (
                  <RegisterBulkUser />
               )}
            </div>
         </div>
      );
   }
};

export default RegisterUsers;
