import React from 'react';
import { Placeholder } from 'semantic-ui-react';

export default function ArqPlaceholder({
   numParagraphs,
   numLinesPerParagraphs,
}) {
   return (
      <Placeholder>
         {[...Array(numParagraphs || 2).keys()].map(
            (paragraphIndex) => (
               <Placeholder.Paragraph key={paragraphIndex}>
                  {[...Array(numLinesPerParagraphs || 5).keys()].map(
                     (lineIndex) => (
                        <Placeholder.Line key={lineIndex} />
                     ),
                  )}
               </Placeholder.Paragraph>
            ),
         )}
      </Placeholder>
   );
}
