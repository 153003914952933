import styled from 'styled-components';
import { Menu } from 'semantic-ui-react';
import { Blue, TextColors } from '../Colors';

const StyledTabSwitcherMenu = styled(Menu)`
   border: 1px solid ${Blue.BlueDark1} !important;
`;

/**
 * Corresponds to TabSwitcher in figma design docs
 *
 */
export const TabSwitcherMenu = ({ children, ...otherProps }) => (
   <StyledTabSwitcherMenu compact borderless {...otherProps}>
      {children}
   </StyledTabSwitcherMenu>
);

const StyledTabSwitcherItem = styled(Menu.Item)`
   color: ${Blue.BlueDark1} !important;
   border: none !important;
   justify-content: center !important;
   height: 42px !important;
   width: 180px !important;
   border-right: 1px solid ${Blue.BlueDark1} !important;
   &.active {
      background-color: ${Blue.BlueDark1} !important;
      color: ${TextColors.SemanticWhite} !important;
   }
   &:last-child {
      border-right: none !important;
      border-radius: 0 1px 1px 0 !important;
   }
   &:first-child {
      border-radius: 1px 0 0 1px !important;
   }
`;

export const TabSwitcherItem = ({ children, ...otherProps }) => (
   <StyledTabSwitcherItem {...otherProps}>
      {children}
   </StyledTabSwitcherItem>
);
