import React from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Icon, Button } from 'semantic-ui-react';
import { URL } from '../../Common/Constants/URLConstants';

export const UserRegistrationCompletedModal = ({
   isRegistrationCompleted,
   closeModal,
}) => {
   const history = useHistory();
   return (
      <Modal
         centered={true}
         open={isRegistrationCompleted}
         dimmer="inverted"
         style={{
            height: '288px',
            width: '424px',
         }}
      >
         <Modal.Content>
            <Icon
               color="black"
               name="close"
               style={{ float: 'right', paddingLeft: '1em' }}
               onClick={closeModal}
            />
            <Modal.Description
               style={{
                  textAlign: 'center',
                  fontSize: '20px',
                  paddingLeft: '8px',
               }}
            >
               <Icon
                  size="large"
                  name="check circle"
                  style={{
                     color: '#18B03A',
                     fontSize: '60px',
                     marginTop: '20px',
                  }}
               />
               <p
                  style={{
                     fontSize: '20px',
                     fontWeight: 'bold',
                     marginTop: '25px',
                     marginBottom: '3px',
                  }}
               >
                  Success
               </p>
               <p style={{ fontSize: '18px' }}>
                  Your user information has been confirmed.
               </p>
               <Button
                  onClick={() =>
                     history.push({
                        pathname: URL.AWV_CHASE_LIST,
                     })
                  }
                  primary
                  style={{ marginTop: '10px' }}
               >
                  Go to AWV Chase List
               </Button>
            </Modal.Description>
         </Modal.Content>
      </Modal>
   );
};
