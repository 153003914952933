import React from 'react';
import styled from 'styled-components';
import { Form, Grid } from 'semantic-ui-react';
import moment from 'moment';
import LoginFormInputs from './LoginFormInputs';
import { DATE_TIME_FORMATS } from '../Common/DateTimeUtils';
import { TextColors } from '../Common/StyledComponents/Colors';

const LoginMessageFormat = styled.p`
   padding: 0;
   font-family: 'PT Serif';
   font-style: normal;
   font-weight: 700;
   font-size: 50px;
   line-height: 18px;
`;

const LoginDateFormat = styled.p`
   padding: 0;
   font-family: 'Lato';
   font-style: normal;
   font-weight: 700;
   font-size: 30px;
   line-height: 18px;
`;

const LoginHourFormat = styled.p`
   padding: 0;
   font-family: 'Lato';
   font-style: normal;
   font-weight: 400;
   font-size: 30px;
   line-height: 18px;
`;

const LoginContainer = styled(Form)`
   width: 366px;
   margin: 57px 32px 57px 32px;
`;

const LoginForm = ({
   formError,
   email,
   setEmail,
   password,
   setPassword,
   handleSubmit,
   handleBackgroundSelection,
}) => {
   const currMomentDate = moment();
   const currHour = currMomentDate.hour();

   return (
      <Grid.Row columns={2}>
         <Grid.Column
            verticalAlign="middle"
            style={{
               height: 490,
               padding: '3em',
               left: 100,
               color: `${handleBackgroundSelection(currHour).color}`,
            }}
         >
            <LoginMessageFormat data-testid={'login-greeting'}>
               {handleBackgroundSelection(currHour).msg}
            </LoginMessageFormat>
            <LoginDateFormat data-testid={'login-date'}>
               {currMomentDate.format(DATE_TIME_FORMATS.LONG_DATE)}
            </LoginDateFormat>
            <LoginHourFormat data-testid={'login-time'}>
               {currMomentDate.format(
                  DATE_TIME_FORMATS.SHORT_TIME_SPACE,
               )}
            </LoginHourFormat>
         </Grid.Column>
         <Grid.Column
            textAlign="center"
            verticalAlign="middle"
            style={{
               backgroundColor: TextColors.SemanticWhite,
               textAlign: 'center',
               maxWidth: 430,
               padding: 0,
               left: 100,
            }}
         >
            <LoginContainer error={formError}>
               <LoginFormInputs
                  formError={formError}
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  handleSubmit={handleSubmit}
               />
               <p style={{ marginTop: '16px' }} className="grey text">
                  <a href="/passwordresetsend">Reset Password</a>
               </p>
            </LoginContainer>
         </Grid.Column>
      </Grid.Row>
   );
};

export default LoginForm;
