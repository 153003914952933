import React from 'react';
import moment from 'moment';
import { UNIX_TIMESTAMP_SEC_TO_MS } from '../../../Common/DateTimeUtils';
import { TextColors } from '../../../Common/StyledComponents/Colors';

const AwvDueDateCell = ({ timestamp }) => {
   const currentMonthYear = moment();
   const timestampMonthYear = moment.unix(
      timestamp / UNIX_TIMESTAMP_SEC_TO_MS,
   );

   const isEarlier = timestampMonthYear.isBefore(
      currentMonthYear,
      'month',
   );

   const dateStyle = {
      color: isEarlier ? TextColors.ErrorText : TextColors.BlackText,
   };
   const formattedTimestamp = timestampMonthYear.format('MMMM YYYY');

   return <span style={dateStyle}>{formattedTimestamp}</span>;
};

export default AwvDueDateCell;
