import { Icon, Modal } from 'semantic-ui-react';
import { BlueDownloadButton } from '../../Common/StyledComponents/Buttons/Buttons';

const RegisterSingleSuccessModal = ({
   refreshState,
   newUser,
   registerSuccess,
}) => {
   return (
      <Modal
         centered={true}
         open={registerSuccess}
         onClose={refreshState}
         style={{
            height: '288px',
            width: '424px',
         }}
         dimmer="inverted"
      >
         <Icon
            link
            color="black"
            name="close"
            onClick={refreshState}
            style={{
               textDecoration: 'none',
               position: 'absolute',
               left: '381px',
               top: '12px',
            }}
         />
         <Modal.Content
            style={{
               textAlign: 'center',
            }}
         >
            <Modal.Description>
               <Icon
                  size="large"
                  name="check circle"
                  style={{
                     color: '#18B03A',
                     fontSize: '60px',
                     marginTop: '20px',
                  }}
               ></Icon>
               <p
                  style={{
                     fontSize: '20px',
                     fontWeight: 'bold',
                     marginTop: '32px',
                     marginBottom: '4px',
                  }}
               >
                  Successful Registration
               </p>
               <p
                  style={{
                     fontSize: '14px',
                     width: '300px',
                     color: '#7B7B7B',
                     marginLeft: 'auto',
                     marginRight: 'auto',
                  }}
               >
                  The user{' '}
                  {newUser.firstName + ' ' + newUser.lastName + ' '}
                  has been created.
               </p>
               <BlueDownloadButton
                  size="large"
                  onClick={refreshState}
                  style={{
                     fontSize: '14px',
                     marginTop: '18px',
                     padding: '11px 10px',
                  }}
               >
                  Enroll Another User
               </BlueDownloadButton>
            </Modal.Description>
         </Modal.Content>
      </Modal>
   );
};

export default RegisterSingleSuccessModal;
