import { COLUMN } from './Constants/MemberListConstants';
import { URL } from './Constants/URLConstants';
import { PERSONA } from './Constants/UserConstants';

// TODO: Move to it's own file or Address / Location based constants file
export const states = [
   { key: 'Alabama', text: 'Alabama', value: 'AL' },
   { key: 'Alaska', text: 'Alaska', value: 'AK' },
   { key: 'American Samoa', text: 'American Samoa', value: 'AS' },
   { key: 'Arizona', text: 'Arizona', values: 'AZ' },
   { key: 'Arkansas', text: 'Arkansas', values: 'AR' },
   { key: 'California', text: 'California', value: 'CA' },
   { key: 'Colorado', text: 'Colorado', value: 'CO' },
   { key: 'Connecticut', text: 'Connecticut', value: 'CT' },
   { key: 'Delaware', text: 'Delaware', value: 'DE' },
   {
      key: 'District Of Columbia',
      text: 'District Of Columbia',
      value: 'DC',
   },
   {
      key: 'Federated States Of Micronesia',
      text: 'Federated States Of Micronesia',
      value: 'FM',
   },
   { key: 'Florida', text: 'Florida', value: 'FL' },
   { key: 'Georgia', text: 'Georgia', value: 'GA' },
   { key: 'Guam', text: 'Guam', value: 'GU' },
   { key: 'Hawaii', text: 'Hawaii', value: 'HI' },
   { key: 'Idaho', text: 'Idaho', value: 'ID' },
   { key: 'Illinois', text: 'Illinois', value: 'IL' },
   { key: 'Indiana', text: 'Indiana', value: 'IN' },
   { key: 'Iowa', text: 'Iowa', value: 'IA' },
   { key: 'Kansas', text: 'Kansas', value: 'KS' },
   { key: 'Kentucky', text: 'Kentucky', value: 'KY' },
   { key: 'Louisiana', text: 'Louisiana', value: 'LA' },
   { key: 'Maine', text: 'Maine', value: 'ME' },
   { key: 'Marshall Islands', text: 'Marshall Islands', value: 'MH' },
   { key: 'Maryland', text: 'Maryland', value: 'MD' },
   { key: 'Massachusetts', text: 'Massachusetts', value: 'MA' },
   { key: 'Michigan', text: 'Michigan', value: 'MI' },
   { key: 'Minnesota', text: 'Minnesota', value: 'MN' },
   { key: 'Mississippi', text: 'Mississippi', value: 'MS' },
   { key: 'Missouri', text: 'Missouri', value: 'MO' },
   { key: 'Montana', text: 'Montana', value: 'MT' },
   { key: 'Nebraska', text: 'Nebraska', value: 'NE' },
   { key: 'Nevada', text: 'Nevada', value: 'NV' },
   { key: 'New Hampshire', text: 'New Hampshire', value: 'NH' },
   { key: 'New Jersey', text: 'New Jersey', value: 'NJ' },
   { key: 'New Mexico', text: 'New Mexico', value: 'NM' },
   { key: 'New York', text: 'New York', value: 'NY' },
   { key: 'North Carolina', text: 'North Carolina', value: 'NC' },
   { key: 'North Dakota', text: 'North Dakota', value: 'ND' },
   {
      key: 'Northern Mariana Islands',
      text: 'Northern Mariana Islands',
      value: 'MP',
   },
   { key: 'Ohio', text: 'Ohio', value: 'OH' },
   { key: 'Oklahoma', text: 'Oklahoma', value: 'OK' },
   { key: 'Oregon', text: 'Oregon', value: 'OR' },
   { key: 'Palau', text: 'Palau', value: 'PW' },
   { key: 'Pennsylvania', text: 'Pennsylvania', value: 'PA' },
   { key: 'Puerto Rico', text: 'Puerto Rico', value: 'PR' },
   { key: 'Rhode Island', text: 'Rhode Island', value: 'RI' },
   { key: 'South Carolina', text: 'South Carolina', value: 'SC' },
   { key: 'South Dakota', text: 'South Dakota', value: 'SD' },
   { key: 'Tennessee', text: 'Tennessee', value: 'TN' },
   { key: 'Texas', text: 'Texas', value: 'TX' },
   { key: 'Utah', text: 'Utah', value: 'UT' },
   { key: 'Vermont', text: 'Vermont', value: 'VT' },
   { key: 'Virgin Islands', text: 'Virgin Islands', value: 'VI' },
   { key: 'Virginia', text: 'Virginia', value: 'VA' },
   { key: 'Washington', text: 'Washington', value: 'WA' },
   { key: 'West Virginia', text: 'West Virginia', value: 'WV' },
   { key: 'Wisconsin', text: 'Wisconsin', value: 'WI' },
   { key: 'Wyoming', text: 'Wyoming', value: 'WY' },
];

// TODO: Move to Navigation/Authentication based util file
export const menuItems = [
   // TODO: Hidden until ACO confirms if
   // feature is needed
   // {
   //   title: 'Document Hub',
   //   icon: 'folder',
   //   pathMatches: ['home', 'member', 'member-details'],
   //   to: '/document-hub',
   //   restrictions: [
   //     'provider',
   //     'pr-reps',
   //     // 'office-manager',
   //   ],
   // },
   {
      title: 'AWV Chase List',
      icon: 'user',
      pathMatches: [],
      to: URL.AWV_CHASE_LIST,
      restrictions: [PERSONA.PROVIDER, PERSONA.PR_REPS],
   },
   // Hide ADT Feed per request from Kejia
   // {
   //    title: 'ADT Feed',
   //    icon: 'bell',
   //    pathMatches: [],
   //    to: URL.ADT_FEED,
   //    restrictions: [PERSONA.PROVIDER, PERSONA.PR_REPS],
   // },
   {
      title: 'Register New Users',
      icon: 'add user',
      pathMatches: ['home', 'register'],
      to: URL.REGISTER_USERS,
      restrictions: [PERSONA.PR_REPS],
   },
   {
      title: 'Settings',
      icon: 'settings',
      pathMatches: ['home'],
      to: URL.SETTINGS,
      restrictions: [PERSONA.PROVIDER],
   },
];

// TODO: Move to provider / member based util file
export const AwvChaseListColumns = [
   {
      key: COLUMN.AWV_COMPLETED,
      text: `Completed in ${new Date().getFullYear()}?`,
      value: COLUMN.AWV_COMPLETED,
   },
   {
      key: COLUMN.AWV_DUE,
      text: 'AWV Due Date',
      value: COLUMN.AWV_DUE,
   },
   {
      key: COLUMN.G_CODE,
      text: 'Suggested G Code',
      value: COLUMN.G_CODE,
   },
   {
      key: COLUMN.PREV_AWV_RECORDS,
      text: 'Historical AWV Records',
      value: COLUMN.PREV_AWV_RECORDS,
   },
   {
      key: COLUMN.HCC_CNT_OPEN,
      text: 'HCC Open Count',
      value: COLUMN.HCC_CNT_OPEN,
   },
   {
      key: COLUMN.RAF_MY,
      text: `RAF ${new Date().getFullYear()}`,
      value: COLUMN.RAF_MY,
   },
   {
      key: COLUMN.RAF_PY,
      text: `RAF ${new Date().getFullYear() - 1}`,
      value: COLUMN.RAF_PY,
   },
   {
      key: COLUMN.PAF,
      text: 'Patient Assessment Forms',
      value: COLUMN.PAF,
   },
];

// TODO: Move to provider / member based util file
export const PrvdrViewADTTableColumnList = [
   {
      key: 'facility_name',
      text: 'Facility Name',
      value: 'facility_name',
   },
   {
      key: 'facility_address',
      text: 'Facility Address',
      value: 'facility_address',
   },
   {
      key: 'facility_contact',
      text: 'Facility Contact',
      value: 'facility_contact',
   },
   {
      key: 'patient_class',
      text: 'Visit Type',
      value: 'patient_class',
   },
   {
      key: 'admit_source',
      text: 'Admit Source',
      value: 'admit_source',
   },
   {
      key: 'diagnosis_code_dg1',
      text: 'Diagnoses',
      value: 'diagnosis_code_dg1',
   },
];

// TODO: Move to Display or Label Utils
export const preferredLanguageOptions = [
   { key: 'english', text: 'English', value: 'English' },
   { key: 'spanish', text: 'Spanish', value: 'Spanish' },
   {
      key: 'chineseTraditional',
      text: 'Chinese - Traditional',
      value: 'Chinese - Traditional',
   },
   {
      key: 'chineseSimplified',
      text: 'Chinese - Simplified',
      value: 'Chinese - Simplified',
   },
   { key: 'korean', text: 'Korean', value: 'Korean' },
];

// TODO: Move to provider / member view based util file
export const prvdrAdtSortOptions = [
   { key: 'nameAsc', text: 'Name (A to Z)', value: 'nameAsc' },
   { key: 'nameDesc', text: 'Name (Z to A)', value: 'nameDesc' },
   {
      key: 'msgTypeAsc',
      text: 'Message Type (A to Z)',
      value: 'msgTypeAsc',
   },
   {
      key: 'msgTypeDesc',
      text: 'Message Type (Z to A)',
      value: 'msgTypeDesc',
   },
   {
      key: 'tAdmitAsc',
      text: 'Time Admit (Oldest to Latest)',
      value: 'tAdmitAsc',
   },
   {
      key: 'tAdmitDesc',
      text: 'Time Admit (Latest to Oldest)',
      value: 'tAdmitDesc',
   },
   {
      key: 'tDischargeAsc',
      text: 'Time Discharge (Oldest to Latest)',
      value: 'tDischargeAsc',
   },
   {
      key: 'tDischargeDesc',
      text: 'Time Discharge (Latest to Oldest)',
      value: 'tDischargeDesc',
   },
   {
      key: 'facNameAsc',
      text: 'Facility Name (A to Z)',
      value: 'facNameAsc',
   },
   {
      key: 'facNameDesc',
      text: 'Facility Name (Z to A)',
      value: 'facNameDesc',
   },
   {
      key: 'facAddrAsc',
      text: 'Facility Address (A to Z)',
      value: 'facAddrAsc',
   },
   {
      key: 'facAddrDesc',
      text: 'Facility Address (Z to A)',
      value: 'facAddrDesc',
   },
   {
      key: 'visitTypeAsc',
      text: 'Visit Type (A to Z)',
      value: 'visitTypeAsc',
   },
   {
      key: 'visitTypeDesc',
      text: 'Visit Type (Z to A)',
      value: 'visitTypeDesc',
   },
   {
      key: 'admitSourceAsc',
      text: 'Admit Source (A to Z)',
      value: 'admitSourceAsc',
   },
   {
      key: 'admitSourceDesc',
      text: 'Admit Source (Z to A)',
      value: 'admitSourceDesc',
   },
   {
      key: 'diagnosesAsc',
      text: 'Diagnoses (A to Z)',
      value: 'diagnosesAsc',
   },
   {
      key: 'diagnosesDesc',
      text: 'Diagnoses (Z to A)',
      value: 'diagnosesDesc',
   },
];

// TODO: Move to provider / member view based util file
export const prvdrMemberSortOptions = [
   { key: 'nameAsc', text: 'Name (A to Z)', value: 'nameAsc' },
   { key: 'nameDesc', text: 'Name (Z to A)', value: 'nameDesc' },
   {
      key: 'awvDueAsc',
      text: 'AWV Due (Soonest to Latest)',
      value: 'awvDueAsc',
   },
   {
      key: 'awvDueDesc',
      text: 'AWV Due (Latest to Soonest)',
      value: 'awvDueDesc',
   },
   {
      key: 'rafYtdAsc',
      text: `RAF ${new Date().getFullYear()} (Low to High)`,
      value: 'rafYtdAsc',
   },
   {
      key: 'rafYtdDesc',
      text: `RAF ${new Date().getFullYear()} (High to Low)`,
      value: 'rafYtdDesc',
   },
   {
      key: 'rafPrevAsc',
      text: `RAF ${new Date().getFullYear() - 1} (Low to High)`,
      value: 'rafPrevAsc',
   },
   {
      key: 'rafPrevDesc',
      text: `RAF ${new Date().getFullYear() - 1} (High to Low)`,
      value: 'rafPrevDesc',
   },
   {
      key: 'hccTotalCntAsc',
      text: 'HCC Total Count (Low to High)',
      value: 'hccTotalCntAsc',
   },
   {
      key: 'hccTotalCntDesc',
      text: 'HCC Total Count (High to Low)',
      value: 'hccTotalCntDesc',
   },
   {
      key: 'hccRecaptureCntAsc',
      text: 'HCC Open Count (Low to High)',
      value: 'hccRecaptureCntAsc',
   },
   {
      key: 'hccRecaptureCntDesc',
      text: 'HCC Open Count (High to Low)',
      value: 'hccRecaptureCntDesc',
   },
];

// TODO: Move member list or general query based util file
export const memberListQueryOptionsRefreshState = {
   page: 1,
   patientTags: {
      filterOptions: [],
   },
   nameColumn: {
      sortOption: 'nameAsc',
      filterOptions: [],
   },
   caseStatusColumn: {
      filterOptions: [],
   },
   todoColumn: {
      sortOption: '',
      filterOptions: [],
   },
   healthPlanColumn: {
      sortOption: '',
      filterOptions: [],
   },
   medicalGroupColumn: {
      sortOption: '',
      filterOptions: [],
   },
   ipaColumn: {
      sortOption: '',
      filterOptions: [],
   },
   providerColumn: {
      sortOption: '',
      filterOptions: [],
   },
   careManagerColumn: {
      filterOptions: [],
   },
   careCoordinatorColumn: {
      sortOption: '',
      filterOptions: [],
   },
   nextStepColumn: {
      filterOptions: [],
   },
   awvColumn: {
      sortOption: '',
      filterOptions: [],
   },
   rafColumn: {
      sortOption: '',
   },
   gicColumn: {
      sortOption: '',
      filterOptions: [],
   },
   firstPlanCreatedColumn: {
      sortOption: '',
   },
   latestPlanCreatedColumn: {
      sortOption: '',
   },
   latestPlanModifiedColumn: {
      sortOption: '',
   },
};
