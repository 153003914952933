import React, { useState, useEffect } from 'react';
import { Loader, Grid, Header, Form } from 'semantic-ui-react';
import { useStitchAuth } from '../StitchAuth';
import AppBar from '../Common/AppBar/AppBar';
import NameTimezoneForm from './NameTimezoneForm';
import NpiForm from './NpiForm';
import MatchingTinList from './MatchingTinList';
import TinForm from './TinForm';
import SettingsChangedModal from './SettingsChangedModal';
import ChangeNpiTinErrorMsg from './ChangeNpiTinErrorMsg';
import '../Common/Layout.css';

const gridStyle = {
   margin: 0,
   marginTop: '1em',
   paddingBottom: '5em',
   paddingLeft: '2em',
};

const Settings = () => {
   const {
      userCustomData,
      actions: { updateCustomData },
   } = useStitchAuth();
   const [loading, setLoading] = useState(true);
   const [userName, setUserName] = useState({
      firstName: '',
      lastName: '',
   });
   const [timeZone, setTimeZone] = useState('');
   const [npi, setNpi] = useState('');
   const [isNpiValid, setIsNpiValid] = useState(false);
   const [tin, setTin] = useState('');
   const [changed, setChanged] = useState(false);

   useEffect(() => {
      setLoading(true);
      if (userCustomData) {
         setUserName({
            firstName: userCustomData.name.substring(
               0,
               userCustomData.name.indexOf(' '),
            ),
            lastName: userCustomData.name.substring(
               userCustomData.name.indexOf(' ') + 1,
            ),
         });
         setTimeZone(
            userCustomData.timeZone || 'America/Los Angeles',
         );
         setNpi(userCustomData.npi || '');
         setIsNpiValid(true);
         setTin(userCustomData.tin || '');
         setLoading(false);
      }
   }, [userCustomData]);

   const handleSubmit = (event) => {
      event.preventDefault();
      const newUserInfo = {
         name: userName.firstName + ' ' + userName.lastName,
         timeZone: timeZone,
      };

      // These blocks are needed to avoid permissions issue
      // for users who can no longer change their own NPI / TIN.
      // Sending the NPI & TIN along with name & timeZone causes
      // a permission error if user is role "confirmedInfoProvider".
      if (!userCustomData.npi) {
         newUserInfo.npi = npi;
      }

      if (!userCustomData.tin) {
         newUserInfo.tin = tin;
      }

      updateCustomData(newUserInfo);
      setChanged(true);
   };

   const validateSettingsForm = () => {
      return !(
         (
            userName.firstName !== '' &&
            userName.lastName !== '' &&
            isNpiValid
         )

         // TODO: Implement form validation for NPI / TIN for providers
         // && npi.length === 10 &&
         // tin.length === 9
      );
   };

   if (loading) {
      return (
         <div className="app-layout">
            <AppBar />
            <Loader active>Loading settings...</Loader>
         </div>
      );
   } else {
      return (
         <div className="app-layout">
            <AppBar />
            <Grid style={gridStyle}>
               <Grid.Row>
                  <Grid.Column>
                     <Header>Change User Settings</Header>
                  </Grid.Column>
               </Grid.Row>
               <NameTimezoneForm
                  userName={userName}
                  setUserName={setUserName}
                  timeZone={timeZone}
                  setTimeZone={setTimeZone}
               />
               <NpiForm
                  npi={npi}
                  setNpi={setNpi}
                  isNpiValid={isNpiValid}
                  setIsNpiValid={setIsNpiValid}
                  currentNpi={userCustomData.npi || ''}
               />
               <TinForm
                  tin={tin}
                  setTin={setTin}
                  currentTin={userCustomData.tin || ''}
               />
               {userCustomData.tin || userCustomData.npi ? (
                  <ChangeNpiTinErrorMsg />
               ) : null}
               <Form.Button
                  primary
                  disabled={validateSettingsForm()}
                  onClick={handleSubmit}
               >
                  Change Settings
               </Form.Button>
            </Grid>
            <Grid style={gridStyle}>
               <MatchingTinList />
            </Grid>
            <SettingsChangedModal
               changed={changed}
               setChanged={setChanged}
            />
         </div>
      );
   }
};
export default Settings;
