import React, { useState, useEffect } from 'react';
import { Table, Loader, Header, Grid } from 'semantic-ui-react';
import { useStitchAuth } from '../StitchAuth';

const MatchingTinList = () => {
   const {
      userCustomData,
      db: { users },
   } = useStitchAuth();
   const [matchingUsersList, setMatchingUsersList] = useState([]);
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      setLoading(true);
      if (userCustomData && userCustomData.tin !== '') {
         users
            .find({
               $and: [
                  { tin: { $exists: true } },
                  { tin: userCustomData.tin },
               ],
            })
            .then((res) => {
               setMatchingUsersList(
                  Array.from(res).filter(
                     (user) => user.id !== userCustomData.id,
                  ),
               );
               setLoading(false);
            });
      } else {
         setMatchingUsersList([]);
         setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userCustomData]);

   if (loading) {
      return (
         <Grid.Row>
            <Grid.Column width={12}>
               <Loader active>Fetching users...</Loader>
            </Grid.Column>
         </Grid.Row>
      );
   } else {
      return (
         <Grid.Row>
            <Grid.Column width={12}>
               <Header>Matching TIN Users</Header>
               <Table basic="very">
                  <Table.Header>
                     <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>NPI</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                     </Table.Row>
                  </Table.Header>
                  <Table.Body>
                     {matchingUsersList.length ? (
                        <>
                           {matchingUsersList.map((user, index) => (
                              <Table.Row key={index} columns={3}>
                                 <Table.Cell>{user.name}</Table.Cell>
                                 <Table.Cell>{user.npi}</Table.Cell>
                                 <Table.Cell>{user.email}</Table.Cell>
                              </Table.Row>
                           ))}
                        </>
                     ) : (
                        <Table.Row>
                           <Table.Cell>
                              <Header>
                                 <em
                                    style={{
                                       marginTop: '1em',
                                       fontWeight: '300',
                                    }}
                                 >
                                    No other users matching TIN
                                 </em>
                              </Header>
                           </Table.Cell>
                        </Table.Row>
                     )}
                  </Table.Body>
               </Table>
            </Grid.Column>
         </Grid.Row>
      );
   }
};

export default MatchingTinList;
