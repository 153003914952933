// TODO: Refactor provider titles to use this enum
export const MENU_TITLES = Object.freeze({
   // CONTROL_PANEL: 'control-panel',
   // RULES: 'rules',
   // ALERT_SETTINGS: 'alert-settings',
   // NOTES: 'notes',
   // LETTERS: 'letters',
   // FILES: 'files',
   // CARE_PLAN_FORM: 'care-plan-form',
   // CALL_MEMBER: 'call-member',
});

export const URL = Object.freeze({
   AWV_CHASE_LIST: '/prvdrview-awv-chase-list',
   ADT_FEED: '/prvdrview-adt-feed',
   REGISTER_USERS: '/register-users',
   SETTINGS: '/settings',
});
