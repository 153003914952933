import * as Realm from 'realm-web';
import { app } from './app.js';

export function hasLoggedInUser() {
   // Check if there is currently a logged in user
   return app.currentUser;
}

export function getCurrentUser() {
   // Return the user object of the currently logged in user
   return app.currentUser;
}

export function switchUser(currentUser) {
   // Switches to another logged in user
   app.switchUser(currentUser);
}

export async function removeUser(newUser) {
   //logs them out and remove them from local session
   await app.removeUser(newUser);
}

export const getCustomData = async (clientCode = 'orma') => {
   if (app.currentUser) {
      const mongodb = app.currentUser.mongoClient('mongodb-atlas');
      const users = mongodb.db('pathwaysAco').collection('users');
      const groups = mongodb.db('pathwaysAco').collection('groups');

      let customData = await users.findOne({
         id: app.currentUser.id,
      });

      if (!customData) {
         customData = {
            name: '',
            namePublic: '',
            timeZone: 'America/Los_Angeles',
            role: 'user',
            group_id: app.currentUser.profile.email,
            id: app.currentUser.id,
            email: app.currentUser.profile.email,
            personas: ['provider'],
            currentPersona: 'provider',
         };

         await users.insertOne(customData);
      }

      const groupData = await groups.findOne({
         group_id: customData.group_id,
      });

      const data = { ...groupData, ...customData };

      return data;
   } else {
      return null;
   }
};

export function logoutCurrentUser() {
   // Logout the currently logged in user
   return app.currentUser.logOut();
}

export async function signupUser(email, password) {
   return await app.emailPasswordAuth.registerUser(email, password);
}

export async function confirmEmail(token, tokenId) {
   return await app.emailPasswordAuth.confirmUser(token, tokenId);
}

export async function loginUser(email, password) {
   const credentials = Realm.Credentials.emailPassword(
      email,
      password,
   );
   //const credential = new UserPasswordCredential(email, password);
   try {
      const user = await app.logIn(credentials);
      //assert(user.id===app.currentUser.id);
      return user;
   } catch (err) {
      console.error('Failed to log in', err);
      return err;
   }
}

export async function sendResetPasswordEmail(email) {
   return await app.emailPasswordAuth.sendResetPasswordEmail({
      email: email,
   });
}

export async function resetPassword(token, tokenId, newPassword) {
   return await app.emailPasswordAuth.resetPassword({
      token,
      tokenId,
      password: newPassword,
   });
}
