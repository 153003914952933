import { Icon, Modal } from 'semantic-ui-react';
import { BlueDownloadButton } from '../../Common/StyledComponents/Buttons/Buttons';

const RegisterSingleFailureModal = ({
   refreshState,
   registerFailure,
}) => {
   return (
      <Modal
         centered={true}
         open={registerFailure}
         onClose={refreshState}
         style={{
            height: '308px',
            width: '424px',
         }}
         dimmer="inverted"
      >
         <Icon
            link
            color="black"
            name="close"
            onClick={refreshState}
            style={{
               textDecoration: 'none',
               position: 'absolute',
               left: '381px',
               top: '12px',
            }}
         />
         <Modal.Content
            style={{
               textAlign: 'center',
            }}
         >
            <Modal.Description>
               <Icon
                  size="large"
                  name="times circle"
                  style={{
                     color: '#D80505',
                     fontSize: '60px',
                     marginTop: '20px',
                  }}
               />
               <p
                  style={{
                     fontSize: '20px',
                     fontWeight: 'bold',
                     marginTop: '32px',
                     marginBottom: '4px',
                  }}
               >
                  This Email Is In Use
               </p>
               <p
                  style={{
                     fontSize: '14px',
                     width: '300px',
                     color: '#7B7B7B',
                     marginLeft: 'auto',
                     marginRight: 'auto',
                  }}
               >
                  Another user with the same email already exists.
                  Please try a different email.
               </p>
               <BlueDownloadButton
                  size="large"
                  onClick={refreshState}
                  style={{
                     fontSize: '14px',
                     marginTop: '18px',
                     padding: '11px 12px',
                  }}
               >
                  Return to Registration
               </BlueDownloadButton>
            </Modal.Description>
         </Modal.Content>
      </Modal>
   );
};

export default RegisterSingleFailureModal;
