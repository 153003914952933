export const COLUMN = Object.freeze({
   AWV_COMPLETED: 'awv_is_due',
   AWV_DUE: 'awv_due',
   G_CODE: 'gcode',
   HCC_CNT_OPEN: 'hcc_cnt_open',
   PREV_AWV_RECORDS: 'prev_awv_records',
   RAF_MY: 'raf_my',
   RAF_PY: 'raf_py',
   PAF: 'paf',
});
