import React from 'react';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { formatNumbersOnly } from '../../Common/arqUtils';
import { FORM_ERRORS } from './userBasicInformationUtils';
import { Blue } from '../../Common/StyledComponents/Colors';
import { FieldInput } from '../../Common/StyledComponents/Inputs/Inputs';

const FormLabels = styled.label`
   display: inline;
   fontsize: 16px;
   font-weight: 400;
`;

const MessageLabel = styled.div`
   background-color: ${Blue.BlueLight1};
   border: 1px solid ${Blue.BlueLight2};
   border-radius: 4px;
   padding: 10px 8px;
   color: ${Blue.BlueDark1};
   margin-bottom: 16px;
   font-family: 'Lato';
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 18px;
`;

export const ProviderInfoSection = ({
   userProfile,
   setUserProfile,
   errorMessages,
}) => {
   const hasNpiError =
      errorMessages.includes(FORM_ERRORS.NPI_REQUIRED) ||
      errorMessages.includes(FORM_ERRORS.NPI_LENGTH);
   const hasTinError =
      errorMessages.includes(FORM_ERRORS.TIN_REQUIRED) ||
      errorMessages.includes(FORM_ERRORS.TIN_LENGTH);
   const hasEmailAddressError = errorMessages.includes(
      FORM_ERRORS.EMAIL_REQUIRED,
   );
   return (
      <>
         {userProfile?.personas.includes('provider') ? (
            <div>
               <Form.Field>
                  <FormLabels>NPI</FormLabels>
                  <FieldInput
                     type="text"
                     required
                     maxLength="10"
                     fluid
                     placeholder="NPI (10 digits)"
                     error={hasNpiError}
                     value={userProfile.npi}
                     onChange={(e) =>
                        setUserProfile({
                           ...userProfile,
                           npi: formatNumbersOnly(e.target.value),
                        })
                     }
                     style={{
                        marginTop: '8px',
                        marginBottom: '16px',
                     }}
                  />
               </Form.Field>
               <Form.Field>
                  <FormLabels>TIN</FormLabels>
                  <FieldInput
                     fluid
                     name="tin"
                     placeholder="TIN (9 digits)"
                     value={userProfile.tin}
                     error={hasTinError}
                     required
                     maxLength="9"
                     onChange={(e) =>
                        setUserProfile({
                           ...userProfile,
                           tin: formatNumbersOnly(e.target.value),
                        })
                     }
                     style={{
                        marginTop: '8px',
                        marginBottom: '16px',
                     }}
                  />
               </Form.Field>
               <MessageLabel>
                  Editing email is not allowed at this time. Please
                  contact us if you need to change your email.
               </MessageLabel>
               <Form.Field>
                  <FormLabels>Email</FormLabels>
                  <FieldInput
                     fluid
                     name="email"
                     placeholder="Email Address"
                     error={hasEmailAddressError}
                     value={userProfile.email}
                     type="text"
                     required
                     disabled={true}
                     style={{
                        marginTop: '8px',
                        marginBottom: '16px',
                     }}
                  />
               </Form.Field>
            </div>
         ) : null}
      </>
   );
};
