import UAParser from 'ua-parser-js';

/**
 * Parses the user agent string of the browser to
 * detect browser and machine metadata
 * 
 * @returns object containing browser and machine metadata
}
 */
export const getBrowserMetadata = () => {
   const userAgentParser = new UAParser();
   const userAgentString = userAgentParser.getUA();
   const browser = userAgentParser.getBrowser();
   const engine = userAgentParser.getEngine();
   const os = userAgentParser.getOS();
   const device = userAgentParser.getDevice();
   const cpu = userAgentParser.getCPU();
   return {
      ua: userAgentString,
      browser_name: browser?.name,
      browser_version: browser?.version,
      engine_name: engine?.name,
      engine_version: engine?.version,
      os_name: os?.name,
      os_version: os?.version,
      device_model: device?.model,
      device_type: device?.type,
      device_vendor: device?.vendor,
      cpu_name: cpu?.architecture,
      pathname: window?.location?.pathname,
      origin: window?.location?.origin,
   };
};
