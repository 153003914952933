export const PERSONA = Object.freeze({
   EXECUTIVE: 'executive',
   PROVIDER: 'provider',
   PVD2: 'pvd2',
   OFFICE_MANAGER: 'office-manager',
   CARE_MANAGER: 'care-manager',
   PR_REPS: 'pr-reps',
   DEV: 'dev',
});

export const PERSONA_LABELS = Object.freeze({
   [PERSONA.EXECUTIVE]: 'Executive',
   [PERSONA.PROVIDER]: 'Provider',
   [PERSONA.OFFICE_MANAGER]: 'Office Manager',
   [PERSONA.CARE_MANAGER]: 'Care Manager',
   [PERSONA.PR_REPS]: 'PR Reps',
   [PERSONA.DEV]: 'Dev',
});

export const PERSONA_DROPDOWN_OPTIONS = [
   {
      key: PERSONA.PROVIDER,
      text: PERSONA_LABELS[PERSONA.PROVIDER],
      value: PERSONA.PROVIDER,
   },
   {
      key: PERSONA.PR_REPS,
      text: PERSONA_LABELS[PERSONA.PR_REPS],
      value: PERSONA.PR_REPS,
   },
   // {
   //   key: PERSONA.OFFICE_MANAGER,
   //   text: PERSONA_LABELS[PERSONA.OFFICE_MANAGER],
   //   value: PERSONA.OFFICE_MANAGER,
   // },
];

export const PERSONA_REGISTER_USERS_OPTIONS = [
   {
      key: PERSONA.PROVIDER,
      text: PERSONA_LABELS[PERSONA.PROVIDER],
      value: PERSONA.PROVIDER,
   },
   {
      key: PERSONA.PR_REPS,
      text: PERSONA_LABELS[PERSONA.PR_REPS],
      value: PERSONA.PR_REPS,
   },
   // {
   //   key: PERSONA.OFFICE_MANAGER,
   //   text: PERSONA_LABELS[PERSONA.OFFICE_MANAGER],
   //   value: PERSONA.OFFICE_MANAGER,
   // },
];

export const USER_ROLES = {
   USER: 'user',
   ADMIN: 'admin',
};
