export const PAGE_NAMES = Object.freeze({
   LOGIN: 'login',
   PASSWORD_RESET: 'passwordReset',
   AWV_CHASE_LIST: 'awvChaseList',
   MEMBER_DETAIL_PAGE: 'memberDetail',
   USER_BASIC_INFORMATION_PAGE: 'userBasicInformation',
});

export const LOGIN_EVENTS = Object.freeze({
   LOGIN_SUCCESS: 'loginRequest_success',
});

export const PASSWORD_RESET_EVENTS = Object.freeze({
   RESET_SUCCESS: 'passwordReset_success',
   RESET_FAILURE: 'passwordReset_failure',
});

export const AWV_CHASE_LIST_EVENTS = Object.freeze({
   DOWNLOAD_TABLE_CLICKED: 'downloadAwvTableBtn_click',
   DOWNLOAD_TABLE_SUCCESS:
      'downloadAwvTable_fileDownloadedRequest_success',
   DOWNLOAD_TABLE_FAILURE:
      'downloadAwvTable_fileDownloadedRequest_failure',
   DOWNLOAD_PAF_CLICKED: 'downloadPafBtn_click',
   DOWNLOAD_PAF_SUCCESS:
      'downloadPafPdf_fileDownloadedRequest_success',
   DOWNLOAD_PAF_FAILURE:
      'downloadPafPdf_fileDownloadedRequest_failure',
   PRINT_TABLE_CLICKED: 'printAwvTableBtn_click',
   PRINT_TABLE_SUCCESS: 'printAwvTable_filePrintRequest_success',
   PRINT_TABLE_FAILURE: 'printAwvTable_filePrintRequest_failure',
});

export const USER_BASIC_INFORMATION_EVENTS = Object.freeze({
   LANDING_PAGE_LOADED: 'landingPage_loaded',
   SUBMIT_CLICK: 'submitBtn_click',
   FORM_VALIDATION_ERROR: 'formValidation_error',
   SUBMIT_REQUEST_SUCCESS: 'submitRequest_success',
   SUBMIT_REQUEST_FAILURE: 'submitRequest_failure',
});

export const MEMBER_DETAIL_EVENTS = Object.freeze({
   LANDING_PAGE_LOADED: 'landingPage_loaded',
   DOWNLOAD_PAF_CLICKED: 'downloadPafBtn_click',
   DOWNLOAD_PAF_SUCCESS:
      'downloadPafPdf_fileDownloadedRequest_success',
   DOWNLOAD_PAF_FAILURE:
      'downloadPafPdf_fileDownloadedRequest_failure',
});
