import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Grid, Dimmer, Loader } from 'semantic-ui-react';
import { useStitchAuth } from '../StitchAuth';
import { getCustomData } from '../../realm/authentication';
import { pathnameSelection } from '../Common/arqUtils';
import LoginForm from './LoginForm';
import { TextColors, Blue } from '../Common/StyledComponents/Colors';

const MORNING_START_HR = 5;
const MORNING_END_HR = 12;
const EVENING_START_HR = 18;
const MIDNIGHT_HOUR = 0;

const Login = () => {
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [formError, setFormError] = useState(false);
   const [inProcess, setInProcess] = useState(false);
   const {
      actions: { handleEmailPasswordLogin },
   } = useStitchAuth();
   const history = useHistory();

   const handleSubmit = () => {
      setInProcess(true);
      handleEmailPasswordLogin(email, password)
         .then(async () => {
            const customUser = await getCustomData();
            const pathname = pathnameSelection(
               customUser.currentPersona,
            );

            history.replace({
               pathname: pathname,
            });
         })
         .catch(() => {
            setInProcess(false);
            setFormError(true);
         });
   };

   /**
    *
    * @param {Number} currTime - Current time in hours
    * @returns {Object} - {img: imagePath, msg: "Message GreetingText", color: <text color>}
    */
   function handleBackgroundSelection(currTime) {
      // If the current time falls during the morning hours, use Morning greeting
      if (currTime >= MORNING_START_HR && currTime < MORNING_END_HR) {
         return {
            backgroundColor: Blue.BlueLight1,
            msg: 'ACO Portal',
            color: TextColors.DiscoPurpleText,
         };
         // If the current time falls after evening start, or after midnight and before mornign start
      } else if (
         currTime >= EVENING_START_HR ||
         (currTime >= MIDNIGHT_HOUR && currTime < MORNING_START_HR)
      ) {
         return {
            backgroundColor: Blue.BlueLight1,
            msg: 'ACO Portal',
            color: TextColors.SemanticWhite,
         };
      } else {
         return {
            backgroundColor: Blue.BlueLight1,
            msg: 'ACO Portal',
            color: TextColors.BlueDarkText,
         };
      }
   }

   return (
      <div>
         <Dimmer inverted active={inProcess}>
            <Loader />
         </Dimmer>
         <Grid
            style={{
               backgroundColor: `${
                  handleBackgroundSelection(moment().hour())
                     .backgroundColor
               }`,
               backgroundSize: 'cover',
               height: '100vh',
               margin: 0,
            }}
         >
            <LoginForm
               formError={formError}
               email={email}
               setEmail={setEmail}
               password={password}
               setPassword={setPassword}
               handleSubmit={handleSubmit}
               handleBackgroundSelection={handleBackgroundSelection}
            />
         </Grid>
      </div>
   );
};

export default Login;
