import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';
import { updateSearchParams } from '../../../Common/arqUtils';
import FilterToggle from './FilterToggle';

const FILTER = Object.freeze({
   ONE_MO_OVER: 'oneMoOver',
   DUE_CURR_MO: 'dueCurrMo',
   DUE_NEXT_MO: 'dueNextMo',
   DUE_3_MO: 'due3Mo',
   DUE_CURR_YR: 'dueCurrYr',
});

const AwvChaseListFilters = ({ query, basePath }) => {
   const [oneMonthOverdue, setOneMonthOverdue] = useState(false);
   const [dueThisMonth, setDueThisMonth] = useState(false);
   const [dueNextMonth, setDueNextMonth] = useState(false);
   const [due3Months, setDue3Months] = useState(false);
   const [dueThisYear, setDueThisYear] = useState(false);
   const currentMonthYear = moment();
   const thisMonth = currentMonthYear.format('MMM');
   const nextMonth = currentMonthYear
      .clone()
      .add(1, 'months')
      .format('MMM');
   const thisYear = currentMonthYear.format('YYYY');
   const location = useLocation();
   const history = useHistory();

   useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const filters = searchParams.get('filter');

      if (filters) {
         const parsedFiltersArray = filters.split(',');

         setOneMonthOverdue(
            parsedFiltersArray.includes(FILTER.ONE_MO_OVER),
         );
         setDueThisMonth(
            parsedFiltersArray.includes(FILTER.DUE_CURR_MO),
         );
         setDueNextMonth(
            parsedFiltersArray.includes(FILTER.DUE_NEXT_MO),
         );
         setDue3Months(parsedFiltersArray.includes(FILTER.DUE_3_MO));
         setDueThisYear(
            parsedFiltersArray.includes(FILTER.DUE_CURR_YR),
         );
      }

      // On Mount behavior only
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   /**
    * This function takes in the unique
    * filterKey (oneMoOver, dueCurrMo...) and
    * the respective useState variable & setter
    * function to handle both URL & element changes.
    * @param {String} filterKey
    * @param {Boolean} currentState state variable
    * @param {Function} setStateFunc setter function
    */
   const handleFilterClick = (
      filterKey,
      currentState,
      setStateFunc,
   ) => {
      const searchParams = new URLSearchParams(location.search);
      let existingFilters = [];

      if (searchParams.get('filter')) {
         existingFilters = searchParams.get('filter')?.split(',');
      }

      const index = existingFilters.indexOf(filterKey);

      if (currentState && index > -1) {
         existingFilters.splice(index, 1);
      } else {
         existingFilters.push(filterKey);
      }

      const updatedFilterParam =
         existingFilters.length > 0
            ? existingFilters.join(',')
            : null;

      setStateFunc(!currentState);
      updateSearchParams(
         history,
         query,
         basePath,
         'filter',
         updatedFilterParam,
      );
   };

   return (
      <span style={{ display: 'inline-block' }}>
         <div style={{ marginBottom: '8px' }}>
            <strong>AWV Due Dates</strong>
         </div>
         <FilterToggle
            toggleSelected={oneMonthOverdue}
            displayText={'+1mo Overdue'}
            onClickHandler={() =>
               handleFilterClick(
                  FILTER.ONE_MO_OVER,
                  oneMonthOverdue,
                  setOneMonthOverdue,
               )
            }
         />
         <FilterToggle
            toggleSelected={dueThisMonth}
            displayText={`Due in ${thisMonth}`}
            onClickHandler={() =>
               handleFilterClick(
                  FILTER.DUE_CURR_MO,
                  dueThisMonth,
                  setDueThisMonth,
               )
            }
         />
         <FilterToggle
            toggleSelected={dueNextMonth}
            displayText={`Due in ${nextMonth}`}
            onClickHandler={() =>
               handleFilterClick(
                  FILTER.DUE_NEXT_MO,
                  dueNextMonth,
                  setDueNextMonth,
               )
            }
         />
         <FilterToggle
            toggleSelected={due3Months}
            displayText={'Due in next 3mo'}
            onClickHandler={() =>
               handleFilterClick(
                  FILTER.DUE_3_MO,
                  due3Months,
                  setDue3Months,
               )
            }
         />
         <FilterToggle
            toggleSelected={dueThisYear}
            displayText={`Due in ${thisYear}`}
            onClickHandler={() =>
               handleFilterClick(
                  FILTER.DUE_CURR_YR,
                  dueThisYear,
                  setDueThisYear,
               )
            }
         />
      </span>
   );
};

export default AwvChaseListFilters;
