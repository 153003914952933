import React from 'react';
import { Form, Grid } from 'semantic-ui-react';
import { formatNumbersOnly } from '../Common/arqUtils';

const TinForm = ({ tin, setTin, currentTin }) => {
   const handleTinChange = (event) => {
      setTin(formatNumbersOnly(event.target.value));
   };

   const checkDisableTin = () => {
      if (currentTin !== '') {
         return true;
      } else {
         return false;
      }
   };

   return (
      <Grid.Row style={{ marginTop: '-28px' }}>
         <Grid.Column>
            <label>
               <p>TIN (9 digits)</p>
            </label>
            <Form.Field>
               <Form.Input
                  style={{ marginTop: '8px', marginBottom: '16px' }}
                  type="text"
                  disabled={checkDisableTin()}
                  required
                  fluid
                  name="tin"
                  placeholder="TIN"
                  value={tin}
                  onChange={handleTinChange}
               />
            </Form.Field>
         </Grid.Column>
      </Grid.Row>
   );
};

export default TinForm;
