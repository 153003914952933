import React from 'react';
import { Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AcoLogoBlue } from '../../Common/StyledComponents/Logos/AcoLogo';
import { TextColors } from '../../Common/StyledComponents/Colors';
import { getAwvScriptLastUpdatedDate } from './ContentTableUtils';

const PrintHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%
  height: 90px;
`;

const ProviderInfoToPrint = ({ memberResponse }) => {
   return (
      <PrintHeader>
         <div>
            <AcoLogoBlue />
         </div>
         {memberResponse?.data.length ? (
            <div style={{ width: '40%' }}>
               <Input
                  fluid={true}
                  value={`${memberResponse?.data?.[0]?.pcp_name
                     .substring(
                        memberResponse?.data?.[0]?.pcp_name.indexOf(
                           ' ',
                        ),
                     )
                     .toUpperCase()}, ${memberResponse?.data?.[0].pcp_name
                     .substring(
                        0,
                        memberResponse?.data?.[0]?.pcp_name.indexOf(
                           ' ',
                        ),
                     )
                     .toUpperCase()} (${
                     memberResponse?.data?.[0]?.pcp_npi
                  })`}
               />
               <p
                  style={{
                     color: TextColors.GreyText,
                     marginTop: '10px',
                  }}
               >
                  {getAwvScriptLastUpdatedDate(memberResponse?.data)}
               </p>
            </div>
         ) : null}
      </PrintHeader>
   );
};

ProviderInfoToPrint.propTypes = {
   memberResponse: PropTypes.object,
};

export default ProviderInfoToPrint;
