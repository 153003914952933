import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import ObjectID from 'bson-objectid';
import { useStitchAuth } from './StitchAuth';
// Create a React Context that lets us expose and access auth state
// without passing props through many levels of the component tree
const StitchMembersContext = createContext();

// Create a React Hook that lets us get data from our auth context
export function useStitchMembers() {
   const context = React.useContext(StitchMembersContext);
   if (!context) {
      throw new Error(
         `useStitchMembers must be used within a StitchMembersProvider`,
      );
   }
   return context;
}

// TODO: CM-714 Fill out JSDOC comments
export function StitchMembersProvider(props) {
   const {
      // TODO: Verify if we can remove these
      // currentUser,
      //userCustomData,
      db: { members /* claims */ },
   } = useStitchAuth();
   const [memberAll, setMemberAll] = useState();
   const [memberOne, setMemberOne] = useState();

   // We useMemo to improve performance by eliminating some re-renders
   const memberInfo = React.useMemo(() => {
      const reset = () => {
         setMemberAll(null);
         setMemberOne(null);
      };

      // TODO: CM-714 Fill out JSDOC comments
      /**
       *
       * @param {*} memberId
       */
      const loadMember = async (memberId) => {
         const fetchMemberBaseData = async () => {
            const memberData =
               (await members.findOne({
                  _id: ObjectID(memberId),
               })) || {};
            if (memberData) {
               memberData._id = ObjectID(
                  memberData._id,
               ).toHexString();
            }
            return memberData;
         };

         if (memberId) {
            const memberData = await fetchMemberBaseData();
            setMemberOne(memberData);
         }
      };

      const value = {
         memberAll,
         memberOne,
         actions: {
            reset,
            loadMember,
         },
      };
      return value;
   }, [memberAll, memberOne, members]);
   return (
      <StitchMembersContext.Provider value={memberInfo}>
         {props.children}
      </StitchMembersContext.Provider>
   );
}

StitchMembersProvider.propTypes = {
   children: PropTypes.element,
};
