import React from 'react';
import { PropTypes } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Form, Dropdown } from 'semantic-ui-react';
import {
   useMembersListQuery,
   updateSearchParams,
} from '../../Common/arqUtils';
import { prvdrMemberSortOptions } from '../../Common/Values';
import { getAwvScriptLastUpdatedDate } from './ContentTableUtils';
import { TextColors } from '../../Common/StyledComponents/Colors';

const SortOptionsDropdown = ({
   basePath,
   memberData,
   loadingData,
}) => {
   const query = useMembersListQuery();
   const history = useHistory();

   return (
      <Form>
         <Form.Group widths="1">
            <Form.Field>
               <label>Sort</label>
               <Dropdown
                  fluid
                  selection
                  options={prvdrMemberSortOptions}
                  defaultValue={query.sortParam || 'awvDueAsc'}
                  onChange={(e, data) =>
                     updateSearchParams(
                        history,
                        query,
                        basePath,
                        'sortParam',
                        data.value,
                     )
                  }
                  style={{ width: 250 }}
               />
            </Form.Field>
         </Form.Group>
         {loadingData ? null : (
            <p
               style={{
                  color: TextColors.GreyText,
                  margin: 0,
               }}
            >
               {getAwvScriptLastUpdatedDate(memberData)}
            </p>
         )}
      </Form>
   );
};

SortOptionsDropdown.propTypes = {
   basePath: PropTypes.string,
   memberData: PropTypes.arrayOf(PropTypes.object),
   loadingData: PropTypes.bool,
};

export default SortOptionsDropdown;
