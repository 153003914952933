import React, { useCallback, useEffect, useRef } from 'react';
import { Grid, Icon, Loader } from 'semantic-ui-react';
import { useReactToPrint } from 'react-to-print';
import { saveSync } from 'save-file';
import PropTypes from 'prop-types';
import { useStitchAuth } from '../../StitchAuth';
import ProviderInfoToPrint from './ProviderInfoToPrint';
import {
   createCsvFileFromMembers,
   formatSearchRequest,
} from './ContentTableUtils';
import {
   AWV_CHASE_LIST_EVENTS,
   PAGE_NAMES,
} from '../../Common/ActivityData/ActivityDataEvents';
import { GreyPopup } from '../../Common/StyledComponents/Popups/Popups';
import { BlueDownloadButton } from '../../Common/StyledComponents/Buttons/Buttons';

const AwvChaseListActions = ({
   query,
   location,
   loadingToDownload,
   loadingToPrint,
   memberData,
   componentToPrint,
   setLoading,
   setLoadingToDownload,
   setLoadingToPrint,
   setMemberData,
   addProviderInfoToPrint,
   setAddProviderInfoToPrint,
}) => {
   const {
      actions: { handleFetchMembers, handleLogActivityData },
   } = useStitchAuth();
   const onBeforeGetContentResolve = useRef(null);

   const handleOnBeforeGetContent = useCallback(() => {
      // Log event data for print table clicked
      handleLogActivityData({
         name: AWV_CHASE_LIST_EVENTS.PRINT_TABLE_CLICKED,
         pageName: PAGE_NAMES.AWV_CHASE_LIST,
      });
      setLoadingToPrint(!loadingToPrint);
      const prevMemberData = memberData;
      const getAllMembers = true;
      const searchParams = new URLSearchParams(location.search);
      const searchRequest = formatSearchRequest(
         searchParams,
         getAllMembers,
      );

      handleFetchMembers(searchRequest).then(async (res) => {
         if (res.success) {
            const memberResponse = res.data;

            setMemberData(memberResponse);
            /* Adding Header to the component before printing */
            setAddProviderInfoToPrint(
               <>
                  <ProviderInfoToPrint
                     memberResponse={memberResponse}
                  />
               </>,
            );
            // Log event data for print table success
            handleLogActivityData({
               name: AWV_CHASE_LIST_EVENTS.PRINT_TABLE_SUCCESS,
               pageName: PAGE_NAMES.AWV_CHASE_LIST,
            });
            setLoading(false);
         } else {
            // Log event data for print table failure
            handleLogActivityData({
               name: AWV_CHASE_LIST_EVENTS.PRINT_TABLE_FAILURE,
               pageName: PAGE_NAMES.AWV_CHASE_LIST,
            });
         }
      });

      return new Promise((resolve) => {
         onBeforeGetContentResolve.current = resolve;

         setTimeout(() => {
            setLoadingToPrint(false);
            setMemberData(prevMemberData);
            setAddProviderInfoToPrint();
            // Reset the Promise resolve so we can print again
            resolve();
            // set enough time for the content to be set before print dialog opens
         }, 2000);
      });
   }, [
      loadingToPrint,
      handleFetchMembers,
      handleLogActivityData,
      setAddProviderInfoToPrint,
      setLoading,
      setLoadingToPrint,
      setMemberData,
      location,
      memberData,
   ]);

   const printContent = useCallback(() => {
      return componentToPrint.current; //piece of component we want to print
   }, [componentToPrint]);

   const handlePrint = useReactToPrint({
      content: printContent,
      documentTitle: `memberList-${Date.now()}`, // name of the file if saving as pdf
      onBeforeGetContent: handleOnBeforeGetContent,
      removeAfterPrint: true,
   });

   useEffect(() => {
      // We watch for the state to change here, and for the Promise resolve to be available
      if (
         addProviderInfoToPrint &&
         typeof onBeforeGetContentResolve.current === 'function'
      ) {
         // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
         onBeforeGetContentResolve.current();
      }
   }, [addProviderInfoToPrint]);

   const handleDownloadTableAsCsv = async () => {
      setLoadingToDownload(true);
      setLoading(true);
      // Log event data for download table clicked
      handleLogActivityData({
         name: AWV_CHASE_LIST_EVENTS.DOWNLOAD_TABLE_CLICKED,
         pageName: PAGE_NAMES.AWV_CHASE_LIST,
      });
      const getAllMembers = true;
      const searchParams = new URLSearchParams(location.search);
      const searchRequest = formatSearchRequest(
         searchParams,
         getAllMembers,
      );
      handleFetchMembers(searchRequest).then(async (res) => {
         if (res.success) {
            const memberResponse = res.data;
            const csvData = createCsvFileFromMembers(memberResponse);
            await saveSync(csvData, `memberList-${Date.now()}.csv`);
            // Log event data for download table success
            handleLogActivityData({
               name: AWV_CHASE_LIST_EVENTS.DOWNLOAD_TABLE_SUCCESS,
               pageName: PAGE_NAMES.AWV_CHASE_LIST,
            });
            setLoading(false);
            setLoadingToDownload(false);
         } else {
            // Log event data for download table failure
            handleLogActivityData({
               name: AWV_CHASE_LIST_EVENTS.DOWNLOAD_TABLE_FAILURE,
               pageName: PAGE_NAMES.AWV_CHASE_LIST,
            });
         }
      });
   };

   return (
      <Grid.Row columns={1}>
         <Grid.Column style={{ display: 'flex', gap: 10 }}>
            <BlueDownloadButton
               onClick={() => handleDownloadTableAsCsv()}
               disabled={
                  (query.npi !== null &&
                     (query.npi.includes('all') ||
                        query.npi.split(',').length > 1)) ||
                  query.npi === '' ||
                  query.npi === 'none' ||
                  location.search === '' ||
                  loadingToDownload
               }
               style={{
                  padding: '11px 13.5px',
               }}
            >
               {loadingToDownload ? (
                  <>
                     <Loader size="mini" active inline />
                  </>
               ) : (
                  <>
                     <Icon name="download" />
                     &nbsp; Download Table
                  </>
               )}
            </BlueDownloadButton>
            <GreyPopup
               disabled={
                  !(
                     (query.npi !== null &&
                        (query.npi.includes('all') ||
                           query.npi.split(',').length > 1)) ||
                     query.npi === '' ||
                     query.npi === 'none' ||
                     location.search === '' ||
                     loadingToPrint
                  )
               }
               position="bottom left"
               content="Please choose a provider to enable button"
               trigger={
                  <div
                     style={{
                        padding: 0,
                        margin: 0,
                     }}
                  >
                     <BlueDownloadButton
                        disabled={
                           (query.npi !== null &&
                              (query.npi.includes('all') ||
                                 query.npi.split(',').length > 1)) ||
                           query.npi === '' ||
                           query.npi === 'none' ||
                           location.search === '' ||
                           loadingToPrint
                        }
                        onClick={handlePrint}
                        style={{ padding: '11px 13.5px' }}
                     >
                        {loadingToPrint ? (
                           <>
                              <Loader size="mini" active inline />
                           </>
                        ) : (
                           <>
                              <Icon name="print" />
                              &nbsp; Print AWV Chase List
                           </>
                        )}
                     </BlueDownloadButton>
                  </div>
               }
            />
         </Grid.Column>
      </Grid.Row>
   );
};

AwvChaseListActions.propTypes = {
   query: PropTypes.object,
   location: PropTypes.shape({
      hash: PropTypes.string,
      key: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
   }),
   loadingToDownload: PropTypes.bool,
   loadingToPrint: PropTypes.bool,
   memberData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.object),
      endIdx: PropTypes.number,
      numTotal: PropTypes.number,
      pageIdx: PropTypes.number,
      pageMax: PropTypes.number,
      startIdx: PropTypes.number,
   }),
   componentToPrint: PropTypes.object,
   setLoading: PropTypes.func,
   setLoadingToDownload: PropTypes.func,
   setLoadingToPrint: PropTypes.func,
   setMemberData: PropTypes.func,
   addProviderInfoToPrint: PropTypes.object,
   setAddProviderInfoToPrint: PropTypes.func,
};

export default AwvChaseListActions;
