import React, { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { memberListQueryOptionsRefreshState } from './Common/Values';

// Create a React Context that lets us expose and access filter params for member list
// without passing props through many levels of the component tree
const GeneralParamsContext = createContext();

// Create a React Hook that lets us get data from our auth context
export function useGeneralParams() {
   const context = useContext(GeneralParamsContext);
   if (!context) {
      throw new Error(
         `useGeneralParams must be used within a generalParamsProvider`,
      );
   }
   return context;
}

// Create a component that controls auth state and exposes it via
// the React Context we created.
export function GeneralParamsProvider(props) {
   // list filters on the member list
   const [showParam, setShowParam] = useState('all');
   const [sortParam, setSortParam] = useState('measureDateDesc');
   const [listFilter, setListFilter] = useState('all-members');

   // Twilio Call and Modal Parameters
   const [callModalOpen, setCallModalOpen] = useState(false);
   const [ongoingCall, setOngoingCall] = useState(false);
   const [isCallingMember, setIsCallingMember] = useState(false); // as opposed to calling prospect
   const [callTimerData, setCallTimerData] = useState({
      callee: null,
      device: null,
      isCallTimerRunning: false,
      time: 0,
      postCallModalOpen: false,
   }); // holds data for persistent call timer modal

   // Member List 2.0 Parameters
   const [queryOptions, setQueryOptions] = useState(
      memberListQueryOptionsRefreshState,
   );

   // We useMemo to improve performance by eliminating some re-renders
   const value = React.useMemo(
      () => ({
         // list filters on the member list
         showParam,
         sortParam,
         listFilter,
         setShowParam,
         setSortParam,
         setListFilter,

         // Twilio Call and Modal Parameters
         callModalOpen,
         ongoingCall,
         isCallingMember,
         setCallModalOpen,
         setOngoingCall,
         setIsCallingMember,
         callTimerData,
         setCallTimerData,

         // Member List 2.0 Parameters
         queryOptions,
         setQueryOptions,
      }),
      [
         showParam,
         sortParam,
         listFilter,
         callModalOpen,
         ongoingCall,
         isCallingMember,
         callTimerData,
         setCallTimerData,
         queryOptions,
         setQueryOptions,
      ],
   );
   return (
      <GeneralParamsContext.Provider value={value}>
         {props.children}
      </GeneralParamsContext.Provider>
   );
}
GeneralParamsProvider.propTypes = {
   children: PropTypes.element,
};
