import React from 'react';
import { Image } from 'semantic-ui-react';
import { GreyPopupTop } from '../../../Common/StyledComponents/Popups/Popups';
import InfoCircle from '../../../Common/icons/info.svg';

const InfoIconPopup = ({ popupText }) => {
   const popupAppearDelayMs = 200;
   const popupDisappearDelayMs = 600;

   return (
      <GreyPopupTop
         content={popupText}
         position="top center"
         size="tiny"
         mouseEnterDelay={popupAppearDelayMs}
         mouseLeaveDelay={popupDisappearDelayMs}
         trigger={
            <Image
               inline={true}
               src={InfoCircle}
               style={{ paddingBottom: '3px' }}
            />
         }
      />
   );
};

export default InfoIconPopup;
