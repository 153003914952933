import React, { useEffect, useState } from 'react';
import { Grid, Header, Loader } from 'semantic-ui-react';
import AppBar from '../../Common/AppBar/AppBar';
import { useStitchAuth } from '../../StitchAuth';
import { ContactInfoSection } from './ContactInfoSection';
import AboutUserSection from './AboutUserSection';
import { OfficeInfoSection } from './OfficeInfoSection';
import { PracticeManagerInfoSection } from './PracticeManagerInfoSection';
import { UserRegistrationCompletedModal } from './UserRegistrationCompletedModal';
import {
   USER_BASIC_INFORMATION_EVENTS,
   PAGE_NAMES,
} from '../../Common/ActivityData/ActivityDataEvents';
import {
   formatInitialUserProfile,
   validateUserProfile,
   getUserBasicInformationFormErrors,
   formatUserProfileResponse,
} from './userBasicInformationUtils';
import { UserFormValidationModal } from './UserFormValidationModal';

const gridStyle = {
   margin: 0,
   paddingBottom: '5em',
   paddingLeft: '2em',
};

export const UserBasicInformation = () => {
   const {
      userCustomData,
      actions: { handleUserRegistration, handleLogActivityData },
   } = useStitchAuth();
   const [loading, setLoading] = useState(true);
   const userName = userCustomData?.name.split(' ') || '';
   const [userProfile, setUserProfile] = useState(null);
   const [isRegistrationCompleted, setIsRegistrationCompleted] =
      useState(false);
   const [errorMessages, setErrorMessages] = useState([]);
   const [showErrorModal, setShowErrorModal] = useState(false);

   const refreshState = () => {
      setShowErrorModal(false);
      setErrorMessages([]);
   };

   useEffect(() => {
      if (userProfile !== null) {
         setErrorMessages([]);
      }
   }, [userProfile]);

   useEffect(() => {
      return () => {
         refreshState();
      };
   }, []);

   useEffect(() => {
      if (userCustomData) {
         if (!userProfile) {
            // Log event data for first time page is loaded
            handleLogActivityData({
               name: USER_BASIC_INFORMATION_EVENTS.LANDING_PAGE_LOADED,
               pageName: PAGE_NAMES.USER_BASIC_INFORMATION_PAGE,
            });
         }
         setUserProfile(
            formatInitialUserProfile(userName, userCustomData),
         );
         setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userCustomData]);

   const handleValidationError = () => {
      // Log event data when user has form validation error
      console.log('Error for validation');
      handleLogActivityData({
         name: USER_BASIC_INFORMATION_EVENTS.FORM_VALIDATION_ERROR,
         pageName: PAGE_NAMES.USER_BASIC_INFORMATION_PAGE,
      });

      // Get validation errors and show in modal
      const newErrorMsgs =
         getUserBasicInformationFormErrors(userProfile);
      setErrorMessages(newErrorMsgs);
      setShowErrorModal(true);
   };

   const handleUserInformation = async () => {
      setLoading(true);
      const isFormValid = validateUserProfile(userProfile);
      if (!isFormValid) {
         handleValidationError();
      } else {
         // Format fields before sending to backend
         const updatedUserInfo =
            formatUserProfileResponse(userProfile);

         const response =
            await handleUserRegistration(updatedUserInfo);
         if (response?.success) {
            setIsRegistrationCompleted(true);
         } else if (response?.errorMsg && response?.errorMsg?.error) {
            setShowErrorModal(true);
            setErrorMessages([response?.errorMsg?.error]);
         }
      }
      setLoading(false);
   };

   if (loading) {
      return (
         <div className="app-layout">
            <AppBar />
            <Loader active>Loading...</Loader>
         </div>
      );
   } else {
      return (
         <div className="app-layout">
            <AppBar />
            <div
               style={{
                  gridColumn: 3,
                  color: '#333',
                  marginTop: '40px',
               }}
            >
               <Grid style={gridStyle}>
                  <Grid.Row>
                     <Grid.Column>
                        <Grid.Row>
                           <Header>Confirm User Information</Header>
                        </Grid.Row>
                     </Grid.Column>
                  </Grid.Row>
               </Grid>
               <div
                  style={{
                     gridColumn: 3,
                     color: '#333',
                     paddingLeft: 0,
                  }}
               >
                  <Grid
                     style={{
                        margin: 0,
                        marginTop: '-70px',
                        paddingBottom: '5em',
                        paddingLeft: '2em',
                     }}
                  >
                     <Grid.Row
                        style={{ paddingBottom: 0, gap: '56px' }}
                     >
                        <AboutUserSection
                           userProfile={userProfile}
                           setUserProfile={setUserProfile}
                           errorMessages={errorMessages}
                        />
                        <ContactInfoSection
                           userProfile={userProfile}
                           setUserProfile={setUserProfile}
                        />
                     </Grid.Row>
                     <OfficeInfoSection
                        userProfile={userProfile}
                        setUserProfile={setUserProfile}
                     />
                     <PracticeManagerInfoSection
                        userProfile={userProfile}
                        setUserProfile={setUserProfile}
                        handleUserInformation={handleUserInformation}
                     />
                  </Grid>
               </div>
            </div>
            <UserRegistrationCompletedModal
               isRegistrationCompleted={isRegistrationCompleted}
               closeModal={() => setIsRegistrationCompleted(false)}
            />
            <UserFormValidationModal
               isModalOpen={showErrorModal}
               closeModal={() => setShowErrorModal(false)}
               errorMessages={errorMessages}
            />
         </div>
      );
   }
};
