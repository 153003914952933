import React, { useState, useEffect } from 'react';
import {
   Button,
   Form,
   Grid,
   Header,
   Segment,
} from 'semantic-ui-react';
import { useStitchAuth } from './StitchAuth';
import { useLocation, useHistory } from 'react-router-dom';

function useQuery() {
   return new URLSearchParams(useLocation().search);
}

const ConfirmEmail = (props) => {
   const query = useQuery();
   const token = query.get('token');
   const tokenId = query.get('tokenId');
   const history = useHistory();
   const { actions } = useStitchAuth();
   const [confirmed, setConfirmed] = useState(false);

   useEffect(() => {
      actions
         .handleConfirmEmail(token, tokenId)
         .then((e) => {
            console.log(e);
            setConfirmed(true);
         })
         .catch(() => {
            console.log('something went wrong...');
         });
   }, [actions, token, tokenId]);

   const handleSubmit = (event) => {
      history.replace('/login');
   };

   return (
      <Grid
         textAlign="center"
         style={{ height: '100vh' }}
         verticalAlign="middle"
      >
         {confirmed && (
            <Grid.Column style={{ maxWidth: 450 }}>
               <Header as="h2" textAlign="center">
                  <Header.Content>
                     Your email is confirmed!
                  </Header.Content>
               </Header>
               <Form size="large">
                  <Segment
                     style={{ border: '0', boxShadow: '0 0 0 0' }}
                  >
                     <Button
                        style={{
                           backgroundColor: '#335eea',
                           color: '#fff',
                        }}
                        fluid
                        size="large"
                        onClick={handleSubmit}
                     >
                        Sign-in to Your Account
                     </Button>
                  </Segment>
               </Form>
            </Grid.Column>
         )}
      </Grid>
   );
};

export default ConfirmEmail;
