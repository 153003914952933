import React, { useState } from 'react';
import AppBar from '../Common/AppBar/AppBar';
import '../Common/Layout.css';
import { Header, Form, Grid } from 'semantic-ui-react';
import { useStitchAuth } from '../StitchAuth';
import { getDefaultClientCode } from '../Common/whitelabel';
import { useHistory } from 'react-router-dom';
import { handlePhoneNumberFormat } from '../Common/utils';
import { pathnameSelection } from '../Common/arqUtils';

export default function Agreement(props) {
   const { actions, userCustomData } = useStitchAuth();
   const [name, setName] = useState({ firstName: '', lastName: '' });
   const [phoneNumber, setPhoneNumber] = useState('');
   const [address, setAddress] = useState({
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '',
   });
   const [acoInfo, setAcoInfo] = useState({
      acoId: '',
      acoName: '',
   });
   const history = useHistory();

   const handlePhoneNumberChange = (event) => {
      const { value } = event.target;
      let newPhoneNumber;
      const lastCharIsDeleted = value.length < phoneNumber.length;
      const lastCharIsADash =
         value.length > 0 && value[value.length - 1] === '-';

      if (lastCharIsDeleted && lastCharIsADash) {
         newPhoneNumber = value.slice(0, value.length - 1);
      } else if (lastCharIsDeleted && !lastCharIsADash) {
         newPhoneNumber = value;
      } else {
         newPhoneNumber = handlePhoneNumberFormat(value);
      }
      setPhoneNumber(newPhoneNumber);
   };

   const handleAddressChange = (event) => {
      const { name, value } = event.target;
      setAddress({ ...address, [name]: value });
   };

   const handleAcoInformation = (e, { name, value }) => {
      setAcoInfo({ ...acoInfo, [name]: value });
   };

   const handleSubmit = (event) => {
      const fullname =
         name.firstName.replace(/ /g, '') +
         ' ' +
         name.lastName.replace(/ /g, '');
      const fulladdress = (
         address.line1 +
         ' ' +
         address.line2 +
         ', ' +
         address.city +
         ', ' +
         address.state +
         ' ' +
         address.zipCode
      ).replace(/\s\s+/g, ' ');
      const agreement = {
         agreement: {
            contactName: fullname,
            address: fulladdress,
            phoneNumber: phoneNumber,
         },
         clientName: getDefaultClientCode(),
         termsAndConditions: 'agreed', // deprecated
         customer_agreement_consent: true, // deprecated
         hasBasicInformation: true,
         signedContract: false,
         signedDate: new Date().toISOString(),
         acoId: acoInfo.acoId,
         acoName: acoInfo.acoName,
      };
      actions.updateAgreement(agreement).then(() => {
         const navigateToPath = pathnameSelection(
            userCustomData?.currentPersona,
         );
         history.push(navigateToPath);
      });
   };

   return (
      <div className="app-layout">
         <AppBar />
         <div className="messages">
            <Grid
               style={{
                  margin: 0,
                  paddingLeft: '1em',
                  paddingTop: '40px',
               }}
            >
               <Grid.Row textAlign="left" columns={1}>
                  <Grid.Column width={10} verticalAlign="middle">
                     <Header as="h2" style={{ fontWeight: 300 }}>
                        Tell us more about yourself to finalize your
                        account settings.
                        <Header.Subheader></Header.Subheader>
                     </Header>
                     <Form>
                        <Form.Group widths="equal">
                           <Form.Input
                              fluid
                              required
                              name="acoId"
                              label="ACO ID"
                              placeholder="ACO ID"
                              value={acoInfo.acoId}
                              onChange={handleAcoInformation}
                           />
                           <Form.Input
                              fluid
                              required
                              name="acoName"
                              label="ACO Name"
                              placeholder="ACO Name"
                              value={acoInfo.acoName}
                              onChange={handleAcoInformation}
                           />
                        </Form.Group>
                        <Form.Input
                           fluid
                           required
                           name="firstName"
                           label="First Name"
                           placeholder="First Name"
                           value={name.firstName}
                           onChange={(e) =>
                              setName({
                                 ...name,
                                 firstName: e.target.value,
                              })
                           }
                        />
                        <Form.Input
                           fluid
                           required
                           name="lastName"
                           label="Last Name"
                           placeholder="Last Name"
                           value={name.lastName}
                           onChange={(e) =>
                              setName({
                                 ...name,
                                 lastName: e.target.value,
                              })
                           }
                        />
                        <Form.Input
                           fluid
                           name="phoneNumber"
                           required
                           label="Phone Number"
                           placeholder="000-000-0000"
                           value={phoneNumber}
                           onChange={handlePhoneNumberChange}
                        />
                        <Form.Input
                           fluid
                           required
                           name="line1"
                           label="Address 1 (Practice Address)"
                           placeholder="Street Address"
                           value={address.line1}
                           onChange={handleAddressChange}
                        />
                        <Form.Input
                           fluid
                           name="line2"
                           label="Address 2"
                           placeholder="Apartment, Building, Floor, etc."
                           value={address.line2}
                           onChange={handleAddressChange}
                        />
                        <Form.Input
                           fluid
                           required
                           name="city"
                           label="City"
                           placeholder="City"
                           value={address.city}
                           onChange={handleAddressChange}
                        />
                        <Form.Group widths="equal">
                           <Form.Input
                              fluid
                              required
                              name="state"
                              label="State"
                              placeholder="State"
                              value={address.state}
                              onChange={handleAddressChange}
                           />
                           <Form.Input
                              fluid
                              required
                              name="zipCode"
                              label="ZIP/Postal Code"
                              placeholder="00000"
                              value={address.zipCode}
                              onChange={handleAddressChange}
                           />
                        </Form.Group>
                        <Form.Button primary onClick={handleSubmit}>
                           Submit
                        </Form.Button>
                     </Form>
                  </Grid.Column>
               </Grid.Row>
            </Grid>
         </div>
      </div>
   );
}
