import React from 'react';
import { Form, Grid, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ProviderInfoSection } from './ProviderInfoSection';
import { FORM_ERRORS } from './userBasicInformationUtils';
import { Blue } from '../../Common/StyledComponents/Colors';
import { FieldInput } from '../../Common/StyledComponents/Inputs/Inputs';

const FormLabels = styled.label`
   display: inline;
   fontsize: 16px;
   font-weight: 400;
`;

const headerStyle = { color: Blue.BlueDark1 };

const AboutUserSection = ({
   userProfile,
   setUserProfile,
   errorMessages,
}) => {
   const hasFirstNameError = errorMessages.includes(
      FORM_ERRORS.FIRST_NAME_REQUIRED,
   );
   const hasLastNameError = errorMessages.includes(
      FORM_ERRORS.LAST_NAME_REQUIRED,
   );
   return (
      <Grid.Column width={5}>
         <Header as="h4" style={headerStyle}>
            About
         </Header>
         <FormLabels>First Name </FormLabels>
         <Form.Field>
            <FieldInput
               fluid
               name="name"
               placeholder="First Name"
               value={userProfile?.firstName || ''}
               type="text"
               error={hasFirstNameError}
               required
               onChange={(e) =>
                  setUserProfile({
                     ...userProfile,
                     firstName: e.target.value,
                  })
               }
               style={{ marginTop: '8px', marginBottom: '16px' }}
            />
         </Form.Field>
         <FormLabels>Last Name </FormLabels>
         <Form.Field>
            <FieldInput
               fluid
               name="name"
               placeholder="Last Name"
               value={userProfile?.lastName || ''}
               error={hasLastNameError}
               required
               onChange={(e) =>
                  setUserProfile({
                     ...userProfile,
                     lastName: e.target.value,
                  })
               }
               style={{ marginTop: '8px', marginBottom: '16px' }}
            />
         </Form.Field>
         <ProviderInfoSection
            userProfile={userProfile}
            setUserProfile={setUserProfile}
            errorMessages={errorMessages}
         />
      </Grid.Column>
   );
};

AboutUserSection.propTypes = {
   userProfile: PropTypes.shape({
      name: PropTypes.string,
      phoneNumber: PropTypes.string,
      address: PropTypes.object, // TODO: Add more detailed shape for address
      office: PropTypes.object, // TODO: Add more detailed shape for office
      practiceManager: PropTypes.object, // TODO: Add more detailed shape for practiceManager
      tin: PropTypes.string,
      npi: PropTypes.string,
   }),
   setUserProfile: PropTypes.func,
   errorMessages: PropTypes.arrayOf(PropTypes.string),
};

export default AboutUserSection;
