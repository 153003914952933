import React, { useState } from 'react';
import { Grid } from 'semantic-ui-react';
import Papa from 'papaparse';
import '../../Common/Layout.css';
import RegisterBulkUsersInstructions from './RegisterBulkUsersInstructions';
import BulkUserCsvTable from './BulkUserCsvTable';
import BulkUserResultsPage from './BulkUserResultsPage/BulkUserResultsPage';

const gridStyle = {
   margin: 0,
   paddingBottom: '5em',
   paddingLeft: '2em',
};

const RegisterBulkUser = () => {
   const [parsedData, setParsedData] = useState({});
   const [isResponseReceived, setIsResponseReceived] =
      useState(false);
   const [registrationResponse, setRegistrationResponse] = useState(
      {},
   );
   const [isFileUploaded, setIsFileUploaded] = useState(false);

   const refreshState = () => {
      setParsedData({});
      setIsResponseReceived(false);
      setRegistrationResponse({});
      setIsFileUploaded(false);
   };

   const handleFileUpload = (event) => {
      Papa.parse(event.target.files[0], {
         header: true,
         skipEmptyLines: true,
         complete: function (parsedRegistrationData) {
            const csvData = { headers: [], values: [] };

            parsedRegistrationData.data.forEach((userData, index) => {
               // Store the header column names once
               if (index === 0) {
                  Object.keys(userData).forEach((header) =>
                     csvData.headers.push(header),
                  );
               }
               csvData.values.push(Object.values(userData));
            });
            setParsedData(csvData);
            setIsFileUploaded(true);
         },
      });
   };

   return (
      <div>
         <Grid style={gridStyle}>
            <Grid.Row style={{ marginTop: '-70px' }}>
               <Grid.Column>
                  {isResponseReceived === false ? (
                     isFileUploaded === false ? (
                        <RegisterBulkUsersInstructions
                           handleFileUpload={handleFileUpload}
                        />
                     ) : (
                        <BulkUserCsvTable
                           userData={parsedData}
                           setUserData={setParsedData}
                           setIsFileUploaded={setIsFileUploaded}
                           setIsResponseReceived={
                              setIsResponseReceived
                           }
                           setRegistrationResponse={
                              setRegistrationResponse
                           }
                        />
                     )
                  ) : (
                     <BulkUserResultsPage
                        registrationResponse={registrationResponse}
                        refreshState={refreshState}
                     />
                  )}
               </Grid.Column>
            </Grid.Row>
         </Grid>
      </div>
   );
};

export default RegisterBulkUser;
