import React, {
   useCallback,
   useEffect,
   useMemo,
   useState,
} from 'react';
import lodashDebounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import { Search } from 'semantic-ui-react';
import { useStitchAuth } from '../../../StitchAuth';
import { getNewSearchParam } from '../../arqUtils';
import { PERSONA } from '../../Constants/UserConstants';
import { TextColors } from '../../StyledComponents/Colors';

const fontStyle = {
   fontSize: '11px',
   fontFamily: 'Lato',
   fontStyle: 'normal',
   padding: 0,
   margin: 0,
};

// prvdr Member list basic queries
const query = {
   view: null,
   npi: 'all',
   tin: null,
   cm: null,
   cc: null,
   sort: null,
   gic: null,
   page: null,
   sortParam: null,
   memberId: null,
   tableColumns: null,
   messageType: null,
};

const SearchResult = ({ title, memberid, description }) => (
   <Link
      to={{
         pathname: '/prvdrview-awv-chase-list',
         search: getNewSearchParam(query, 'memberId', memberid),
      }}
   >
      <div
         style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: 0,
            margin: 0,
            color: TextColors.BlackText,
         }}
      >
         <p
            style={{
               fontWeight: 700,
               ...fontStyle,
            }}
         >
            {title}
         </p>
         <p
            style={{
               fontWeight: 400,
               ...fontStyle,
            }}
         >
            {memberid}
         </p>
      </div>
      <div
         style={{
            padding: 0,
            margin: 0,
            color: TextColors.GreyText,
         }}
      >
         {description}
      </div>
   </Link>
);

const SearchBar = ({ userCustomData, isLoading, setIsLoading }) => {
   const {
      actions: { handleMemberSearchProvider },
   } = useStitchAuth();
   const [searchResults, setSearchResults] = useState([]);
   const [searchTerm, setSearchTerm] = useState('');
   const [isSearchBarDisabled, setIsSearchBarDisabled] =
      useState(true);
   const debounceSearchDelayMs = 300;

   useEffect(() => {
      if (userCustomData && userCustomData.currentPersona) {
         const searchEnabledPersonas = [PERSONA.PROVIDER];
         const disable = !searchEnabledPersonas.includes(
            userCustomData.currentPersona,
         );
         setIsSearchBarDisabled(disable);
      }
   }, [userCustomData]);

   /** handleSearchChange() is called whenever input
    * to the search bar is changed. It will call debouncedSearch()
    * which will wait 300ms after the the last input change
    * to run performSearch().
    * It will then return an array of search results to display.
    */
   const performSearch = useCallback(
      (searchStr) => {
         setIsLoading(true);

         if (searchStr && searchStr !== '') {
            handleMemberSearchProvider(searchStr).then((res) => {
               setSearchResults(res.searchResults);
               setIsLoading(false);
            });
         } else {
            setIsLoading(false);
         }
      },
      [setIsLoading, setSearchResults, handleMemberSearchProvider],
   );

   const debouncedSearch = useMemo(
      () =>
         lodashDebounce((searchStr) => {
            performSearch(searchStr);
         }, debounceSearchDelayMs),
      [performSearch],
   );

   const handleSearchChange = useCallback(
      (e, { value }) => {
         setSearchTerm(value);
         debouncedSearch(value);
      },
      [debouncedSearch],
   );

   return (
      <div style={{ marginLeft: '28px' }}>
         <Search
            size="mini"
            className="search-box"
            placeholder="Search a member by name or ID"
            input={{ icon: 'search', iconPosition: 'left' }}
            disabled={isSearchBarDisabled}
            loading={isLoading}
            onSearchChange={handleSearchChange}
            resultRenderer={SearchResult}
            /** searchResults as formatted causes a console
             * warning since Semantic UI React <Search />
             * expects the results prop to be formatted
             * in a way that would not allow the feature
             * to fully work as designed by our team. */
            results={searchResults}
            value={searchTerm}
            noResultsMessage={
               isLoading ? 'Searching...' : 'No results found.'
            }
         />
      </div>
   );
};

export default SearchBar;
