import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import MainSideBar from './MainSideBar/MainSideBar';
import { TextColors } from '../../StyledComponents/Colors';
import { PERSONA } from '../../Constants/UserConstants';

const LogoItem = styled.div`
   padding: 0 0 0.5em 1.1em;
   margin-bottom: 0px;
   cursor: pointer;
   display: flex;
   flex-direction: row;
   gap: 25px;
   align-items: center;
   width: 100%;
   height: 40px;
   color: ${TextColors.SemanticWhite};
`;

export const SideNavigationBar = ({ Logo, userCustomData }) => {
   const history = useHistory();
   let memberPath = '/members';
   const providerList = [
      PERSONA.PROVIDER,
      PERSONA.PVD2,
      PERSONA.EXECUTIVE,
      PERSONA.PR_REPS,
   ];

   if (providerList.includes(userCustomData?.currentPersona)) {
      memberPath = '/prvdrview-awv-chase-list';
   }

   const MenuBar = (
      <>
         <LogoItem
            onClick={() => {
               history.push({
                  pathname: memberPath,
               });
            }}
         >
            <Logo />
         </LogoItem>
      </>
   );

   const renderSideBarMenu = () => {
      return <MainSideBar userCustomData={userCustomData} />;
   };

   return (
      <div
         data-testid="side-navigation-menu"
         id="menu"
         className="channels"
      >
         <div>
            {MenuBar}
            {renderSideBarMenu()}
         </div>
      </div>
   );
};
