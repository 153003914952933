import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Table, Header } from 'semantic-ui-react';
import moment from 'moment';
import AwvDueDateCell from './AwvDueDateCell';
import PrevAwvRecordsCell from './PrevAwvRecordsCell';
import PafDownloadCell from './PafDownloadCell';
import {
   getNewSearchParam,
   useMembersListQuery,
} from '../../../Common/arqUtils';
import { AwvChaseListColumns } from '../../../Common/Values';
import { COLUMN } from '../../../Common/Constants/MemberListConstants';
import { DATE_TIME_FORMATS } from '../../../Common/DateTimeUtils';
import {
   Blue,
   TextColors,
} from '../../../Common/StyledComponents/Colors';

const TableRow = ({ member, basePath, loadingToPrint }) => {
   const query = useMembersListQuery();
   const rafDecimalRounder = 1000;

   const awvColumnList = useMemo(() => {
      if (loadingToPrint) {
         // Hide columns from print view
         return AwvChaseListColumns.filter(
            (col) => col.key !== COLUMN.PAF,
         ).filter((col) => col.key !== COLUMN.PREV_AWV_RECORDS);
      } else {
         return AwvChaseListColumns;
      }
   }, [loadingToPrint]);

   const getTableCellValueComponent = (colname) => {
      if (colname === COLUMN.AWV_COMPLETED) {
         return member.awv_is_due === 'Y' ? 'N' : 'Y';
      } else if (colname === COLUMN.AWV_DUE) {
         return <AwvDueDateCell timestamp={member.awvDueTs} />;
      } else if (colname === COLUMN.G_CODE) {
         return member.awv_is_due === 'Y' && <>{member.awv_code}</>;
      } else if (colname === COLUMN.PREV_AWV_RECORDS) {
         return <PrevAwvRecordsCell member={member} />;
      } else if (colname === COLUMN.HCC_CNT_OPEN) {
         return (
            <>
               {member.hcc_recapture_cnt > 0 ? (
                  <span style={{ color: TextColors.ErrorText }}>
                     <b>{member.hcc_recapture_cnt}</b>
                  </span>
               ) : (
                  <span>0</span>
               )}
            </>
         );
      } else if (colname === COLUMN.RAF_MY) {
         return (
            <>
               {Math.round(member.raf_my * rafDecimalRounder) /
                  rafDecimalRounder}
            </>
         );
      } else if (colname === COLUMN.RAF_PY) {
         return (
            <>
               {Math.round(member.raf_py * rafDecimalRounder) /
                  rafDecimalRounder}
            </>
         );
      } else if (colname === COLUMN.PAF) {
         return <PafDownloadCell member={member} />;
      }
   };

   return (
      <Table.Row
         style={{
            pageBreakInside: loadingToPrint ? 'avoid' : 'auto',
         }}
      >
         <Table.Cell>
            <Link
               to={{
                  pathname: basePath,
                  search: getNewSearchParam(
                     query,
                     'memberId',
                     member.mbi,
                  ),
               }}
            >
               <Header as="h5">
                  <div>
                     <div
                        style={{
                           color: Blue.BlueMedium2,
                        }}
                     >
                        {member.name}
                     </div>
                     <strong style={{ fontSize: '0.8em' }}>
                        {member.gender},&nbsp;
                        {moment(member.dob).format(
                           DATE_TIME_FORMATS.MINIMAL_DATE,
                        )}
                     </strong>
                  </div>
               </Header>
            </Link>
         </Table.Cell>
         <Table.Cell>{member.mbi}</Table.Cell>
         {awvColumnList.map((colname) => (
            <Table.Cell
               key={member._id + colname.key}
               textAlign="right"
            >
               {getTableCellValueComponent(colname.key)}
            </Table.Cell>
         ))}
      </Table.Row>
   );
};

export default TableRow;
