import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import {
   ButtonColors,
   TextColors,
   Blue,
   IconColors,
} from '../Colors.js';

export const ConfirmButton = styled(Button)`
   background-color: ${ButtonColors.GreenSuccess} !important;
   color: ${TextColors.SemanticWhite} !important;
   font-weight: 400 !important;
   &:hover {
      background-color: ${ButtonColors.GreenSuccessHover} !important;
   }
   &:active {
      background-color: ${ButtonColors.GreenSuccessActive} !important;
   }
   &:disabled {
      background-color: ${ButtonColors.GreenSuccessDisabled} !important;
   }
`;

export const ErrorButton = styled(Button)`
   background-color: ${ButtonColors.RedError} !important;
   color: ${TextColors.SemanticWhite} !important;
   font-weight: 400 !important;
   &:hover {
      background-color: ${ButtonColors.RedErrorHover} !important;
   }
   &:active {
      background-color: ${ButtonColors.RedErrorActive} !important;
   }
   &:disabled {
      background-color: ${ButtonColors.RedErrorDisabled} !important;
   }
`;

export const BlueDownloadButton = styled(Button)`
   background-color: ${ButtonColors.BlueDownload} !important;
   color: ${TextColors.SemanticWhite} !important;
   font-weight: 400 !important;
   &:hover {
      background-color: ${ButtonColors.BlueDownloadHover} !important;
   }
   &:active {
      background-color: ${ButtonColors.BlueDownloadActive} !important;
   }
   &:disabled {
      background-color: ${ButtonColors.BlueDownloadDisabled} !important;
   }
`;

export const GreyDownloadButton = styled(Button)`
   background-color: ${ButtonColors.GreyDownload} !important;
   color: ${TextColors.SemanticText} !important;
   font-weight: 400 !important;
   &:hover {
      background-color: ${ButtonColors.GreyDownloadHover} !important;
   }
   &:active {
      background-color: ${ButtonColors.GreyDownloadActive} !important;
   }
   &:disabled {
      background-color: ${ButtonColors.GreyDownloadDisabled} !important;
   }
`;

export const GreyAddButton = styled(Button)`
   background-color: ${ButtonColors.GreyDownload} !important;
   color: ${TextColors.SemanticText} !important;
   font-weight: 400 !important;
   padding: 8px 12px !important;
   margin: 0 !important;
   cursor: pointer !important;
   &:hover {
      background-color: ${ButtonColors.GreyDownloadHover} !important;
   }
   &:active {
      background-color: ${ButtonColors.GreyDownloadActive} !important;
   }
   &:disabled {
      background-color: ${ButtonColors.GreyDownloadDisabled} !important;
   }
`;

export const ToggleOffButton = styled(Button)`
   background-color: ${ButtonColors.GreyDownloadDisabled} !important;
   color: ${TextColors.GreyText} !important;
   font-weight: 400 !important;
   &:hover {
      background-color: ${ButtonColors.GreyDownloadHover} !important;
   }
`;

export const ToggleOnButton = styled(Button)`
   background-color: ${Blue.BlueLight1} !important;
   color: ${Blue.BlueMedium2} !important;
   font-weight: 400 !important;
   &:hover {
      background-color: ${Blue.BlueMedium1} !important;
   }
`;

export const LightCyanButton = styled(Button)`
   background-color: ${ButtonColors.LightCyan} !important;
   color: ${TextColors.GreenDropdownText} !important;
   font-weight: 400 !important;
   border-radius: 4px;
   border: 1px solid ${TextColors.GreenDropdownText} !important;
   &:hover {
      background-color: ${ButtonColors.GreenSuccessDisabled} !important;
   }
   &:active {
      background-color: ${ButtonColors.GreenSuccessDisabled} !important;
   }
   &:disabled {
      background-color: ${ButtonColors.LightCyan} !important;
   }
`;

export const MidnightBlueButton = styled(Button)`
   background-color: ${ButtonColors.MidnightBlueButton} !important;
   color: ${TextColors.PurpleOutcomeText} !important;
   font-weight: 400 !important;
   border-radius: 4px;
   border: 1px solid ${TextColors.PurpleOutcomeText} !important;
   &:hover {
      background-color: ${ButtonColors.MidnightBlueButton} !important;
   }
   &:active {
      background-color: ${IconColors.Purple} !important;
   }
   &:disabled {
      background-color: ${ButtonColors.MidnightBlueButton} !important;
   }
`;
