import React, { useState, useEffect } from 'react';
import { Grid, Table, Icon } from 'semantic-ui-react';
import { useStitchAuth } from '../../StitchAuth';
import {
   ConfirmButton,
   ErrorButton,
} from '../../Common/StyledComponents/Buttons/Buttons';
import { CsvPreviewContainer } from '../../Common/StyledComponents/Containers/Containers';

const BulkUserCsvTable = ({
   userData,
   setUserData,
   setIsFileUploaded,
   setIsResponseReceived,
   setRegistrationResponse,
}) => {
   const {
      userCustomData,
      actions: { handleBulkSignup },
   } = useStitchAuth();
   const [processing, setProcessing] = useState(false);

   const handleCancelUpload = () => {
      setUserData({});
      setIsFileUploaded(false);
   };

   const handleUsersRegistration = async () => {
      setProcessing(true);
      const res = await handleBulkSignup(
         userData.values,
         userCustomData,
      );

      if (res.success) {
         setIsResponseReceived(true);
         setRegistrationResponse(res);
         setProcessing(false);
      }
   };

   useEffect(() => {
      return () => {
         setUserData({});
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <>
         <Grid.Row>
            <CsvPreviewContainer>
               <Table basic="very" striped>
                  <Table.Header>
                     <Table.Row>
                        {userData.headers?.map(
                           (columnName, index) => {
                              return (
                                 <Table.HeaderCell key={index}>
                                    {columnName}
                                 </Table.HeaderCell>
                              );
                           },
                        )}
                     </Table.Row>
                  </Table.Header>
                  <Table.Body>
                     {userData.values?.map((user, index) => {
                        return (
                           <Table.Row key={index}>
                              {user.map((userData, i) => {
                                 return (
                                    <Table.Cell key={i}>
                                       {userData}
                                    </Table.Cell>
                                 );
                              })}
                           </Table.Row>
                        );
                     })}
                  </Table.Body>
               </Table>
            </CsvPreviewContainer>
         </Grid.Row>
         <Grid.Row style={{ marginTop: '24px' }}>
            <ConfirmButton
               style={{
                  marginLeft: '21px',
                  float: 'right',
               }}
               loading={processing}
               onClick={handleUsersRegistration}
            >
               <Icon name="check" />
               Yes, this is correct
            </ConfirmButton>
            <ErrorButton
               style={{
                  float: 'right',
               }}
               onClick={handleCancelUpload}
            >
               <Icon name="close" />
               Wrong file / needs changes
            </ErrorButton>
         </Grid.Row>
      </>
   );
};

export default BulkUserCsvTable;
