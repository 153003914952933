export const TextColors = {
   SemanticWhite: '#FFFFFF',
   SemanticWhiteGreyShade: '#F5F5F5',
   SemanticText: '#091930',
   ErrorText: '#D80505',
   CorrectText: '#107927',
   GreenDropdownText: '#1A6659',
   GreyText: '#7B7B7B',
   BrightErrorText: '#FF0000',
   BlueDarkText: '#1D4C8F',
   BlackText: '#000000',
   WarningOrange: '#EC991D',
   GreenSuccess: '#18B03A',
   WarningDark: '#8B5400',
   Grey5Text: '#0d0d0d',
   PurpleOutcomeText: '#211275',
   DarkCharcoal: '#333333',
   PeriwinklePurpleText: '#BDC9E8',
};

// These colors match the default error state for semantic-ui errors
// We do not have these states in our style-guide, so for now these are
// defined here to recreate for semantic-ui error states
// This matches `.ui.message.error input` styling from semantic css
export const SemanticErrorColors = {
   backgroundColor: '#FFF6F6',
   borderColor: '#E0B4B4',
   color: '#9F3A38',
};

export const ButtonColors = {
   GreenSuccess: '#18B03A',
   GreenSuccessHover: '#189634',
   GreenSuccessActive: '#107927',
   GreenSuccessDisabled: '#8CD89D',
   RedError: '#D80505',
   RedErrorHover: '#A60B0B',
   RedErrorActive: '#840404',
   RedErrorDisabled: '#EC8282',
   BlueDownload: '#1D4C8F',
   BlueDownloadHover: '#173A6B',
   BlueDownloadActive: '#112A4E',
   BlueDownloadDisabled: '#8EA6C7',
   GreyDownload: '#EAEAEA',
   GreyDownloadHover: '#D4D4D4',
   GreyDownloadActive: '#BCBCBC',
   GreyDownloadDisabled: '#F5F5F5',
   LightCyan: '#CFFCF4',
   MidnightBlueButton: '#E3DEFF',
};

export const MessageColors = {
   GreenSuccess: '#E7FEEC',
   RedError: '#FFE1E1',
   WarningOrange: '#FFE6C1',
};

export const Blue = {
   BlueLight1: '#EAF4FF',
   BlueLight2: '#C4DBF2',
   BlueMedium1: '#88BEFF',
   BlueMedium2: '#457AB9',
   BlueDark1: '#1D4C8F',
   BlueDark2: '#173A6B',
   BlueDark3: '#112A4E',
};

export const Sketch = {
   Subtitle: '#8F8F8F',
};

export const InputColors = {
   GreyDisabledBackground: '#F5F5F5',
   GreyBorder: '#EAEAEA',
   GreyText: '#7B7B7B',
   SemanticStripedGrey: '#FAFAFB',
};

export const LabelColors = {
   ErrorDark1: '#D80505',
   SemanticWhite: '#FFFFFF',
   WarningOrange: '#EC991D',
   GreenSuccess: '#18B03A',
   WarningLight: '#FFE6C1',
};

export const IconColors = {
   Purple: '#B8ACFF',
   // Semantic-ui can only be from the following set
   BlackName: 'black',
   RedName: 'red',
   WhiteName: 'white',
   OrangeName: 'orange',
   OliveName: 'olive',
   GreenName: 'green',
   TealName: 'teal',
   BlueName: 'blue',
   VioletName: 'violet',
   PurpleName: 'purple',
   GreyName: 'grey',
   BrownName: 'brown',
   YellowName: 'yellow',
};
