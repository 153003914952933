import React from 'react';
import { Icon } from 'semantic-ui-react';
import { useStitchAuth } from '../../StitchAuth';
import { formatCapitalString } from '../arqUtils';
import { PersonaDropdownMenu } from '../StyledComponents/Dropdowns/Dropdowns.js';
import {
   IconColors,
   TextColors,
} from '../StyledComponents/Colors.js';

// TODO: Determine if this feature is necessary for production
// users will not be able to change personas, hence the indicator
// text may be redundant.

const PersonaDropdown = () => {
   const { userCustomData } = useStitchAuth();

   if (userCustomData !== null) {
      return (
         <PersonaDropdownMenu
            icon={false}
            trigger={
               <>
                  <Icon.Group>
                     <Icon
                        name="user outline"
                        style={{ color: IconColors.BlackName }}
                     />
                  </Icon.Group>
                  <div
                     style={{
                        userSelect: 'none',
                        display: 'inline',
                        height: '30px',
                        color: TextColors.SemanticText,
                     }}
                  >
                     {formatCapitalString(
                        userCustomData.currentPersona,
                     )}
                  </div>
               </>
            }
         />
      );
   } else {
      return null;
   }
};

export default PersonaDropdown;
