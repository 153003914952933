import React from 'react';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { formatNumbersOnly } from '../../Common/arqUtils';
import { states } from '../../Common/Values';

const FormLabels = styled.label`
   display: inline;
   fontsize: 16px;
   font-weight: 400;
`;

const MessageLabel = styled.div`
   background-color: #eaf4ff;
   border: 1px solid #c4dbf2;
   border-radius: 4px;
   padding: 12px 8px;
   color: #1d4c8f;
   margin-bottom: 18px;
   margin-top: 18px;
   font-family: 'Lato';
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 18px;
`;

const inputLabelStyle = { marginTop: '8px', marginBottom: '16px' };

export const AddressInfoSection = ({
   userProfile,
   setUserProfile,
}) => {
   return (
      <>
         <MessageLabel>
            Enter your preferred mailing address for APA ACO program
            materials.
         </MessageLabel>
         <FormLabels>Mailing Address 1</FormLabels>
         <Form.Input
            style={inputLabelStyle}
            name="line1"
            required
            fluid
            placeholder="Address 1"
            value={userProfile?.address.line1 || ''}
            onChange={(e) =>
               setUserProfile({
                  ...userProfile,
                  address: {
                     ...userProfile.address,
                     line1: e.target.value,
                  },
               })
            }
         />
         <FormLabels>Mailing Address 2</FormLabels>
         <Form.Input
            style={inputLabelStyle}
            name="line2"
            required
            fluid
            placeholder="Address 2"
            value={userProfile?.address.line2 || ''}
            onChange={(e) =>
               setUserProfile({
                  ...userProfile,
                  address: {
                     ...userProfile.address,
                     line2: e.target.value,
                  },
               })
            }
         />
         <FormLabels>City</FormLabels>
         <Form.Input
            style={inputLabelStyle}
            name="city"
            required
            fluid
            placeholder="City"
            value={userProfile?.address.city || ''}
            onChange={(e) =>
               setUserProfile({
                  ...userProfile,
                  address: {
                     ...userProfile.address,
                     city: e.target.value,
                  },
               })
            }
         />
         <div
            style={{
               display: 'flex',
               flexDirection: 'row',
               justifyContent: 'space-between',
            }}
         >
            <div style={{ width: '166px' }}>
               <FormLabels>State</FormLabels>
               <Form.Dropdown
                  style={inputLabelStyle}
                  name="state"
                  selection
                  fluid
                  options={states}
                  placeholder="State"
                  value={userProfile?.address.state || ''}
                  onChange={(e, { value }) =>
                     setUserProfile({
                        ...userProfile,
                        address: {
                           ...userProfile.address,
                           state: value,
                        },
                     })
                  }
               />
            </div>
            <div style={{ width: '166px' }}>
               <FormLabels>ZIP Code</FormLabels>
               <Form.Input
                  style={inputLabelStyle}
                  name="zipCode"
                  required
                  fluid
                  maxLength="5"
                  placeholder="ZIP (5 digit restricted)"
                  value={userProfile?.address.zipCode || ''}
                  onChange={(e) =>
                     setUserProfile({
                        ...userProfile,
                        address: {
                           ...userProfile.address,
                           zipCode: formatNumbersOnly(e.target.value),
                        },
                     })
                  }
               />
            </div>
         </div>
      </>
   );
};
