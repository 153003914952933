import React from 'react';
import { Grid } from 'semantic-ui-react';

const RegisterBulkUsersInstructions = ({ handleFileUpload }) => {
   return (
      <>
         <Grid.Row style={{ marginTop: '-5px' }}>
            To enroll multiple users, please upload a CSV of user
            account information.
         </Grid.Row>
         <Grid.Row style={{ marginTop: '12px' }}>
            <input
               type="file"
               name="file"
               accept=".csv"
               onChange={handleFileUpload}
               style={{ marginTop: '12px' }}
            />
         </Grid.Row>
      </>
   );
};

export default RegisterBulkUsersInstructions;
