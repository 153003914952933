import { Form, Grid, Header } from 'semantic-ui-react';
import { validateEmail } from '../../Common/utils';
import styled from 'styled-components';

const FormLabels = styled.p`
   display: inline;
   fontsize: 16px;
`;

const RequiredFields = styled.p`
   display: inline;
   color: red;
`;

const ErrorMessageLabel = styled.p`
   marginbottom: 8px;
   color: red;
`;

const RegisterLoginForm = ({
   newUser,
   setNewUser,
   handleAddAccount,
   processing,
   newUserPasswordError,
   setNewUserPasswordError,
   newUserEmailError,
   setNewUserEmailError,
}) => {
   return (
      <Grid.Row>
         <Grid.Column width={5} style={{ marginTop: '48px' }}>
            <Header as="h4">Login Information</Header>
            <div style={{ marginBottom: '16px' }}>
               <label>
                  <FormLabels>New User Email </FormLabels>
                  <RequiredFields>(Required)</RequiredFields>
               </label>
               <Form.Input
                  style={{ marginTop: '8px', marginBottom: '8px' }}
                  type="text"
                  required
                  fluid
                  placeholder="New User Email"
                  value={newUser.email}
                  onChange={(e) => {
                     setNewUser({
                        ...newUser,
                        email: e.target.value
                           .toLowerCase()
                           .replace(/\s/g, ''),
                     });
                     if (!validateEmail(e.target.value)) {
                        setNewUserEmailError(true);
                     } else {
                        setNewUserEmailError(false);
                     }
                  }}
               />
               {newUserEmailError ? (
                  <ErrorMessageLabel>
                     Please fill out email
                  </ErrorMessageLabel>
               ) : null}
            </div>
         </Grid.Column>
         <Grid.Column width={5} style={{ marginTop: '80px' }}>
            <div style={{ marginBottom: '16px' }}>
               <label>
                  <FormLabels>New User Password </FormLabels>
                  <RequiredFields>(Required)</RequiredFields>
               </label>
               <Form.Input
                  style={{ marginTop: '8px', marginBottom: '8px' }}
                  type="password"
                  required
                  fluid
                  placeholder="New User Password"
                  autoComplete="new-password"
                  value={newUser.password}
                  onChange={(e) => {
                     setNewUser({
                        ...newUser,
                        password: e.target.value,
                     });
                     if (e.target.value.length < 8) {
                        setNewUserPasswordError(true);
                     } else {
                        setNewUserPasswordError(false);
                     }
                  }}
               />
               {newUserPasswordError ? (
                  <ErrorMessageLabel>
                     Password must be at least 8 characters
                  </ErrorMessageLabel>
               ) : null}
            </div>

            <Form.Button
               primary
               onClick={handleAddAccount}
               loading={processing}
               style={{ float: 'right' }}
            >
               Add New Account
            </Form.Button>
         </Grid.Column>
      </Grid.Row>
   );
};

export default RegisterLoginForm;
