import { PERSONA } from '../../Common/Constants/UserConstants';
import { formatPhoneNumberE164 } from '../../Common/FormatUtils';
import { toDomesticPhoneFormat } from '../../Common/utils';

export const FORM_ERRORS = Object.freeze({
   FIRST_NAME_REQUIRED: 'First name is required',
   LAST_NAME_REQUIRED: 'Last name is required',
   EMAIL_REQUIRED: 'Email is required',
   TIN_REQUIRED: 'TIN is required',
   TIN_LENGTH: 'TIN must be 9 digits long - no letters or dashes',
   NPI_REQUIRED: 'NPI is required',
   NPI_LENGTH: 'NPI must be 10 digits long',
});

export const formatInitialUserProfile = (
   userName,
   userCustomData,
) => {
   return {
      firstName: userName[0],
      lastName: userName[1],
      email: userCustomData.email,
      npi: userCustomData.npi,
      phoneNumber: toDomesticPhoneFormat(userCustomData.phoneNumber),
      address: userCustomData.address || {},
      tin: userCustomData.tin || '',
      personas: userCustomData.personas || [],
      office: {
         phoneOffice: '',
         email: '',
      },
      practiceManager: {
         firstName: '',
         lastName: '',
         phoneManager: '',
         email: '',
      },
   };
};

export const formatUserProfileResponse = (userProfile) => {
   // Format Phone numbers. Phone numbers should be E.164 format
   const phoneNumber = formatPhoneNumberE164(
      userProfile?.phoneNumber,
   );
   const phoneOffice = formatPhoneNumberE164(
      userProfile?.office?.phoneOffice,
   );
   const phoneManager = formatPhoneNumberE164(
      userProfile?.practiceManager?.phoneManager,
   );

   // If user is provider, include NPI and TIN
   const providerUserInfo =
      userProfile?.currentPersona !== PERSONA.PROVIDER
         ? null
         : {
              npi: userProfile?.npi,
              tin: userProfile?.tin,
           };

   const updatedUserInfo = {
      name: `${userProfile.firstName} ${userProfile.lastName}`,
      phoneNumber: phoneNumber,
      address: userProfile.address,
      office: {
         ...userProfile?.office,
         phoneOffice: phoneOffice,
      },
      practiceManager: {
         name: `${userProfile.practiceManager.firstName} ${userProfile.practiceManager.lastName}`,
         phoneManager: phoneManager,
         email: userProfile?.practiceManager?.email,
      },
      ...providerUserInfo,
   };

   return updatedUserInfo;
};

/**
 *
 * Validates whether the form in UserBasicInformation
 * is valid by checking required fields and their format
 *
 * @returns {boolean} true if form is valid
 */
export const validateUserProfile = (userProfile) => {
   if (
      userProfile?.firstName === '' ||
      userProfile?.lastName === '' ||
      userProfile?.email === ''
   ) {
      return false;
   }
   // check NPI & TIN is filled out if persona registered is Provider
   if (userProfile?.personas?.includes(PERSONA.PROVIDER)) {
      if (
         userProfile?.npi?.length !== 10 ||
         userProfile?.tin?.length !== 9
      ) {
         return false;
      }
   }
   return true;
};

export const getUserBasicInformationFormErrors = (userProfile) => {
   const errorMsgs = [];
   // Validate User fields
   if (userProfile?.firstName === '') {
      errorMsgs.push(FORM_ERRORS.FIRST_NAME_REQUIRED);
   }
   if (userProfile?.lastName === '') {
      errorMsgs.push(FORM_ERRORS.LAST_NAME_REQUIRED);
   }
   if (userProfile?.email === '') {
      errorMsgs.push(FORM_ERRORS.EMAIL_REQUIRED);
   }
   // Validate provider only fields
   if (userProfile?.personas?.includes(PERSONA.PROVIDER)) {
      if (userProfile?.npi === '') {
         errorMsgs.push(FORM_ERRORS.NPI_REQUIRED);
      }
      if (userProfile?.npi?.length !== 10) {
         errorMsgs.push(FORM_ERRORS.NPI_LENGTH);
      }
      if (userProfile?.tin?.length !== 9) {
         errorMsgs.push(FORM_ERRORS.TIN_LENGTH);
      }
   }
   return errorMsgs;
};
