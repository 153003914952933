import React, { useState } from 'react';
import {
   Grid,
   Label,
   Header,
   Popup,
   Table,
   List,
   Icon,
   Message,
} from 'semantic-ui-react';
import 'moment-timezone';
import { save } from 'save-file';
import { useStitchAuth } from '../StitchAuth';
import { prvdrTodoIntelligence } from '../Common/arqUtils';
import { GreyDownloadButton } from '../Common/StyledComponents/Buttons/Buttons';
import {
   MEMBER_DETAIL_EVENTS,
   PAGE_NAMES,
} from '../Common/ActivityData/ActivityDataEvents';

const MemberProfile = ({ memberDetails }) => {
   const [loading, setLoading] = useState(false);
   const {
      actions: { handleFetchPafSingleMember, handleLogActivityData },
   } = useStitchAuth();

   const downloadPaf = () => {
      setLoading(true);
      // Log event data for download PAF clicked
      handleLogActivityData({
         name: MEMBER_DETAIL_EVENTS.DOWNLOAD_PAF_CLICKED,
         pageName: PAGE_NAMES.MEMBER_DETAIL_PAGE,
      });
      handleFetchPafSingleMember(memberDetails.mbi).then((res) => {
         if (res.success && res.data) {
            // Log event data for download PAF success
            handleLogActivityData({
               name: MEMBER_DETAIL_EVENTS.DOWNLOAD_PAF_SUCCESS,
               pageName: PAGE_NAMES.MEMBER_DETAIL_PAGE,
            });
            save(res.data.pdfBase64, `${res.data.fn}.pdf`);
         } else {
            console.error('no PAF available');

            // Log event data for download PAF failure
            handleLogActivityData({
               name: MEMBER_DETAIL_EVENTS.DOWNLOAD_PAF_FAILURE,
               pageName: PAGE_NAMES.MEMBER_DETAIL_PAGE,
            });
         }
         setLoading(false);
      });
   };

   const determineColor = (status) => {
      let color = 'purple';
      if (status === 'closed') {
         color = 'green';
      } else if (status === 'recapture') {
         color = 'red';
      }
      return color;
   };

   return (
      <>
         <Grid.Row columns={1}>
            <Grid.Column>
               {prvdrTodoIntelligence(memberDetails).length > 0 && (
                  <Message color="blue">
                     <List>
                        {prvdrTodoIntelligence(memberDetails).map(
                           (message) => (
                              <List.Item key={message}>
                                 <List.Icon name="lightbulb" />
                                 <List.Content>
                                    {message}
                                 </List.Content>
                              </List.Item>
                           ),
                        )}
                     </List>
                  </Message>
               )}
            </Grid.Column>
         </Grid.Row>
         <Grid.Row columns={4}>
            <Grid.Column>
               <Header as="h4">
                  {memberDetails.name}
                  <br />
                  (MBI: {memberDetails.mbi})
                  <Header.Subheader>Member Info.</Header.Subheader>
               </Header>
            </Grid.Column>
            <Grid.Column>
               <Header as="h4">
                  {memberDetails.dob.substring(0, 10)}
                  <br />
                  {memberDetails.mdcrStatus}
                  <Header.Subheader>
                     DoB and Eligibility
                  </Header.Subheader>
               </Header>
            </Grid.Column>
            <Grid.Column>
               <Header as="h4">
                  {memberDetails.address.line1}
                  <br />
                  {memberDetails.address.city},{' '}
                  {memberDetails.address.state}{' '}
                  {memberDetails.address.zipCode}
                  <Header.Subheader>Member Address</Header.Subheader>
               </Header>
            </Grid.Column>
            <Grid.Column>
               <Header as="h4">
                  {memberDetails.pcp_name}
                  <br />
                  (NPI: {memberDetails.pcp_npi})
                  <Header.Subheader>PCP Info.</Header.Subheader>
               </Header>
            </Grid.Column>
         </Grid.Row>
         <Grid.Row columns={4}>
            <Grid.Column>
               <Header as="h4">
                  <Popup
                     content={
                        'Ideally, this should be below 7% by the end of the year.'
                     }
                     trigger={
                        <span
                           className={
                              memberDetails.acr_num /
                                 memberDetails.acr_denom <
                              0.07
                                 ? 'text green'
                                 : 'text red'
                           }
                        >
                           {Math.round(
                              (memberDetails.acr_num /
                                 memberDetails.acr_denom) *
                                 1000,
                           ) / 10}
                           %
                        </span>
                     }
                  ></Popup>
                  <Header.Subheader>
                     All-cause Readmission Rate
                  </Header.Subheader>
               </Header>
            </Grid.Column>
            <Grid.Column>
               <Header as="h4">
                  <Popup
                     content={
                        'Unplanned Admission with Multiple Chronic Conditions.'
                     }
                     trigger={
                        <span>{Math.round(memberDetails.uamcc)}</span>
                     }
                  ></Popup>
                  <Header.Subheader>UAMCC Counts</Header.Subheader>
               </Header>
            </Grid.Column>
            <Grid.Column>
               <Header as="h4">
                  <Popup
                     content={
                        'Ideally, this should be over 85% by the end of the year.'
                     }
                     trigger={
                        <span
                           className={
                              memberDetails.awv_is_due === 'Y'
                                 ? 'text green'
                                 : 'text red'
                           }
                        >
                           {memberDetails.awv_is_due === 'Y'
                              ? `Due: ${memberDetails.awv_due_date}`
                              : `Not Due Yet`}
                        </span>
                     }
                  ></Popup>
                  <Header.Subheader>Is AWV Due</Header.Subheader>
               </Header>
            </Grid.Column>
            <Grid.Column>
               <Header as="h4">
                  {memberDetails.awv_code}
                  <Header.Subheader>AWV Code</Header.Subheader>
               </Header>
            </Grid.Column>
         </Grid.Row>
         <Grid.Row columns={4}>
            <Grid.Column>
               <Header as="h4">
                  {Math.round(memberDetails.raf_my * 1000) / 1000}
                  <Header.Subheader>
                     RAF Estimate (YTD)
                  </Header.Subheader>
               </Header>
            </Grid.Column>
            <Grid.Column>
               <Header as="h4" className="teal">
                  {Math.round(memberDetails.raf_py * 1000) / 1000}
                  <Header.Subheader>
                     RAF Estimate (Prev.)
                  </Header.Subheader>
               </Header>
            </Grid.Column>
         </Grid.Row>
         <Grid.Row columns={2}>
            <Grid.Column>
               <Header as="h4">
                  HCC Details
                  <Header.Subheader>
                     Closed, Recapture, and Suspected HCCs
                  </Header.Subheader>
               </Header>
               <GreyDownloadButton
                  icon
                  onClick={() => downloadPaf()}
                  loading={loading}
               >
                  <Icon name="download" /> Download PAF
               </GreyDownloadButton>
               <Table basic="very" size="small" textAlign="center">
                  <Table.Header>
                     <Table.Row>
                        <Table.HeaderCell textAlign="left" width={8}>
                           HCC Description
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" width={8}>
                           Prior Evidence
                        </Table.HeaderCell>
                     </Table.Row>
                  </Table.Header>
                  <Table.Body>
                     {Object.keys(memberDetails.hcc.raf_details)
                        .sort((a, b) => {
                           const offsetA =
                              memberDetails.hcc.raf_details[a]
                                 .status === 'closed'
                                 ? -1000
                                 : 0;
                           const offsetB =
                              memberDetails.hcc.raf_details[b]
                                 .status === 'closed'
                                 ? -1000
                                 : 0;
                           return (
                              parseInt(a.split(' ')[0].slice(3)) +
                              offsetA -
                              parseInt(b.split(' ')[0].slice(3)) +
                              offsetB
                           );
                        })
                        .map((hccDescription) => {
                           const hccDetails =
                              memberDetails.hcc.raf_details[
                                 hccDescription
                              ];
                           return (
                              <Table.Row key={hccDescription}>
                                 <Table.Cell textAlign="left">
                                    <Label
                                       basic
                                       size="mini"
                                       color={determineColor(
                                          hccDetails.status,
                                       )}
                                    >
                                       {hccDetails.status}
                                    </Label>{' '}
                                    {hccDescription}{' '}
                                 </Table.Cell>
                                 <Table.Cell textAlign="left">
                                    <List>
                                       {Object.values(
                                          hccDetails.evidence,
                                       ).map((evidenceItem) => (
                                          <List.Item
                                             key={evidenceItem}
                                          >
                                             {evidenceItem}
                                          </List.Item>
                                       ))}
                                    </List>
                                 </Table.Cell>
                              </Table.Row>
                           );
                        })}
                  </Table.Body>
               </Table>
            </Grid.Column>
         </Grid.Row>
      </>
   );
};

export default MemberProfile;
