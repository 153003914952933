import React, { useState, useEffect, useRef } from 'react';
import {
   Table,
   Grid,
   Header,
   Icon,
   Divider,
} from 'semantic-ui-react';
import 'moment-timezone';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation, useHistory } from 'react-router-dom';
import { useStitchAuth } from '../../StitchAuth';
import TableHeader from './TableHeader/TableHeader';
import TableRow from './TableRow/TableRow';
import ProviderListDropdown from './ProviderListDropdown/ProviderListDropdown';
import SortOptionsDropdown from './SortOptionsDropdown';
import MemberListPagination from '../MemberListPagination';
import MemberProfile from '../MemberProfile';
import AwvChaseListFilters from './AwvChaseListFilters/AwvChaseListFilters';
import AwvChaseListActions from './AwvChaseListActions';
import { formatSearchRequest } from './ContentTableUtils';
import {
   useMembersListQuery,
   updateSearchParams,
   checkUserHasFullAccess,
} from '../../Common/arqUtils';
import { URL } from '../../Common/Constants/URLConstants';
import ArqPlaceholder from '../../Common/StyledComponents/Placeholder/Placeholder';
import { GridBody } from '../../Common/StyledComponents/Grid/Grid';
import { BlueDownloadButton } from '../../Common/StyledComponents/Buttons/Buttons';

const ContentTable = () => {
   const {
      userCustomData,
      actions: { handleFetchMemberSingle, handleFetchMembers },
   } = useStitchAuth();
   const [memberData, setMemberData] = useState({ data: [] });
   const [memberDetails, setMemberDetails] = useState(null);
   const location = useLocation();
   const query = useMembersListQuery();
   const history = useHistory();
   const [loading, setLoading] = useState(true);
   const componentToPrint = useRef();
   const [loadingToPrint, setLoadingToPrint] = useState(false);
   const [loadingToDownload, setLoadingToDownload] = useState(false);
   const [addProviderInfoToPrint, setAddProviderInfoToPrint] =
      useState();

   useEffect(() => {
      if (userCustomData) {
         const hasFullAccess = checkUserHasFullAccess(userCustomData);

         if (!query.npi) {
            history.push({
               pathname: URL.AWV_CHASE_LIST,
               search: `npi=${
                  hasFullAccess ? 'all' : userCustomData.npi || 'none'
               }`,
            });
         } else {
            const searchParams = new URLSearchParams(location.search);
            const memberId = searchParams.get('memberId');
            if (memberId) {
               // Member Details view
               setLoading(true);
               handleFetchMemberSingle(memberId).then((res) => {
                  if (res.success) {
                     setMemberDetails(res.data);
                     setLoading(false);
                  }
               });
            } else {
               // If no memberId, then Live View
               setMemberDetails(null);
               const searchRequest =
                  formatSearchRequest(searchParams);

               setLoading(true);
               handleFetchMembers(searchRequest).then((res) => {
                  if (res.success) {
                     setMemberData(res.data);
                     setLoading(false);
                  }
               });
            }
         }
      }

      return () => {
         setMemberData({ data: [] });
      };
   }, [
      userCustomData,
      handleFetchMembers,
      handleFetchMemberSingle,
      location,
      query.npi,
      history,
   ]);

   return (
      <ErrorBoundary>
         <GridBody stackable>
            {memberDetails === null && (
               <>
                  <Grid.Row
                     columns={1}
                     style={{ padding: '0px 0px 14px 0px' }}
                  >
                     <Grid.Column>
                        <Header>AWV Chase List</Header>
                     </Grid.Column>
                  </Grid.Row>
                  <AwvChaseListActions
                     query={query}
                     location={location}
                     loadingToDownload={loadingToDownload}
                     loadingToPrint={loadingToPrint}
                     memberData={memberData}
                     componentToPrint={componentToPrint}
                     setLoading={setLoading}
                     setLoadingToDownload={setLoadingToDownload}
                     setLoadingToPrint={setLoadingToPrint}
                     setMemberData={setMemberData}
                     addProviderInfoToPrint={addProviderInfoToPrint}
                     setAddProviderInfoToPrint={
                        setAddProviderInfoToPrint
                     }
                  />
                  <Grid.Row columns={1}>
                     <Grid.Column>
                        <ProviderListDropdown
                           basePath={location.pathname}
                        />
                     </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                     <Grid.Column>
                        <AwvChaseListFilters
                           query={query}
                           basePath={location.pathname}
                        />
                     </Grid.Column>
                     <Grid.Column
                        style={{
                           justifyContent: 'flex-end',
                           display: 'flex',
                        }}
                     >
                        <SortOptionsDropdown
                           basePath={location.pathname}
                           memberData={memberData?.data}
                           loadingData={loading}
                        />
                     </Grid.Column>
                  </Grid.Row>
                  <Divider />
                  <Grid.Row columns={2}>
                     <Grid.Column></Grid.Column>
                     <Grid.Column textAlign="right">
                        <MemberListPagination
                           memberData={memberData}
                           query={query}
                        />
                     </Grid.Column>
                  </Grid.Row>
                  {loading && (
                     <Grid.Row columns={1}>
                        <Grid.Column>
                           <ArqPlaceholder />
                        </Grid.Column>
                     </Grid.Row>
                  )}
                  {!loading && (
                     <div
                        className="print-style"
                        ref={componentToPrint}
                     >
                        {addProviderInfoToPrint}
                        <Grid.Row columns={1}>
                           <Grid.Column>
                              <Table
                                 size="small"
                                 selectable
                                 basic="very"
                              >
                                 <TableHeader
                                    loadingToPrint={loadingToPrint}
                                 />
                                 <Table.Body>
                                    {(memberData.data || []).map(
                                       (member) => (
                                          <TableRow
                                             key={member._id}
                                             member={member}
                                             basePath={
                                                location.pathname
                                             }
                                             loadingToPrint={
                                                loadingToPrint
                                             }
                                          />
                                       ),
                                    )}
                                 </Table.Body>
                              </Table>
                           </Grid.Column>
                        </Grid.Row>
                     </div>
                  )}
                  <Grid.Row columns={2}>
                     <Grid.Column></Grid.Column>
                     <Grid.Column textAlign="right">
                        <MemberListPagination
                           memberData={memberData}
                           query={query}
                        />
                     </Grid.Column>
                  </Grid.Row>
               </>
            )}
            {memberDetails !== null && (
               <>
                  <Grid.Row columns={1}>
                     <Grid.Column>
                        <BlueDownloadButton
                           onClick={() =>
                              updateSearchParams(
                                 history,
                                 query,
                                 URL.AWV_CHASE_LIST,
                                 'memberId',
                                 null,
                              )
                           }
                        >
                           <Icon name="list" />
                           &nbsp; Back to the List view
                        </BlueDownloadButton>
                     </Grid.Column>
                  </Grid.Row>
                  {loading && (
                     <Grid.Row columns={1}>
                        <Grid.Column>
                           <ArqPlaceholder />
                        </Grid.Column>
                     </Grid.Row>
                  )}
                  {!loading && (
                     <MemberProfile memberDetails={memberDetails} />
                  )}
               </>
            )}
         </GridBody>
      </ErrorBoundary>
   );
};

export default ContentTable;
