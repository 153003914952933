import React from 'react';
import { Form, Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { handlePhoneNumberChange } from '../../Common/utils';

const FormLabels = styled.p`
   display: inline;
   fontsize: 16px;
   font-weight: 400;
`;

const headerStyle = { color: '#1D4C8F' };

export const OfficeInfoSection = ({
   userProfile,
   setUserProfile,
}) => {
   return (
      <>
         <Grid.Row
            style={{
               marginBottom: 0,
               padding: 0,
            }}
         >
            <Grid.Column>
               <Header as="h4" style={headerStyle}>
                  Office
               </Header>
            </Grid.Column>
         </Grid.Row>
         <Grid.Row style={{ paddingTop: 0, gap: '56px' }}>
            <Grid.Column width={5} style={{ marginTop: '18px' }}>
               <FormLabels>Office Phone Number</FormLabels>
               <Form.Input
                  style={{ marginTop: '8px', marginBottom: '16px' }}
                  fluid
                  name="phoneNumber"
                  placeholder="(XXX) - XXX - XXXX"
                  value={userProfile?.office.phoneOffice || ''}
                  type="text"
                  required
                  onChange={(e) =>
                     setUserProfile({
                        ...userProfile,
                        office: {
                           ...userProfile.office,
                           phoneOffice: handlePhoneNumberChange(
                              e.target.value,
                              userProfile.office.phoneOffice || '',
                           ),
                        },
                     })
                  }
               />
            </Grid.Column>
            <Grid.Column width={5} style={{ marginTop: '18px' }}>
               <FormLabels>Office Email</FormLabels>
               <Form.Input
                  style={{ marginTop: '8px', marginBottom: '16px' }}
                  fluid
                  name="email"
                  placeholder="Office Email"
                  value={userProfile?.office.email || ''}
                  type="text"
                  required
                  onChange={(e) =>
                     setUserProfile({
                        ...userProfile,
                        office: {
                           ...userProfile.office,
                           email: e.target.value,
                        },
                     })
                  }
               />
            </Grid.Column>
         </Grid.Row>
      </>
   );
};
