import moment from 'moment';
import {
   DATE_TIME_FORMATS,
   getCurrentYear,
   getPreviousYear,
   getPrevMonthLastDate,
   UNIX_TIMESTAMP_SEC_TO_MS,
} from '../../Common/DateTimeUtils';

export const formatSearchRequest = (
   searchParams,
   getAllMembers = false,
) => {
   const searchRequest = {
      npi: searchParams.get('npi') || 'all',
      sortParam: searchParams.get('sortParam') || 'awvDueAsc',
      pageNum: searchParams.get('page') || 1,
      filters: searchParams.get('filter')?.split(',') || [],
      getAllMembers: getAllMembers,
   };

   return searchRequest;
};

/**
 * Headers for the Member list CSV file mapped to their member data property
 */
const MEMBER_LIST_CSV_HEADERS = Object.freeze({
   NAME: 'name',
   DOB: 'dob',
   GENDER: 'gender',
   MBI: 'mbi',
   RAF_MY: 'raf_my',
   RAF_PY: 'raf_py',
   AWV_IS_DUE: 'awv_is_due',
   AWV_DUE_TIMESTAMP: 'awvDueTs',
   PREVIOUS_AWV_RECORDS: 'awv_prev_records',
   G_CODE_RECOMMENDATION: 'awv_code',
   HCC_OPEN_COUNT: 'hcc_recapture_cnt',
   LAST_UPDATED_DATE: 'awvScriptLastUpdatedDate',
});

const MEMBER_LIST_CSV_HEADER_LABELS = {
   [MEMBER_LIST_CSV_HEADERS.NAME]: 'Name',
   [MEMBER_LIST_CSV_HEADERS.DOB]: 'Member DOB',
   [MEMBER_LIST_CSV_HEADERS.GENDER]: 'Member Gender',
   [MEMBER_LIST_CSV_HEADERS.MBI]: 'Medicare ID',
   [MEMBER_LIST_CSV_HEADERS.AWV_IS_DUE]: `Completed in ${getCurrentYear()}?`,
   [MEMBER_LIST_CSV_HEADERS.AWV_DUE_TIMESTAMP]: 'AWV Due Date',
   [MEMBER_LIST_CSV_HEADERS.G_CODE_RECOMMENDATION]:
      'Suggested G Code​',
   [MEMBER_LIST_CSV_HEADERS.PREVIOUS_AWV_RECORDS]:
      'Historical AWV Records​',
   [MEMBER_LIST_CSV_HEADERS.HCC_OPEN_COUNT]: 'HCC Open Count',
   [MEMBER_LIST_CSV_HEADERS.RAF_MY]: `RAF ${getCurrentYear()}`,
   [MEMBER_LIST_CSV_HEADERS.RAF_PY]: `RAF ${getPreviousYear()}`,
};

// Captures the order in which CSV headers should be displayed (Object.keys() is inconsistent in order)
const CSV_HEADERS_ARRAY = [
   MEMBER_LIST_CSV_HEADERS.NAME,
   MEMBER_LIST_CSV_HEADERS.DOB,
   MEMBER_LIST_CSV_HEADERS.GENDER,
   MEMBER_LIST_CSV_HEADERS.MBI,
   MEMBER_LIST_CSV_HEADERS.AWV_IS_DUE,
   MEMBER_LIST_CSV_HEADERS.AWV_DUE_TIMESTAMP,
   MEMBER_LIST_CSV_HEADERS.G_CODE_RECOMMENDATION,
   MEMBER_LIST_CSV_HEADERS.PREVIOUS_AWV_RECORDS,
   MEMBER_LIST_CSV_HEADERS.HCC_OPEN_COUNT,
   MEMBER_LIST_CSV_HEADERS.RAF_MY,
   MEMBER_LIST_CSV_HEADERS.RAF_PY,
];

const formatCsvCellValue = (columnName, member) => {
   const rafDecimalRounder = 1000;

   switch (columnName) {
      case MEMBER_LIST_CSV_HEADERS.DOB:
         return member[columnName].slice(0, 10);
      case MEMBER_LIST_CSV_HEADERS.AWV_IS_DUE:
         return member[columnName] === 'Y' ? 'N' : 'Y';
      case MEMBER_LIST_CSV_HEADERS.AWV_DUE_TIMESTAMP:
         return moment
            .unix(member[columnName] / UNIX_TIMESTAMP_SEC_TO_MS)
            .format(DATE_TIME_FORMATS.LONG_DATE_NO_COMMA);
      case MEMBER_LIST_CSV_HEADERS.RAF_MY:
      case MEMBER_LIST_CSV_HEADERS.RAF_PY:
         return (
            Math.round(member[columnName] * rafDecimalRounder) /
            rafDecimalRounder
         );
      default:
         return member[columnName];
   }
};

export const createCsvFileFromMembers = (memberResponse) => {
   /* == Sets a string literal that contains the last awvScriptLastUpdateDate of the first member of the array == */
   const topMemberData = memberResponse?.data ?? [];
   const lastUpdatedDate = getAwvScriptLastUpdatedDate(topMemberData);
   /* === END of string literal for awvScriptLastUpdatedDate === */

   return (
      CSV_HEADERS_ARRAY.map(
         (headerKey) =>
            MEMBER_LIST_CSV_HEADER_LABELS?.[headerKey] ?? '',
      ).join(',') +
      `,${lastUpdatedDate}\n` +
      memberResponse.data
         .map((member) =>
            CSV_HEADERS_ARRAY.map((colname) => {
               return formatCsvCellValue(colname, member);
            }).join(','),
         )
         .join('\n')
   );
};

/**
 * Accepts the members data array to get the first member of the list
 * and return a string literal that contains the awvScriptLastUpdatedDate
 * @param {Array} memberData an array of members' object
 * @returns {String} "Data is current through 9/30/23 as of 10/20/23"
 */
export const getAwvScriptLastUpdatedDate = (memberData) => {
   const prevMonthLastDate = moment(
      getPrevMonthLastDate(memberData?.[0]?.awvScriptLastUpdatedDate),
   ).format(DATE_TIME_FORMATS.MINIMAL_DATE);
   const memberAwvLastUpdate = moment(
      memberData?.[0]?.awvScriptLastUpdatedDate,
   ).format(DATE_TIME_FORMATS.MINIMAL_DATE);
   const lastUpdateDate = `Data is current through ${prevMonthLastDate} as of ${memberAwvLastUpdate}`;
   return lastUpdateDate;
};
