import React, { useState } from 'react';
import {
   Button,
   Form,
   Grid,
   Header,
   Segment,
   Message,
} from 'semantic-ui-react';
import { AcoLogoBlue } from './Common/StyledComponents/Logos/AcoLogo';
import { useStitchAuth } from './StitchAuth';
import { useHistory, useLocation } from 'react-router-dom';
import LoginMorning from './Common/Background/login-morning.svg';
import LoginAfternoon from './Common/Background/login-afternoon.svg';
import LoginEvening from './Common/Background/login-evening.svg';
// TODO: Uncomment at a later date after an API key has been created
// import {
//    PAGE_NAMES,
//    PASSWORD_RESET_EVENTS,
// } from './Common/ActivityData/ActivityDataEvents';

function useQuery() {
   return new URLSearchParams(useLocation().search);
}

const PasswordResetForm = (props) => {
   const history = useHistory();
   const [password, setPassword] = useState('');

   const [formError, setFormError] = useState(false);
   const [success, setSuccess] = useState(false);

   const { actions } = useStitchAuth();

   const query = useQuery();
   const token = query.get('token');
   const tokenId = query.get('tokenId');

   function handleBackgroundSelection(currTime) {
      if (currTime >= 5 && currTime < 12) {
         return {
            img: LoginMorning,
            msg: 'Good Morning!',
            color: '#7E174F',
         };
      } else if (currTime >= 18 || (currTime >= 0 && currTime < 5)) {
         return {
            img: LoginEvening,
            msg: 'Good Evening!',
            color: '#FFFFFF',
         };
      } else {
         return {
            img: LoginAfternoon,
            msg: 'Good Afternoon!',
            color: '#1D4C8F',
         };
      }
   }

   const handleSubmit = (event) => {
      actions
         .handleResetPassword(token, tokenId, password)
         .then(() => {
            // TODO: Uncomment at a later date after an API key has been created
            // await actions.handleLogPasswordReset({
            //    name: PASSWORD_RESET_EVENTS.RESET_SUCCESS,
            //    pageName: PAGE_NAMES.PASSWORD_RESET,
            // });
            setTimeout(() => {
               history.replace({ pathname: '/login' });
            }, 3000);
            setSuccess(true);
         })
         .catch(() => {
            setFormError(true);
         });
   };

   if (success) {
      return (
         <div
            style={{
               backgroundImage: `url(${
                  handleBackgroundSelection(new Date().getHours()).img
               })`,
               backgroundPosition: 'center',
               backgroundSize: 'cover',
            }}
         >
            <Grid
               textAlign="center"
               style={{
                  height: '100vh',
                  margin: 0,
                  backgroundColor: 'rgba(0,0,0,0.5)',
               }}
               verticalAlign="middle"
            >
               <Grid.Column
                  style={{
                     maxWidth: 450,
                     backgroundColor: '#fff',
                     padding: '3em',
                  }}
               >
                  <Header as="h2" textAlign="center">
                     Password Reset Success!
                     <Header.Subheader>
                        Please visit the
                        <a
                           href="/login"
                           style={{ fontWeight: 'bold' }}
                        >
                           {' '}
                           Login{' '}
                        </a>
                        page and re-login with your new password.
                     </Header.Subheader>
                  </Header>
               </Grid.Column>
            </Grid>
         </div>
      );
   } else {
      return (
         <div>
            <Grid
               style={{
                  backgroundImage: `url(${
                     handleBackgroundSelection(new Date().getHours())
                        .img
                  })`,
                  backgroundSize: 'cover',
                  height: '100vh',
                  margin: 0,
               }}
            >
               <Grid.Row columns={2}>
                  <Grid.Column
                     style={{
                        backgroundColor: '#fff',
                        textAlign: 'center',
                        maxWidth: 430,
                        height: 413,
                        padding: '3em',
                        margin: '0 auto',
                     }}
                     textAlign="center"
                     verticalAlign="middle"
                  >
                     <Header
                        as="h2"
                        icon
                        textAlign="center"
                        style={{ marginBottom: '1em' }}
                     >
                        <AcoLogoBlue />
                        <Header.Content
                           style={{
                              fontWeight: 300,
                              textAlign: 'left',
                              marginTop: '1em',
                           }}
                        >
                           Reset your password
                        </Header.Content>
                     </Header>

                     <Form size="large" error={formError}>
                        <Segment
                           style={{
                              border: '0',
                              boxShadow: '0 0 0 0',
                           }}
                        >
                           <Message
                              error
                              header="Invalid Username or Password"
                              content="Please check your username/password again."
                           />
                           <Form.Input
                              style={{
                                 marginBottom: '1.2em',
                                 outline: '0.0625em',
                                 borderWidth: '0.0625em',
                                 borderRadius: '0.0625em',
                              }}
                              icon="lock"
                              iconPosition="left"
                              placeholder="New Password"
                              type="password"
                              value={password}
                              onChange={(event) =>
                                 setPassword(
                                    event.target.value.replace(
                                       /\s/g,
                                       '',
                                    ),
                                 )
                              }
                           />
                           <Button
                              style={{ width: '150px' }}
                              primary
                              fluid
                              size="medium"
                              onClick={handleSubmit}
                           >
                              Reset Password
                           </Button>
                        </Segment>
                     </Form>
                  </Grid.Column>
               </Grid.Row>
            </Grid>
         </div>
      );
   }
};

export default PasswordResetForm;
