import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { save } from 'save-file';
import { useStitchAuth } from '../../../StitchAuth';
import {
   AWV_CHASE_LIST_EVENTS,
   PAGE_NAMES,
} from '../../../Common/ActivityData/ActivityDataEvents';
import { GreyDownloadButton } from '../../../Common/StyledComponents/Buttons/Buttons';

const PafDownloadCell = ({ member }) => {
   const {
      actions: { handleFetchPafSingleMember, handleLogActivityData },
   } = useStitchAuth();
   const [loading, setLoading] = useState(false);

   const downloadPaf = () => {
      setLoading(true);
      // Log event data for download PAF clicked
      handleLogActivityData({
         name: AWV_CHASE_LIST_EVENTS.DOWNLOAD_PAF_CLICKED,
         pageName: PAGE_NAMES.AWV_CHASE_LIST,
      });
      handleFetchPafSingleMember(member.mbi).then((res) => {
         if (res.success && res.data) {
            // Log event data for download PAF success
            handleLogActivityData({
               name: AWV_CHASE_LIST_EVENTS.DOWNLOAD_PAF_SUCCESS,
               pageName: PAGE_NAMES.AWV_CHASE_LIST,
            });
            save(res.data.pdfBase64, `${res.data.fn}.pdf`);
         } else {
            console.error('no PAF available');
            // Log event data for download PAF failure
            handleLogActivityData({
               name: AWV_CHASE_LIST_EVENTS.DOWNLOAD_PAF_FAILURE,
               pageName: PAGE_NAMES.AWV_CHASE_LIST,
            });
         }
         setLoading(false);
      });
   };

   return (
      <GreyDownloadButton
         loading={loading}
         onClick={() => downloadPaf()}
         style={{ padding: '11px 13.5px' }}
      >
         <Icon name="download" /> Download PAF
      </GreyDownloadButton>
   );
};

export default PafDownloadCell;
