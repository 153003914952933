import { Form, Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { formatNumbersOnly } from '../../Common/arqUtils';
import {
   PERSONA,
   PERSONA_REGISTER_USERS_OPTIONS,
} from '../../Common/Constants/UserConstants';

const FormLabels = styled.p`
   display: inline;
   fontsize: 16px;
`;

const RequiredFields = styled.p`
   display: inline;
   color: red;
`;

const ErrorMessageLabel = styled.p`
   color: red;
`;

const RegisterAboutForm = ({
   newUser,
   setNewUser,
   setFirstNameError,
   firstNameError,
   lastNameError,
   setLastNameError,
   personasError,
   setPersonasError,
   npiError,
   setNpiError,
   tinError,
   setTinError,
   showPersonaDropdown = false,
}) => {
   return (
      <Grid.Column width={5}>
         <Header as="h4">About</Header>
         <Form.Field>
            <div
               style={{
                  marginBottom: '16px',
               }}
            >
               <label>
                  <FormLabels>User First Name </FormLabels>
                  <RequiredFields>(Required)</RequiredFields>
               </label>
               <Form.Input
                  style={{ marginTop: '8px', marginBottom: '8px' }}
                  fluid
                  name="name"
                  placeholder="First Name"
                  value={newUser.firstName}
                  type="text"
                  required
                  onChange={(e) => {
                     setNewUser({
                        ...newUser,
                        firstName: e.target.value,
                     });
                     if (e.target.value === '') {
                        setFirstNameError(true);
                     } else {
                        setFirstNameError(false);
                     }
                  }}
               />
               {firstNameError ? (
                  <ErrorMessageLabel>
                     Please fill out first name
                  </ErrorMessageLabel>
               ) : null}
            </div>
         </Form.Field>
         <div
            style={{
               marginBottom: '16px',
            }}
         >
            <label>
               <FormLabels>User Last Name </FormLabels>
               <RequiredFields>(Required)</RequiredFields>
            </label>
            <Form.Field>
               <Form.Input
                  style={{ marginTop: '8px', marginBottom: '8px' }}
                  fluid
                  name="name"
                  placeholder="Last Name"
                  value={newUser.lastName}
                  required
                  onChange={(e) => {
                     setNewUser({
                        ...newUser,
                        lastName: e.target.value,
                     });
                     if (e.target.value === '') {
                        setLastNameError(true);
                     } else {
                        setLastNameError(false);
                     }
                  }}
               />
               {lastNameError ? (
                  <ErrorMessageLabel>
                     Please fill out last name
                  </ErrorMessageLabel>
               ) : null}
            </Form.Field>
         </div>
         {showPersonaDropdown && (
            <div
               style={{
                  marginBottom: '16px',
               }}
            >
               <Form.Field>
                  <label>
                     <FormLabels>Personas </FormLabels>
                     <RequiredFields>(Required)</RequiredFields>
                  </label>
                  <Form.Dropdown
                     style={{ marginTop: '8px', marginBottom: '8px' }}
                     fluid
                     selection
                     options={PERSONA_REGISTER_USERS_OPTIONS}
                     value={newUser.personas}
                     onChange={(e, { value }) => {
                        setNewUser({
                           ...newUser,
                           personas: value,
                        });
                        if (e.target.value === '') {
                           setPersonasError(true);
                        } else {
                           setPersonasError(false);
                        }
                     }}
                  />
                  {personasError ? (
                     <ErrorMessageLabel>
                        Please select a persona
                     </ErrorMessageLabel>
                  ) : null}
               </Form.Field>
            </div>
         )}
         {newUser.personas === PERSONA.PROVIDER ? (
            <>
               <div style={{ marginBottom: '16px' }}>
                  <label>
                     <FormLabels>Provider’s NPI </FormLabels>
                     <RequiredFields>(Required)</RequiredFields>
                  </label>
                  <Form.Field>
                     <Form.Input
                        style={{
                           marginTop: '8px',
                           marginBottom: '8px',
                        }}
                        type="text"
                        required
                        maxLength="10"
                        fluid
                        placeholder="NPI (10 digits)"
                        value={newUser.npi}
                        onChange={(e) => {
                           setNewUser({
                              ...newUser,
                              npi: formatNumbersOnly(e.target.value),
                           });
                           if (
                              formatNumbersOnly(e.target.value)
                                 .length !== 10
                           ) {
                              setNpiError(true);
                           } else {
                              setNpiError(false);
                           }
                        }}
                     />
                     {npiError ? (
                        <ErrorMessageLabel>
                           NPI must be 10 digits long
                        </ErrorMessageLabel>
                     ) : null}
                  </Form.Field>
               </div>
            </>
         ) : null}
         {newUser.personas === PERSONA.PROVIDER ||
         newUser.personas === PERSONA.OFFICE_MANAGER ? (
            <>
               <div style={{ marginBottom: '16px' }}>
                  <label>
                     <FormLabels>TIN </FormLabels>
                     <RequiredFields>(Required)</RequiredFields>
                  </label>
                  <Form.Field>
                     <Form.Input
                        style={{
                           marginTop: '8px',
                           marginBottom: '8px',
                        }}
                        fluid
                        name="tin"
                        placeholder="TIN (9 digits)"
                        value={newUser.tin}
                        required
                        maxLength="9"
                        onChange={(e) => {
                           setNewUser({
                              ...newUser,
                              tin: formatNumbersOnly(e.target.value),
                           });
                           if (
                              formatNumbersOnly(e.target.value)
                                 .length !== 9
                           ) {
                              setTinError(true);
                           } else {
                              setTinError(false);
                           }
                        }}
                     />
                     {tinError ? (
                        <ErrorMessageLabel>
                           TIN must be 9 digits long
                        </ErrorMessageLabel>
                     ) : null}
                  </Form.Field>
               </div>
            </>
         ) : null}
      </Grid.Column>
   );
};

export default RegisterAboutForm;
