import styled from 'styled-components';
import { Form, Input, TextArea } from 'semantic-ui-react';
import {
   InputColors,
   SemanticErrorColors,
   TextColors,
} from '../Colors.js';

export const DisableInput = styled(Form.Input)`
   background-color: ${InputColors.GreyDisabledBackground} !important;
   border-radius: 4px !important;
   color: ${InputColors.GreyText} !important;
   font-family: Lato !important;
   font-style: normal !important;
   font-weight: 400 !important;
   font-size: 14px !important;
   line-height: 18px !important;
`;

export const DisabledTextArea = styled(TextArea)`
   background-color: ${InputColors.GreyDisabledBackground} !important;
   border: 1px solid ${InputColors.GreyBorder} !important;
   border-radius: 4px !important;
   color: ${InputColors.GreyText} !important;
   font-family: Lato !important;
   font-style: normal !important;
   font-weight: 400 !important;
   font-size: 14px !important;
   line-height: 18px !important;
   resize: none !important;
   pointer-events: none !important;
   caret-color: transparent !important;
   overflow: hidden !important;
`;

export const DateNumInput = styled(Input)`
   border-radius: 4px !important;
   font-family: Lato !important;
   font-style: normal !important;
   font-weight: 400 !important;
   font-size: 14px !important;
   line-height: 18px !important;
   padding: 0 !important;
   margin: 0 0 0 0 !important;
   text-align: center !important;
   color: ${TextColors.GreenDropdownText} !important;
`;

export const FieldInput = styled(Input)`
   border-radius: 4px !important;
   font-family: Lato !important;
   font-style: normal !important;
   font-weight: 400 !important;
   font-size: 14px !important;
   line-height: 18px !important;
   text-align: center !important;
   .error > input {
      background-color: ${SemanticErrorColors.backgroundColor};
      border-color: ${SemanticErrorColors.borderColor};
      color: ${SemanticErrorColors.color};
      box-shadow: none;
   }
`;
