import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../utils';
import { useStitchAuth } from '../../StitchAuth';
import TopNavigationBar from './TopNavigationBar/TopNavigationBar';
import { SideNavigationBar } from './SideNavigationBar/SideNavigationBar';
import { WorkspaceRegistration } from './WorkspaceRegistration';
import { UserRegistration } from './UserRegistration';
import { AcoLogoWhite } from '../StyledComponents/Logos/AcoLogo';

export default function AppBar(props) {
   const {
      userCustomData,
      actions,
      db: { members },
   } = useStitchAuth();
   const history = useHistory();
   const query = useQuery();
   const clientCode = query.get('clientCode') || 'orma';
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      if (!userCustomData) {
         actions.handleRefreshCustomData(clientCode);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userCustomData, members]);

   useEffect(() => {
      if (userCustomData && !userCustomData.hasBasicInformation) {
         history.replace('/userinformation');
      } else if (
         userCustomData &&
         !userCustomData.confirmedBasicInfo
      ) {
         history.replace('/userbasicinformation');
      }
   }, [userCustomData, history]);

   const logout = (e) => {
      e.preventDefault();
      actions.handleLogout();
   };

   if (userCustomData && !userCustomData.hasBasicInformation) {
      return (
         <WorkspaceRegistration logout={logout} Logo={AcoLogoWhite} />
      );
   } else if (userCustomData && !userCustomData.confirmedBasicInfo) {
      return <UserRegistration logout={logout} Logo={AcoLogoWhite} />;
   } else {
      return (
         <>
            <TopNavigationBar
               isLoading={isLoading}
               setIsLoading={setIsLoading}
               logout={logout}
               userCustomData={userCustomData}
            />
            <SideNavigationBar
               Logo={AcoLogoWhite}
               userCustomData={userCustomData}
            />
         </>
      );
   }
}
