import React from 'react';
import { Link } from 'react-router-dom';
import { TextColors } from '../../StyledComponents/Colors';
import WorkspaceDropdown from '../WorkspaceDropdown';
import PersonaDropdown from '../PersonaDropdown';

const TopNavigationActions = ({ logout, userCustomData }) => {
   return (
      <>
         <div
            style={{
               marginLeft: 'auto',
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
            }}
         >
            <PersonaDropdown />
            <WorkspaceDropdown />
            <div
               style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
               }}
            >
               <Link
                  to="/login"
                  onClick={logout}
                  style={{
                     color: TextColors.SemanticText,
                  }}
               >
                  Logout
               </Link>
            </div>
         </div>
      </>
   );
};

export default TopNavigationActions;
