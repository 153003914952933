import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Dropdown } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { useStitchAuth } from '../../../StitchAuth';
import {
   useMembersListQuery,
   updateSearchParams,
   checkUserHasFullAccess,
} from '../../../Common/arqUtils';
import {
   createPcpListForFullAccess,
   createPcpListForNpiList,
   createPcpListForSingleProviderMatched,
   createPcpListForSingleProviderNoMatch,
   createPcpListNoNpi,
} from './ProviderListDropdownUtils';

const ProviderListDropdown = ({ basePath }) => {
   const {
      userCustomData,
      actions: { handleFetchProviders },
   } = useStitchAuth();
   const query = useMembersListQuery();
   const history = useHistory();
   const hasFullAccess = checkUserHasFullAccess(userCustomData);
   const hasNpiList =
      userCustomData.npiList && userCustomData.npiList.length > 0;
   const initialPcpArray = hasFullAccess
      ? [{ key: 'all', text: 'All PCPs', value: 'all' }]
      : [{ key: 'none', text: 'Choose a provider', value: 'none' }];
   const [pcpList, setPcpList] = useState(initialPcpArray);
   const [providerDropdownLoading, setProviderDropdownLoading] =
      useState(true);

   const updateProviderDropdown = useCallback(
      (responseData) => {
         let newPcpList = [];
         if (hasFullAccess) {
            newPcpList = createPcpListForFullAccess(responseData);
         } else if (hasNpiList) {
            newPcpList = createPcpListForNpiList(responseData);
         } else if (userCustomData.npi !== '') {
            if (responseData.length > 0) {
               // If the user has an NPI with an existing
               // provider in DB, display DB name
               newPcpList =
                  createPcpListForSingleProviderMatched(responseData);
            } else {
               // Otherwise, display user's name with
               // user's NPI
               newPcpList =
                  createPcpListForSingleProviderNoMatch(
                     userCustomData,
                  );
            }
         } else {
            newPcpList = createPcpListNoNpi;
         }
         setPcpList(newPcpList);
         setProviderDropdownLoading(false);
      },
      [hasFullAccess, userCustomData, hasNpiList],
   );

   useEffect(() => {
      if (userCustomData) {
         setProviderDropdownLoading(true);
         handleFetchProviders().then((res) => {
            if (res.success) {
               updateProviderDropdown(res.data);
            }
         });
      }

      return () => {
         setPcpList([]);
      };
   }, [
      userCustomData,
      hasFullAccess,
      handleFetchProviders,
      updateProviderDropdown,
   ]);

   return (
      <Form>
         <Form.Group widths="1">
            <Form.Field style={{ width: '300px' }}>
               <label>PCP</label>
               <Dropdown
                  fluid
                  selection
                  search
                  options={pcpList}
                  loading={providerDropdownLoading}
                  selectOnBlur={false}
                  value={query.npi || 'default'}
                  disabled={!hasFullAccess && !hasNpiList}
                  onChange={(e, data) =>
                     updateSearchParams(
                        history,
                        query,
                        basePath,
                        'npi',
                        data.value,
                     )
                  }
               />
            </Form.Field>
         </Form.Group>
      </Form>
   );
};

ProviderListDropdown.propTypes = {
   basePath: PropTypes.string,
};

export default ProviderListDropdown;
