import React from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import { BlueDownloadButton } from '../../../Common/StyledComponents/Buttons/Buttons';
import BulkUserFailedRegistrationTable from './BulkUserFailedRegistrationTable';
import BulkUserRegisteredTable from './BulkUserRegisteredTable';

const BulkUserResultsPage = ({
   registrationResponse,
   refreshState,
}) => {
   return (
      <>
         <Grid.Row style={{ marginTop: '-5px' }}>
            {registrationResponse.body?.failedUsers.length > 0 ? (
               <BulkUserFailedRegistrationTable
                  registrationResponse={registrationResponse}
               />
            ) : null}
         </Grid.Row>
         <Grid.Row style={{ marginTop: '24px' }}>
            {registrationResponse.body?.registeredUsers.length > 0 ? (
               <BulkUserRegisteredTable
                  registrationResponse={registrationResponse}
               />
            ) : null}
         </Grid.Row>
         <Grid.Row style={{ marginTop: '24px' }}>
            <BlueDownloadButton
               style={{
                  marginLeft: '21px',
                  float: 'right',
                  padding: '11px 14px',
               }}
               onClick={refreshState}
            >
               <Icon name="upload" />
               Upload Another File
            </BlueDownloadButton>
         </Grid.Row>
      </>
   );
};

export default BulkUserResultsPage;
