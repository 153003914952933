import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';
import { InputColors, LabelColors, TextColors } from '../Colors';

export const InterventionDropdown = styled(Dropdown)`
   &:not(.button) > .default.text {
      color: ${TextColors.BlackText} !important;
   }
   &&& > .menu > .header {
      color: ${InputColors.GreyText};
      background-color: ${InputColors.GreyDisabledBackground};
      text-transform: none;
      font-size: 14px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin: 0px;
      font-weight: 400;
   }

   &&& > .menu > .active.item {
      background-color: ${TextColors.SemanticWhite};
      font-weight: 400;
   }
   &&& > .menu > .active.item:hover {
      background-color: ${TextColors.SemanticWhiteGreyShade};
      font-weight: 400;
   }
   &&& > .menu > .selected.item {
      background-color: ${TextColors.SemanticWhite};
      font-weight: 400;
      color: ${TextColors.Grey5Text};
   }
   &&& > .menu > .selected.item:hover {
      background-color: ${TextColors.SemanticWhiteGreyShade};
      font-weight: 400;
   }
`;

export const RuleStepDropdown = styled(Dropdown)`
   border-radius: 4px !important;
   padding: 7px 8px 7px 8px !important;
   color: ${TextColors.GreenDropdownText} !important;
   border: 1px solid ${TextColors.GreenDropdownText} !important;
   width: 382px;
   min-width: 160px !important;
   min-height: 28px !important;

   &:not(.button) > .default.text {
      color: ${TextColors.GreenDropdownText} !important;
   }
   &&& > .menu {
      border: 1px solid ${TextColors.GreenDropdownText} !important;
   }
   &&& > input.search {
      padding: 7px 8px 7px 8px !important;
   }
   &&& > .menu > .header {
      color: ${TextColors.GreenDropdownText};
   }
   &&& > .menu > .active.item {
      background-color: ${TextColors.SemanticWhite};
      font-weight: 400;
      color: ${TextColors.GreenDropdownText};
   }
   &&& > .menu > .active.item:hover {
      background-color: ${TextColors.SemanticWhiteGreyShade};
      font-weight: 400;
      color: ${TextColors.GreenDropdownText};
   }
   &&& > .menu > .selected.item {
      background-color: ${TextColors.SemanticWhite};
      font-weight: 400;
      color: ${TextColors.GreenDropdownText};
      padding: 7px 8px 7px 8px !important;
   }
   &&& > .menu > .selected.item:hover {
      background-color: ${TextColors.SemanticWhiteGreyShade};
      font-weight: 400;
      color: ${TextColors.GreenDropdownText};
   }
   &&& > .menu > .item:hover {
      background-color: ${TextColors.SemanticWhiteGreyShade};
      font-weight: 400;
      color: ${TextColors.GreenDropdownText};
   }
   &&& > .menu > .item {
      background-color: ${TextColors.SemanticWhite};
      font-weight: 400;
      color: ${TextColors.GreenDropdownText};
      padding: 7px 8px 7px 8px !important;
   }
   &&& > .dropdown.icon {
      font-size: 0.9em;
      padding: 7px 4px 0 0;
   }
`;

export const DateTypeDropdown = styled(Dropdown)`
   border-radius: 4px !important;
   padding: 7px 8px 7px 8px !important;
   color: ${TextColors.GreenDropdownText} !important;
   border: 1px solid ${TextColors.GreenDropdownText} !important;
   min-width: 92px !important;
   min-height: 28px !important;

   &&& > .menu > .header {
      color: ${TextColors.GreenDropdownText};
   }
   &&& > .menu {
      border: 1px solid ${TextColors.GreenDropdownText} !important;
   }
   &&& > .menu > .active.item {
      background-color: ${TextColors.SemanticWhite};
      font-weight: 400;
      color: ${TextColors.GreenDropdownText};
   }
   &&& > .menu > .active.item:hover {
      background-color: ${TextColors.SemanticWhiteGreyShade};
      font-weight: 400;
      color: ${TextColors.GreenDropdownText};
   }
   &&& > .menu > .selected.item {
      background-color: ${TextColors.SemanticWhite};
      font-weight: 400;
      color: ${TextColors.GreenDropdownText};
   }
   &&& > .menu > .selected.item:hover {
      background-color: ${TextColors.SemanticWhiteGreyShade};
      font-weight: 400;
      color: ${TextColors.GreenDropdownText};
   }
   &&& > .menu > .item:hover {
      background-color: ${TextColors.SemanticWhiteGreyShade};
      font-weight: 400;
      color: ${TextColors.GreenDropdownText};
   }
   &&& > .menu > .item {
      background-color: ${TextColors.SemanticWhite};
      font-weight: 400;
      color: ${TextColors.GreenDropdownText};
   }

   &&& > .dropdown.icon {
      font-size: 0.9em;
      padding: 7px 4px 0 0;
   }
`;

export const RuleOutcomeDropdown = styled(Dropdown)`
   border-radius: 4px !important;
   padding: 7px 8px 7px 8px !important;
   color: ${TextColors.PurpleOutcomeText} !important;
   border: 1px solid ${TextColors.PurpleOutcomeText} !important;
   width: 382px;
   min-width: 160px !important;
   min-height: 28px !important;

   &:not(.button) > .default.text {
      color: ${TextColors.PurpleOutcomeText} !important;
   }
   &&& > .menu {
      border: 1px solid ${TextColors.PurpleOutcomeText} !important;
   }
   &&& > input.search {
      padding: 7px 8px 7px 8px !important;
   }
   &&& > .menu > .header {
      color: ${TextColors.PurpleOutcomeText};
   }
   &&& > .menu > .active.item {
      background-color: ${TextColors.SemanticWhite};
      font-weight: 400;
      color: ${TextColors.PurpleOutcomeText};
   }
   &&& > .menu > .active.item:hover {
      background-color: ${TextColors.SemanticWhiteGreyShade};
      font-weight: 400;
      color: ${TextColors.PurpleOutcomeText};
   }
   &&& > .menu > .selected.item {
      background-color: ${TextColors.SemanticWhite};
      font-weight: 400;
      color: ${TextColors.PurpleOutcomeText};
      padding: 7px 8px 7px 8px !important;
   }
   &&& > .menu > .selected.item:hover {
      background-color: ${TextColors.SemanticWhiteGreyShade};
      font-weight: 400;
      color: ${TextColors.PurpleOutcomeText};
   }
   &&& > .menu > .item:hover {
      background-color: ${TextColors.SemanticWhiteGreyShade};
      font-weight: 400;
      color: ${TextColors.PurpleOutcomeText};
   }
   &&& > .menu > .item {
      background-color: ${TextColors.SemanticWhite};
      font-weight: 400;
      color: ${TextColors.PurpleOutcomeText};
      padding: 7px 8px 7px 8px !important;
   }
   &&& > .dropdown.icon {
      font-size: 0.9em;
      padding: 7px 4px 0 0;
   }
`;

export const CarePlanDropdown = styled(Dropdown)`
   padding: 0.67857143em 1em !important;
   padding-bottom: 8.5px !important;
   font-family: Lato;
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 18px;
   min-height: 20px !important;
   min-width: 160px !important;
   &&& > .menu > .active.item {
      background-color: ${TextColors.SemanticWhite};
      font-weight: 400;
   }
   &&& > .menu > .active.item:hover {
      background-color: ${TextColors.SemanticWhiteGreyShade};
      font-weight: 400;
   }
   &&& > .menu > .selected.item {
      background-color: ${TextColors.SemanticWhite};
      font-weight: 400;
      color: ${TextColors.Grey5Text};
   }
   &&& > .menu > .selected.item:hover {
      background-color: ${TextColors.SemanticWhiteGreyShade};
      font-weight: 400;
   }
`;

export const PurposeDropdown = styled(Dropdown)`
   padding: 0.67857143em 1em !important;
   padding-bottom: 8.5px !important;
   font-family: Lato;
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 18px;
   min-height: 34px !important;
   min-width: 346px !important;
   &&& > .menu > .header {
      color: ${InputColors.GreyText};
      background-color: ${InputColors.GreyDisabledBackground};
      text-transform: none;
      font-size: 14px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin: 0;
      font-weight: 400;
   }

   &&& > .menu > .active.item {
      background-color: ${TextColors.SemanticWhite};
      font-weight: 400;
   }
   &&& > .menu > .active.item:hover {
      background-color: ${TextColors.SemanticWhiteGreyShade};
      font-weight: 400;
   }
   &&& > .menu > .selected.item {
      background-color: ${TextColors.SemanticWhite};
      font-weight: 400;
      color: ${TextColors.Grey5Text};
   }
   &&& > .menu > .selected.item:hover {
      background-color: ${TextColors.SemanticWhiteGreyShade};
      font-weight: 400;
   }
`;

export const StaffNameDropdown = styled(Dropdown)`
   font-family: Lato;
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 18px;
   min-height: 38px !important;
   height: 34px !important;
   padding: 0.67857143em 1em !important;
`;

// Styled Components for Member List Dropdown
export const ColumnDropdownHeader = styled.div`
   background-color: ${InputColors.GreyDisabledBackground};
   pointer-events: none !important;
   font-weight: 700;
   padding: 8px 9px;
   border-bottom: 1px solid ${InputColors.GreyBorder};
`;

export const DropdownItem = styled.div`
   min-height: 36px;
   font-weight: 400;
   font-family: 'Lato';
   background-color: ${LabelColors.SemanticWhite};
   padding: 8px 9px;
   align-items: center;
   cursor: pointer;
   border-bottom: 1px solid ${InputColors.GreyBorder};
   transition: background-color 0.3s;
   user-select: none;
   &:hover {
      background-color: ${InputColors.GreyBorder} !important;
   }
`;

export const DropdownScrollableContainer = styled.div`
   max-height: 400px;
   overflow-y: auto;
   overflow-x: hidden;
   background: rgba(222, 222, 222, 1);

   ::-webkit-scrollbar {
      width: 3px;
   }

   ::-webkit-scrollbar-thumb {
      border-right: 4px ${InputColors.GreyText} solid;
      background-clip: padding-box;
   }
`;

export const PersonaDropdownMenu = styled(Dropdown)`
   font-family: Lato;
   border-radius: 4px;
   font-size: 14px;
   font-style: normal;
   line-height: 18px;
   color: ${TextColors.SemanticText};
   margin-right: 29px;
   pointer-events: none;
   &&& .menu {
      top: 41px;
   }
   &&& .menu > .scrolling.menu {
      width: 390px !important;
      max-height: 560px;
      ::-webkit-scrollbar {
         width: 3px;
      }
   }
`;

export const WorkspaceDropdownMenu = styled(Dropdown)`
   font-family: Lato;
   border-radius: 4px;
   font-size: 14px;
   font-style: normal;
   line-height: 18px;
   color: ${TextColors.SemanticText};
   margin-right: 29px;
   &&& .menu {
      top: 41px;
   }
   &&& .menu > .scrolling.menu {
      width: 390px !important;
      max-height: 560px;
      ::-webkit-scrollbar {
         width: 3px;
      }
   }
`;
