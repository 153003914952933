import React from 'react';
import { Form, Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { handlePhoneNumberChange } from '../../Common/utils';

const FormLabels = styled.label`
   display: inline;
   fontsize: 16px;
   font-weight: 400;
`;

const headerStyle = { color: '#1D4C8F' };

export const PracticeManagerInfoSection = ({
   userProfile,
   setUserProfile,
   handleUserInformation,
}) => {
   return (
      <>
         <Grid.Row
            style={{
               marginBottom: 0,
               padding: 0,
            }}
         >
            <Grid.Column>
               <Header as="h4" style={headerStyle}>
                  Practice Manager
               </Header>
            </Grid.Column>
         </Grid.Row>
         <Grid.Row
            style={{
               paddingTop: 0,
               gap: '56px',
            }}
         >
            <Grid.Column width={5} style={{ marginTop: '18px' }}>
               <FormLabels>First Name </FormLabels>
               <Form.Input
                  style={{ marginTop: '8px', marginBottom: '16px' }}
                  fluid
                  placeholder="First Name"
                  value={userProfile?.practiceManager.firstName || ''}
                  type="text"
                  required
                  onChange={(e) =>
                     setUserProfile({
                        ...userProfile,
                        practiceManager: {
                           ...userProfile.practiceManager,
                           firstName: e.target.value,
                        },
                     })
                  }
               />
               <FormLabels>Phone Number</FormLabels>
               <Form.Input
                  style={{ marginTop: '8px', marginBottom: '16px' }}
                  fluid
                  placeholder="(XXX) - XXX - XXXX"
                  value={
                     userProfile?.practiceManager.phoneManager || ''
                  }
                  type="text"
                  required
                  onChange={(e) =>
                     setUserProfile({
                        ...userProfile,
                        practiceManager: {
                           ...userProfile.practiceManager,
                           phoneManager: handlePhoneNumberChange(
                              e.target.value,
                              userProfile.practiceManager
                                 .phoneManager || '',
                           ),
                        },
                     })
                  }
               />
            </Grid.Column>
            <Grid.Column width={5} style={{ marginTop: '18px' }}>
               <FormLabels>Last Name </FormLabels>
               <Form.Input
                  style={{ marginTop: '8px', marginBottom: '16px' }}
                  fluid
                  placeholder="Last Name"
                  value={userProfile?.practiceManager.lastName || ''}
                  required
                  onChange={(e) =>
                     setUserProfile({
                        ...userProfile,
                        practiceManager: {
                           ...userProfile.practiceManager,
                           lastName: e.target.value,
                        },
                     })
                  }
               />
               <FormLabels>Email</FormLabels>
               <Form.Input
                  style={{ marginTop: '8px', marginBottom: '16px' }}
                  fluid
                  placeholder="Practice Manager Email"
                  value={userProfile?.practiceManager.email || ''}
                  type="text"
                  required
                  onChange={(e) =>
                     setUserProfile({
                        ...userProfile,
                        practiceManager: {
                           ...userProfile.practiceManager,
                           email: e.target.value,
                        },
                     })
                  }
               />
               <Form.Button
                  primary
                  onClick={handleUserInformation}
                  style={{ float: 'right' }}
               >
                  Submit
               </Form.Button>
            </Grid.Column>
         </Grid.Row>
      </>
   );
};
