import React from 'react';
import { Form, Dropdown, Grid } from 'semantic-ui-react';
import { formatNameCharacters } from '../Common/arqUtils';
import { toTitleCase } from '../Common/utils';

const NameTimezoneForm = ({
   userName,
   setUserName,
   timeZone,
   setTimeZone,
}) => {
   const timeZoneOptions = [
      {
         key: 'America/New_York',
         text: 'America/New_York',
         value: 'America/New_York',
      },
      {
         key: 'America/Chicago',
         text: 'America/Chicago',
         value: 'America/Chicago',
      },
      {
         key: 'America/Los_Angeles',
         text: 'America/Los_Angeles',
         value: 'America/Los_Angeles',
      },
   ];

   const handleNameChange = (event) => {
      const { name, value } = event.target;
      setUserName({
         ...userName,
         [name]: formatNameCharacters(toTitleCase(value)).trim(),
      });
   };

   return (
      <>
         <Grid.Row>
            <Grid.Column width={8}>
               <label>
                  <p>First Name</p>
               </label>
               <Form.Field>
                  <Form.Input
                     style={{
                        marginTop: '8px',
                        marginBottom: '16px',
                     }}
                     fluid
                     name="firstName"
                     placeholder="First name"
                     type="text"
                     required
                     value={userName.firstName}
                     onChange={handleNameChange}
                  />
               </Form.Field>
            </Grid.Column>
            <Grid.Column width={8}>
               <label>
                  <p>Last Name</p>
               </label>
               <Form.Field>
                  <Form.Input
                     style={{
                        marginTop: '8px',
                        marginBottom: '16px',
                     }}
                     fluid
                     name="lastName"
                     placeholder="Last name"
                     type="text"
                     required
                     value={userName.lastName}
                     onChange={handleNameChange}
                  />
               </Form.Field>
            </Grid.Column>
         </Grid.Row>
         <Grid.Row style={{ marginTop: '-28px' }}>
            <Grid.Column>
               <label>
                  <p>Default Timezone</p>
               </label>
               <Dropdown
                  style={{ marginTop: '8px', marginBottom: '16px' }}
                  fluid
                  selection
                  options={timeZoneOptions}
                  value={timeZone}
                  onChange={(e, v) => setTimeZone(v.value)}
               />
            </Grid.Column>
         </Grid.Row>
      </>
   );
};

export default NameTimezoneForm;
