import React from 'react';
import { Modal, Icon, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export const UserFormValidationModal = ({
   isModalOpen,
   closeModal,
   errorMessages,
}) => {
   return (
      <Modal
         centered={true}
         open={isModalOpen}
         dimmer="inverted"
         style={{
            minWidth: '424px',
            width: 'auto',
         }}
      >
         <Modal.Header
            style={{
               borderBottom: 'none',
               fontSize: '18px',
               lineHeight: '27px',
            }}
         >
            <span>Please correct the following fields</span>
            <Icon
               color="black"
               name="close"
               style={{
                  float: 'right',
                  paddingLeft: '1em',
                  cursor: 'pointer',
               }}
               onClick={closeModal}
            />
         </Modal.Header>
         <Modal.Content
            style={{
               paddingTop: '0',
            }}
         >
            <Modal.Description
               style={{
                  textAlign: 'left',
               }}
            >
               {errorMessages &&
                  errorMessages.map((errorMessage) => {
                     return errorMessage === '' ? null : (
                        <p
                           key={errorMessage}
                           style={{
                              fontSize: '14px',
                              lineHeight: '18px',
                           }}
                        >
                           {errorMessage}
                        </p>
                     );
                  })}
               <Button
                  onClick={() => closeModal()}
                  primary
                  style={{ float: 'right', margin: '2em 0' }}
               >
                  Return to basic information form
               </Button>
            </Modal.Description>
         </Modal.Content>
      </Modal>
   );
};
UserFormValidationModal.propTypes = {
   isModalOpen: PropTypes.bool,
   closeModal: PropTypes.func,
   errorMessages: PropTypes.arrayOf(PropTypes.string),
};
