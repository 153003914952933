import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import { TextColors } from '../Colors.js';

export const GridBody = styled(Grid)`
   margin: 0px 0px 0px 0px !important;
   padding-top: 1.5em !important;
   padding-left: 2em !important;
   display: flex !important;
   flex-direction: column !important;
`;

export const GridHeader = styled(GridBody)`
   position: sticky !important;
   top: 0px !important;
   background-color: ${TextColors.SemanticWhite} !important;
   z-index: 1 !important;
`;
