import React from 'react';
import { Form, Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { handlePhoneNumberChange } from '../../Common/utils';
import { AddressInfoSection } from './AddressInfoSection';

const FormLabels = styled.label`
   display: inline;
   fontsize: 16px;
   font-weight: 400;
`;

const headerStyle = { color: '#1D4C8F' };

export const ContactInfoSection = ({
   userProfile,
   setUserProfile,
}) => {
   return (
      <Grid.Column width={5}>
         <Header as="h4" style={headerStyle}>
            Preferred Contact
         </Header>
         <FormLabels>Phone Number</FormLabels>
         <Form.Input
            style={{ marginTop: '8px', marginBottom: '16px' }}
            fluid
            placeholder="(XXX) - XXX - XXXX"
            value={userProfile.phoneNumber}
            required
            onChange={(e) =>
               setUserProfile({
                  ...userProfile,
                  phoneNumber: handlePhoneNumberChange(
                     e.target.value,
                     userProfile.phoneNumber,
                  ),
               })
            }
         />
         <AddressInfoSection
            userProfile={userProfile}
            setUserProfile={setUserProfile}
         />
      </Grid.Column>
   );
};
