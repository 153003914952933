import React from 'react';
import styled from 'styled-components';
import SearchBar from './SearchBar';
import TopNavigationActions from './TopNavigationActions';

const NavigationContainer = styled.div`
   display: flex;
`;

const TopNavigationBar = ({
   isLoading,
   setIsLoading,
   logout,
   userCustomData,
}) => {
   return (
      <div className="appbar box">
         <NavigationContainer>
            <SearchBar
               userCustomData={userCustomData}
               isLoading={isLoading}
               setIsLoading={setIsLoading}
            />
            <TopNavigationActions
               logout={logout}
               userCustomData={userCustomData}
            />
         </NavigationContainer>
      </div>
   );
};

export default TopNavigationBar;
