import styled from 'styled-components';
import { Popup } from 'semantic-ui-react';
import { InputColors } from '../Colors.js';

export const GreyPopupTop = styled(Popup)`
   background-color: ${InputColors.GreyDisabledBackground} !important;
   border-color: ${InputColors.GreyBorder} !important;
   box-shadow: 0px 0px 0px 0px !important;
   text-align: center !important;
   padding: 4px 4px !important;
   &:before {
      background-color: ${InputColors.GreyDisabledBackground} !important;
      border-right: 1px solid ${InputColors.GreyBorder} !important;
      border-bottom: 1px solid ${InputColors.GreyBorder} !important;
      box-shadow: 0px 0px 0px 0px !important;
   }
`;

export const GreyPopupBottom = styled(Popup)`
   background-color: ${InputColors.GreyDisabledBackground} !important;
   border-color: ${InputColors.GreyBorder} !important;
   box-shadow: 0px 0px 0px 0px !important;
   text-align: center !important;
   padding: 4px 4px !important;
   &:before {
      background-color: ${InputColors.GreyDisabledBackground} !important;
      border-left: 1px solid ${InputColors.GreyBorder} !important;
      border-top: 1px solid ${InputColors.GreyBorder} !important;
      box-shadow: 0px 0px 0px 0px !important;
   }
`;

export const GreyPopup = styled(Popup)`
   background-color: ${InputColors.GreyDisabledBackground} !important;
   border-color: ${InputColors.GreyBorder} !important;
   box-shadow: 0px 0px 0px 0px !important;
   text-align: center !important;
   padding: 4px 4px !important;
   &:before {
      background-color: ${InputColors.GreyDisabledBackground} !important;
      border-left: 1px solid ${InputColors.GreyBorder} !important;
      border-top: 1px solid ${InputColors.GreyBorder} !important;
      box-shadow: 0px 0px 0px 0px !important;
   }
`;
