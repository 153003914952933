import React from 'react';
import { Message, Grid } from 'semantic-ui-react';

export default function ChangeNpiTinErrorMsg() {
   return (
      <Grid.Row style={{ marginTop: '0px' }}>
         <Grid.Column>
            <Message className="greyAlert">
               <p>
                  Please contact your administrator to modify your NPI
                  / TIN.
               </p>
            </Message>
         </Grid.Column>
      </Grid.Row>
   );
}
