import React from 'react';
import { Button, Form, Segment, Message } from 'semantic-ui-react';
import styled from 'styled-components';
import {
   InputColors,
   TextColors,
} from '../Common/StyledComponents/Colors';

const FormLabels = styled.p`
   margin-bottom: 8px;
   text-align: left;
   font-family: 'Lato';
   font-style: normal;
   font-weight: 700;
   font-size: 14px;
   line-height: 18px;
`;

const FormContainer = styled.div`
   display: flex;
   flex-direction: column;
   gap: 24px;
   margin-bottom: 32px;
`;

const FormInputs = styled(Form.Input)`
   background-color: ${TextColors.SemanticWhite} !important;
   border: 1px solid ${InputColors.GreyBorder} !important;
   border-radius: 4px !important;
   border-bottom-style: none !important;
`;

const LoginFormInputs = ({
   formError,
   email,
   setEmail,
   password,
   setPassword,
   handleSubmit,
}) => {
   return (
      <Segment
         style={{
            border: '0',
            boxShadow: '0 0 0 0',
            margin: 0,
         }}
      >
         {formError && (
            <Message
               error
               header="Invalid Username or Password"
               content="Please check your username/password again."
               style={{ textAlign: 'left' }}
            />
         )}
         <FormContainer>
            <Form.Field style={{ margin: 0 }}>
               <FormLabels>Email</FormLabels>
               <FormInputs
                  fluid
                  placeholder="Your email"
                  value={email}
                  onChange={(event) =>
                     setEmail(
                        event.target.value
                           .toLowerCase()
                           .replace(/\s/g, ''),
                     )
                  }
               />
            </Form.Field>
            <Form.Field style={{ margin: 0 }}>
               <FormLabels>Password</FormLabels>
               <FormInputs
                  fluid
                  placeholder="Your password"
                  type="password"
                  value={password}
                  onChange={(event) =>
                     setPassword(
                        event.target.value.replace(/\s/g, ''),
                     )
                  }
               />
            </Form.Field>
         </FormContainer>
         <Button primary fluid size="large" onClick={handleSubmit}>
            Log In
         </Button>
      </Segment>
   );
};

export default LoginFormInputs;
