import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import ArqPagination from '../Common/StyledComponents/Pagination/ArqPagination';
import { updateSearchParams } from '../Common/arqUtils';
import { URL } from '../Common/Constants/URLConstants';

export default function MemberListPagination({ memberData, query }) {
   const history = useHistory();
   return (
      <ArqPagination
         startIdx={memberData.startIdx}
         endIdx={memberData.endIdx}
         numTotal={memberData.numTotal}
         handleClickLeft={() =>
            updateSearchParams(
               history,
               query,
               URL.AWV_CHASE_LIST,
               'page',
               Math.max(parseInt(query.page || 1) - 1 || 1, 1),
            )
         }
         handleClickRight={() =>
            updateSearchParams(
               history,
               query,
               URL.AWV_CHASE_LIST,
               'page',
               Math.min(
                  memberData.pageMax,
                  (parseInt(query.page) || 1) + 1,
               ),
            )
         }
      />
   );
}

MemberListPagination.propTypes = {
   memberData: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.object),
      endIdx: PropTypes.number,
      numTotal: PropTypes.number,
      pageIdx: PropTypes.number,
      pageMax: PropTypes.number,
      startIdx: PropTypes.number,
   }),
   query: PropTypes.object,
};
