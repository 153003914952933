import React, { useState, useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import { useStitchAuth } from '../StitchAuth';
import AppBar from '../Common/AppBar/AppBar';
import ContentTable from './ContentTable/ContentTable';
import '../Common/Layout.css';

const AwvChaseList = () => {
   const { userCustomData, otherUsers } = useStitchAuth();
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      if (userCustomData && otherUsers) {
         setLoading(false);
      }
   }, [userCustomData, otherUsers]);

   if (loading) {
      return (
         <div className="app-layout">
            <AppBar />
            <Loader active>Loading</Loader>
         </div>
      );
   } else {
      return (
         <div className="app-layout">
            <AppBar />
            <div
               style={{
                  gridColumn: 3,
                  color: '#333',
               }}
            >
               <ContentTable />
            </div>
         </div>
      );
   }
};

export default AwvChaseList;
