import React, { useState } from 'react';
import { Table, Icon } from 'semantic-ui-react';
import { BulkUserResultsTable } from '../../../Common/StyledComponents/Containers/Containers';
import { SuccessMessage } from '../../../Common/StyledComponents/Messages/Messages';
import { PERSONA } from '../../../Common/Constants/UserConstants';

const BulkUserRegisteredTable = ({ registrationResponse }) => {
   const [showRegisteredUsers, setShowRegisteredUsers] =
      useState(false);

   return (
      <>
         <SuccessMessage style={{ marginBottom: '24px' }}>
            <strong>
               Your List of Successfully Registered Users
            </strong>
            <span
               style={{ float: 'right', cursor: 'pointer' }}
               onClick={() =>
                  setShowRegisteredUsers(!showRegisteredUsers)
               }
            >
               {showRegisteredUsers ? (
                  <>
                     <strong>Collapse</strong>
                     <Icon name="angle up" />
                  </>
               ) : (
                  <>
                     <strong>Expand</strong>
                     <Icon name="angle down" />
                  </>
               )}
            </span>
         </SuccessMessage>
         {showRegisteredUsers ? (
            <BulkUserResultsTable>
               <Table basic="very" striped>
                  <Table.Header>
                     <Table.Row>
                        <Table.HeaderCell width={2}>
                           First Name
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                           Last Name
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                           NPI
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                           Personas
                        </Table.HeaderCell>
                        <Table.HeaderCell width={4}>
                           New user email
                        </Table.HeaderCell>
                        <Table.HeaderCell width={4}>
                           New user password
                        </Table.HeaderCell>
                     </Table.Row>
                  </Table.Header>
                  <Table.Body>
                     {registrationResponse.body?.registeredUsers.map(
                        (user, index) => {
                           return (
                              <Table.Row key={index}>
                                 <Table.Cell>
                                    {user.name.substring(
                                       0,
                                       user.name.indexOf(' '),
                                    )}
                                 </Table.Cell>
                                 <Table.Cell>
                                    {user.name.substring(
                                       user.name.indexOf(' '),
                                       user.name.length,
                                    )}
                                 </Table.Cell>
                                 <Table.Cell>{user.npi}</Table.Cell>
                                 <Table.Cell>
                                    {user.personas.includes(
                                       PERSONA.PROVIDER,
                                    ) ? (
                                       <>Provider</>
                                    ) : user.personas.includes(
                                         PERSONA.PR_REPS,
                                      ) ? (
                                       <>PR Rep</>
                                    ) : (
                                       // ) : user.personas.includes(PERSONA.EXECUTIVE) ? (
                                       //   <>Executive</>
                                       // ) : user.personas.includes(
                                       //     PERSONA.OFFICE_MANAGER,
                                       //   ) ? (
                                       //   <>Office Manager</>
                                       <>N/A</>
                                    )}
                                 </Table.Cell>
                                 <Table.Cell>{user.email}</Table.Cell>
                                 <Table.Cell>
                                    {user.password}
                                 </Table.Cell>
                              </Table.Row>
                           );
                        },
                     )}
                  </Table.Body>
               </Table>
            </BulkUserResultsTable>
         ) : null}
      </>
   );
};

export default BulkUserRegisteredTable;
