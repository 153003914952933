import styled from 'styled-components';
import { Blue, TextColors } from '../Colors.js';
import { memo } from 'react';

export const AcoLogoStyle = styled.p`
   padding: 0;
   font-family: 'PT Serif';
   font-style: normal;
   font-weight: 700;
   font-size: 30px;
   line-height: 18px;
`;

export const AcoLogoStyleBlue = styled(AcoLogoStyle)`
   color: ${Blue.BlueDark1};
`;

export const AcoLogoStyleWhite = styled(AcoLogoStyle)`
   color: ${TextColors.SemanticWhite};
`;

export const AcoLogoBlue = memo(() => (
   <AcoLogoStyleBlue>ACO Portal</AcoLogoStyleBlue>
));

export const AcoLogoWhite = memo(() => (
   <AcoLogoStyleWhite>ACO Portal</AcoLogoStyleWhite>
));
