import React, { useState, useEffect } from 'react';
import moment from 'moment';

const PrevAwvRecordsCell = ({ member }) => {
   const [recordsData, setRecordsData] = useState([]);

   useEffect(() => {
      if (member) {
         // If member.awv_prev_records is empty,
         // splitRecords = [''] &
         // formattedRecords = [{ recordString: '', code: '' }]
         // Resulting in nothing being rendered in the AWV Previous Records cell
         const splitRecords = (member.awv_prev_records || '').split(
            ';',
         );
         const formattedRecords = splitRecords.map((record) =>
            formatRecordsString(record),
         );

         setRecordsData(formattedRecords);
      }

      return () => {
         setRecordsData([]);
      };
   }, [member]);

   const formatRecordsString = (rawString) => {
      const formatOutput = { recordString: '', code: '' };

      if (!rawString) {
         return formatOutput;
      }

      const recordsRegex =
         /G(\d+) on (\d{4}-\d{2}-\d{2}) by ([\w\s-]+) ([\w\s-]+)/;
      const regexSplitArray = rawString.match(recordsRegex);

      const code = `G${regexSplitArray?.[1] ?? ''}`;
      const date = regexSplitArray?.[2]
         ? moment(regexSplitArray[2])
         : '';
      const firstName = regexSplitArray?.[3] ?? '';
      const lastName = regexSplitArray?.[4] ?? '';
      const formattedDate = moment.isMoment(date)
         ? date.tz('America/New_York').format('MM/DD/YY')
         : '';

      formatOutput.recordString = `${firstName} ${lastName} ${formattedDate}`;
      formatOutput.code = `(${code})`;

      return formatOutput;
   };

   return (
      <span>
         {recordsData.map((record, index) => (
            <div key={index}>
               {record.recordString} <strong>{record.code}</strong>
            </div>
         ))}
      </span>
   );
};

export default PrevAwvRecordsCell;
