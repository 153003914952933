import { Form, Grid, Header } from 'semantic-ui-react';
import { states } from '../../Common/Values';
import styled from 'styled-components';

import { handlePhoneNumberChange } from '../../Common/utils';
import { formatNumbersOnly } from '../../Common/arqUtils';

const FormLabels = styled.p`
   display: inline;
   fontsize: 16px;
`;

const RegisterContactForm = ({ newUser, setNewUser }) => {
   return (
      <Grid.Column width={5}>
         <Header as="h4">Contact</Header>
         <label>
            <FormLabels>Phone Number</FormLabels>
         </label>
         <Form.Field>
            <Form.Input
               style={{ marginTop: '8px', marginBottom: '16px' }}
               fluid
               name="phoneNumber"
               placeholder="Phone Number"
               value={newUser.phoneNumber}
               type="text"
               required
               onChange={(e) =>
                  setNewUser({
                     ...newUser,
                     phoneNumber: handlePhoneNumberChange(
                        e.target.value,
                        newUser.phoneNumber || '',
                     ),
                  })
               }
            />
         </Form.Field>
         <label>
            <FormLabels>Address 1</FormLabels>
         </label>
         <Form.Input
            style={{ marginTop: '8px', marginBottom: '16px' }}
            type="text"
            required
            fluid
            placeholder="Address 1"
            value={newUser.address1}
            onChange={(e) =>
               setNewUser({
                  ...newUser,
                  address1: e.target.value,
               })
            }
         />
         <label>
            <FormLabels>Address 2</FormLabels>
         </label>
         <Form.Input
            style={{ marginTop: '8px', marginBottom: '16px' }}
            type="text"
            required
            fluid
            placeholder="Address 2"
            value={newUser.address2}
            onChange={(e) =>
               setNewUser({
                  ...newUser,
                  address2: e.target.value,
               })
            }
         />
         <label>
            <FormLabels>City</FormLabels>
         </label>
         <Form.Input
            style={{ marginTop: '8px', marginBottom: '16px' }}
            type="text"
            required
            fluid
            placeholder="City"
            value={newUser.city}
            onChange={(e) =>
               setNewUser({
                  ...newUser,
                  city: e.target.value,
               })
            }
         />
         <label>
            <FormLabels>State</FormLabels>
         </label>
         <Form.Dropdown
            style={{ marginTop: '8px', marginBottom: '16px' }}
            fluid
            selection
            options={states}
            value={newUser.state}
            onChange={(e, { value }) =>
               setNewUser({
                  ...newUser,
                  state: value,
               })
            }
         />
         <label>
            <FormLabels>ZIP Code</FormLabels>
         </label>
         <Form.Input
            style={{ marginTop: '8px', marginBottom: '16px' }}
            type="text"
            required
            fluid
            maxLength="5"
            placeholder="ZIP (5 digit restricted)"
            value={newUser.zipCode}
            onChange={(e) =>
               setNewUser({
                  ...newUser,
                  zipCode: formatNumbersOnly(e.target.value),
               })
            }
         />
      </Grid.Column>
   );
};

export default RegisterContactForm;
