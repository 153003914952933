import React, { useState, useEffect } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import { useStitchAuth } from '../StitchAuth';
import { formatNumbersOnly } from '../Common/arqUtils';

const NpiForm = ({
   npi,
   setNpi,
   isNpiValid,
   setIsNpiValid,
   currentNpi,
}) => {
   const {
      userCustomData,
      db: { users },
   } = useStitchAuth();
   const [npiList, setNpiList] = useState([]);

   useEffect(() => {
      if (userCustomData) {
         users
            .find({
               npi: { $exists: true },
               id: { $ne: userCustomData.id },
            })
            .then((res) => {
               setNpiList(res.map((member) => member.npi));
            });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userCustomData, users]);

   const handleNpiChange = (event) => {
      if (
         npi !== '' &&
         npiList.filter((npi) => npi === event.target.value).length >
            0
      ) {
         setIsNpiValid(false);
      } else {
         setIsNpiValid(true);
      }
      setNpi(formatNumbersOnly(event.target.value));
   };

   const checkDisableNpi = () => {
      if (currentNpi !== '') {
         return true;
      } else {
         return false;
      }
   };

   return (
      <Grid.Row style={{ marginTop: '-28px' }}>
         <Grid.Column>
            {!isNpiValid ? (
               <label style={{ color: 'red' }}>
                  <p>NPI - DUPLICATE NPI EXISTS</p>
               </label>
            ) : (
               <label>
                  <p>NPI (10 digits)</p>
               </label>
            )}
            <Form.Field>
               <Form.Input
                  error={!isNpiValid}
                  style={{ marginTop: '8px', marginBottom: '16px' }}
                  type="text"
                  disabled={checkDisableNpi()}
                  required
                  fluid
                  placeholder="NPI Number"
                  value={npi}
                  onChange={handleNpiChange}
               />
            </Form.Field>
         </Grid.Column>
      </Grid.Row>
   );
};

export default NpiForm;
