import React from 'react';
import { Table } from 'semantic-ui-react';
import { BulkUserResultsTable } from '../../../Common/StyledComponents/Containers/Containers';
import { ErrorMessage } from '../../../Common/StyledComponents/Messages/Messages';

const BulkUserFailedRegistrationTable = ({
   registrationResponse,
}) => {
   return (
      <>
         <ErrorMessage style={{ marginBottom: '24px' }}>
            <p>
               <strong>Some Users Were Not Registered</strong>
               <br />
               The following users were not registered because their
               emails are already in use. To register these users,
               please try different emails.
            </p>
         </ErrorMessage>
         <BulkUserResultsTable>
            <Table basic="very" striped>
               <Table.Header>
                  <Table.Row>
                     <Table.HeaderCell width={2}>
                        First Name
                     </Table.HeaderCell>
                     <Table.HeaderCell width={2}>
                        Last Name
                     </Table.HeaderCell>
                     <Table.HeaderCell width={2}>
                        NPI
                     </Table.HeaderCell>
                     <Table.HeaderCell width={2}>
                        Personas
                     </Table.HeaderCell>
                     <Table.HeaderCell width={4}>
                        New user email
                     </Table.HeaderCell>
                     <Table.HeaderCell width={4}>
                        {/* This cell is for spacing to match this table with the
                successfully-registered table */}
                     </Table.HeaderCell>
                  </Table.Row>
               </Table.Header>
               <Table.Body>
                  {registrationResponse.body?.failedUsers.map(
                     (user, index) => {
                        return (
                           <Table.Row key={index}>
                              <Table.Cell>
                                 {user.name.substring(
                                    0,
                                    user.name.indexOf(' '),
                                 )}
                              </Table.Cell>
                              <Table.Cell>
                                 {user.name.substring(
                                    user.name.indexOf(' '),
                                    user.name.length,
                                 )}
                              </Table.Cell>
                              <Table.Cell>{user.npi}</Table.Cell>
                              <Table.Cell>
                                 {user.personas.includes(
                                    'executive',
                                 ) ? (
                                    <>Executive</>
                                 ) : user.personas.includes(
                                      'care-manager',
                                   ) ? (
                                    <>Care Manager</>
                                 ) : user.personas.includes(
                                      'provider',
                                   ) ? (
                                    <>Provider</>
                                 ) : user.personas.includes(
                                      'pr-reps',
                                   ) ? (
                                    <>PR Rep</>
                                 ) : (
                                    <>N/A</>
                                 )}
                              </Table.Cell>
                              <Table.Cell>{user.email}</Table.Cell>
                           </Table.Row>
                        );
                     },
                  )}
               </Table.Body>
            </Table>
         </BulkUserResultsTable>
      </>
   );
};

export default BulkUserFailedRegistrationTable;
