import React, { useState } from 'react';
import styled from 'styled-components';
import {
   Button,
   Form,
   Grid,
   Header,
   Segment,
   Message,
} from 'semantic-ui-react';
import { AcoLogoBlue } from './Common/StyledComponents/Logos/AcoLogo';
import { useStitchAuth } from './StitchAuth';
import LoginMorning from './Common/Background/login-morning.svg';
import LoginAfternoon from './Common/Background/login-afternoon.svg';
import LoginEvening from './Common/Background/login-evening.svg';

const MessageLabel = styled.div`
   background-color: #eaf4ff;
   border: 1px solid #c4dbf2;
   border-radius: 4px;
   padding: 12px 8px;
   color: #1d4c8f;
   margin-bottom: 18px;
   margin-top: 30px;
   font-family: 'Lato';
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 18px;
   text-align: left;
`;

const PasswordResetForm = (props) => {
   const [formError, setFormError] = useState(false);
   const [email, setEmail] = useState('');
   const [formSuccess, setFormSuccess] = useState(false);
   const { actions } = useStitchAuth();

   function handleBackgroundSelection(currTime) {
      if (currTime >= 5 && currTime < 12) {
         return {
            img: LoginMorning,
            msg: 'Good Morning!',
            color: '#7E174F',
         };
      } else if (currTime >= 18 || (currTime >= 0 && currTime < 5)) {
         return {
            img: LoginEvening,
            msg: 'Good Evening!',
            color: '#FFFFFF',
         };
      } else {
         return {
            img: LoginAfternoon,
            msg: 'Good Afternoon!',
            color: '#1D4C8F',
         };
      }
   }

   const handleSubmit = () => {
      actions
         .handleResetPasswordSend(email)
         .then(() => {
            setFormSuccess(true);
            setEmail('');
         })
         .catch(() => {
            setFormError(true);
            console.log('something went wrong...');
         });
   };

   return (
      <div>
         <Grid
            style={{
               backgroundImage: `url(${
                  handleBackgroundSelection(new Date().getHours()).img
               })`,
               backgroundSize: 'cover',
               height: '100vh',
               margin: 0,
            }}
         >
            <Grid.Row columns={2}>
               <Grid.Column
                  style={{
                     backgroundColor: '#fff',
                     textAlign: 'center',
                     maxWidth: 430,
                     height: formSuccess ? 490 : 413,
                     padding: '3em',
                     margin: '0 auto',
                  }}
                  textAlign="left"
                  verticalAlign="middle"
               >
                  <Header
                     as="h2"
                     icon
                     textAlign="center"
                     style={{ marginBottom: '1em' }}
                  >
                     <AcoLogoBlue />
                     <Header.Content
                        style={{
                           fontWeight: 300,
                           textAlign: 'left',
                           marginTop: '1em',
                        }}
                     >
                        Reset your password
                     </Header.Content>
                  </Header>
                  <Form size="large" error={formError}>
                     <Segment
                        style={{ border: '0', boxShadow: '0 0 0 0' }}
                     >
                        <Message
                           error
                           header="Invalid Username or Password"
                           content="Please check your username/password again."
                        />
                        <Form.Input
                           style={{
                              marginBottom: '1.2em',
                              outline: '0.0625em',
                              borderWidth: '0.0625em',
                              borderRadius: '0.0625em',
                           }}
                           icon="user"
                           iconPosition="left"
                           placeholder="E-mail address"
                           value={email}
                           onChange={(event) =>
                              setEmail(
                                 event.target.value
                                    .toLowerCase()
                                    .replace(/\s/g, ''),
                              )
                           }
                        />
                        <Button
                           style={{ width: '150px' }}
                           primary
                           fluid
                           size="medium"
                           onClick={handleSubmit}
                        >
                           Reset Password
                        </Button>
                     </Segment>
                     {formSuccess ? (
                        <MessageLabel>
                           Password reset email sent. <br /> Check
                           your email and follow the link provided.
                        </MessageLabel>
                     ) : null}
                  </Form>
               </Grid.Column>
            </Grid.Row>
         </Grid>
      </div>
   );
};

export default PasswordResetForm;
