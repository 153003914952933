// React
import React from 'react';
import { StitchAuthProvider, useStitchAuth } from './StitchAuth';
import { StitchMembersProvider } from './StitchMembers';
import {
   BrowserRouter as Router,
   Switch,
   Route,
   Redirect,
} from 'react-router-dom';
import 'semantic-ui-less/semantic.less';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { GeneralParamsProvider } from './GeneralParams';
import { UserBasicInformation } from './Agreement/UserBasicInformation/UserBasicInformation';

// Components & Hooks
import Settings from './Settings/Settings';
import UserInformation from './Agreement/UserInformation';
import Login from './Login/Login';
import Signup from './Signup';
import ConfirmEmail from './ConfirmEmail';
import PasswordReset from './PasswordReset';
import PasswordResetSend from './PasswordResetSend';
import AwvChaseList from './AwvChaseList/AwvChaseList';
// import PrvdrViewADTList from './PrvdrViewADTList/PrvdrViewADTList';
import Home from './Home';
import RegisterUsers from './RegisterUsers/RegisterUsers';
// END TODO

App.propTypes = {};
export default function App() {
   return (
      <StitchAuthProvider>
         <StitchMembersProvider>
            <GeneralParamsProvider>
               <Router>
                  <Switch>
                     <Route path="/confirmemail">
                        <ConfirmEmail />
                     </Route>
                     <Route path="/passwordreset">
                        <PasswordReset />
                     </Route>
                     <Route path="/passwordresetsend">
                        <PasswordResetSend />
                     </Route>
                     <Route path="/signup/:client">
                        <Signup />
                     </Route>
                     <Route path="/signup">
                        <Signup />
                     </Route>
                     <Route path="/login/:client">
                        <Login />
                     </Route>
                     <Route path="/login">
                        <Login />
                     </Route>
                     {/* 
              TODO: Hidden until ACO confirms if
              feature is needed
              <PrivateRoute path="/document-hub">
                <DocumentHub />
              </PrivateRoute> */}
                     <PrivateRoute path="/register-users">
                        <RegisterUsers />
                     </PrivateRoute>
                     {/** There is a redirect in Home.js that needs to match this path */}
                     <PrivateRoute path="/userinformation">
                        {/* TODO:  Updating component to NewGroupInformation or remove it completely*/}
                        <UserInformation />
                     </PrivateRoute>
                     <PrivateRoute path="/userbasicinformation">
                        <UserBasicInformation />
                     </PrivateRoute>
                     <PrivateRoute path="/settings">
                        <Settings />
                     </PrivateRoute>
                     <PrivateRoute path="/prvdrview-awv-chase-list">
                        <AwvChaseList />
                     </PrivateRoute>
                     <PrivateRoute path="/home/:viewDefault">
                        <Home />
                     </PrivateRoute>
                     <PrivateRoute path="/home">
                        <Home />
                     </PrivateRoute>
                     <Route path="*">
                        <Redirect to={{ pathname: '/login' }} />
                     </Route>
                  </Switch>
               </Router>
            </GeneralParamsProvider>
         </StitchMembersProvider>
      </StitchAuthProvider>
   );
}

function PrivateRoute({ children, ...rest }) {
   const { isLoggedIn, clientName } = useStitchAuth();

   if (isLoggedIn) {
      return <Route {...rest} render={() => children} />;
   } else {
      return (
         <Route
            {...rest}
            render={({ location }) => (
               <Redirect
                  to={{
                     pathname: '/login/' + clientName,
                     state: { from: location },
                  }}
               />
            )}
         />
      );
   }
}
