import React from 'react';
import './Common/Layout.css';
import { useStitchAuth } from './StitchAuth';
import { Redirect } from 'react-router-dom';
import Login from './Login/Login';

export default function Home() {
   const { isLoggedIn, userCustomData } = useStitchAuth();

   if (isLoggedIn) {
      if (userCustomData && !userCustomData.hasBasicInformation) {
         return <Redirect to="/userinformation" />;
      } else {
         return <Redirect to="/prvdrview-awv-chase-list" />;
      }
   } else {
      return <Login />;
   }
}
