import { useLocation } from 'react-router-dom';

// TODO: Move to StringUtils.js or memberUtils.js
/** String Util functions */
export function numberWithCommas(x) {
   if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
   } else {
      return '.';
   }
}

export function toTitleCase(x) {
   return x.replace(/\w*/g, function (txt) {
      return (
         txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      );
   });
}

export function validateEmail(email) {
   const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   return re.test(String(email).toLowerCase());
}

export const formatName = (name) => {
   // get name suffix if there is one.
   let suffix = '';
   const nameTokens = name.replace(/\s\s+/g, ' ').trim().split(' ');
   if (
      ['sr.', 'sr', 'jr.', 'jr'].includes(
         nameTokens[nameTokens.length - 1].toLowerCase(),
      )
   ) {
      suffix = nameTokens[nameTokens.length - 1];
      nameTokens.pop();
   }

   const firstName = nameTokens[0];
   const lastName = nameTokens[nameTokens.length - 1];
   let formattedName = `${lastName}, ${firstName}`;
   // append middle initials if there are any
   if (nameTokens.length > 2) {
      const middleInitial = nameTokens
         .slice(1, nameTokens.length - 1)
         .map((x) => x[0].toUpperCase())
         .join('. ');
      formattedName = `${lastName}, ${firstName}, ${middleInitial}.`;
   }

   // append suffix if there was one
   if (suffix) {
      return `${formattedName} ${suffix}`;
   } else {
      return formattedName;
   }
};

/** End String Util functions */

// TODO: Refactor this to a StitchPhoneNumber or PhoneNumberService file along with a PhoneNumberUtils file
// TODO: Updating all phoneNumber components to use this function (ex. formMember, editProfile, etc...)
/** Phone Number API and util functions */
export function toDomesticPhoneFormat(phone) {
   if (phone && typeof phone === 'string') {
      return (
         phone.substring(2, 5) +
         '-' +
         phone.substring(5, 8) +
         '-' +
         phone.substring(8, 12)
      );
   } else {
      return '';
   }
}

export function getMMDDYYYY(date) {
   return new Date(date).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
   });
}

export function handlePhoneNumberFormat(phoneNumber) {
   const numOnly = phoneNumber.replace(/(\D+)/g, '');
   let phoneFormat = numOnly.slice(0, 3);
   if (numOnly.length >= 3) {
      phoneFormat = phoneFormat + '-' + numOnly.slice(3, 6);
   }
   if (numOnly.length >= 6) {
      phoneFormat = phoneFormat + '-' + numOnly.slice(6, 10);
   }
   return phoneFormat;
}

export const handlePhoneNumberChange = (value, currentPhone) => {
   let newPhoneNumber;
   const lastCharIsDeleted = value.length < currentPhone.length;
   const lastCharIsADash =
      value.length > 0 && value[value.length - 1] === '-';

   if (lastCharIsDeleted && lastCharIsADash) {
      newPhoneNumber = value.slice(0, value.length - 1);
   } else if (lastCharIsDeleted && !lastCharIsADash) {
      newPhoneNumber = value;
   } else {
      newPhoneNumber = handlePhoneNumberFormat(value);
   }
   return newPhoneNumber;
};
/** End Phone Number API and util functions */

/** NavigationUtils */
export function useQuery() {
   return new URLSearchParams(useLocation().search);
}
/** End NavigationUtils */

// TODO: Move to DateTimeUtils
/** DateTimeUtils */
export function getYMRange() {
   const startYear = 2019;
   const startMonth = 1;
   const endYear = new Date().getYear() + 1900;
   const endMonth = new Date().getMonth() + 1;
   const ymList = [];
   for (let year = startYear; year <= endYear; year++) {
      const mStart = year === startYear ? startMonth : 1;
      const mEnd = year === endYear ? endMonth : 12;
      for (let month = mStart; month <= mEnd; month++) {
         const mStr = month < 10 ? '0' + month : '' + month;
         const key = year + '-' + mStr;
         ymList.push({ key: key, text: key, value: key });
      }
   }
   return ymList.reverse();
}

export const formatDate = (valueOld, valueNew) => {
   // See: MemberDetails/ManualEntry.js

   let valueFormatted = valueOld;
   const mm = parseInt(valueNew.substring(0, 2));
   const dd = parseInt(valueNew.substring(3, 5));
   const yyyy = parseInt(valueNew.substring(6, 10));
   if (valueOld.length > valueNew.length) {
      // delete operation
      valueFormatted = valueNew;
      if (valueOld.length === 6 || valueOld.length === 3) {
         valueFormatted = valueNew.substring(0, valueNew.length - 1);
      }
   } else if (!isNaN(valueNew[valueNew.length - 1])) {
      if (mm <= 12) {
         if (mm > 1 && mm < 10) {
            valueFormatted = '0' + mm;
         } else {
            valueFormatted = valueNew.substring(0, 2);
         }
      }
      if (valueFormatted.length === 2) {
         valueFormatted += '/';
      }
      if (valueNew.length >= 3) {
         if (dd <= 31) {
            if (dd > 3 && dd < 10) {
               valueFormatted += '0' + dd;
            } else {
               if (mm === 2) {
                  if (dd <= 29) {
                     valueFormatted += valueNew.substring(3, 5);
                  } else {
                     valueFormatted += '28';
                  }
               } else {
                  valueFormatted += valueNew.substring(3, 5);
               }
            }
         }
      }
      if (valueFormatted.length === 5) {
         valueFormatted += '/';
      }
      if (valueNew.length >= 6) {
         if (yyyy < 2100) {
            valueFormatted += '' + yyyy;
         }
      }
   }
   return valueFormatted;
};

export const formatTime = (valueOld, valueNew) => {
   let valueFormatted = valueOld;
   const hh = parseInt(valueNew.substring(0, 2));
   const mm = parseInt(valueNew.substring(3, 5));
   const amOrPm = valueNew.substring(6, 8).toUpperCase();
   if (valueOld.length > valueNew.length) {
      // delete operation
      valueFormatted = valueNew;
      if (valueOld.length === 6 || valueOld.length === 3) {
         valueFormatted = valueNew.substring(0, valueNew.length - 1);
      }
   } else if (
      !isNaN(valueNew[valueNew.length - 1]) ||
      /A(?:M)?|P(?:M)?/.test(amOrPm)
   ) {
      if (hh <= 23) {
         if (hh > 2 && hh < 10) {
            valueFormatted = '0' + hh;
         } else {
            valueFormatted = valueNew.substring(0, 2);
         }
      }
      if (valueFormatted.length === 2) {
         valueFormatted += ':';
      }
      if (valueNew.length >= 3) {
         if (mm <= 59) {
            valueFormatted += valueNew.substring(3, 5);
         }
      }
      if (valueFormatted.length === 5) {
         valueFormatted += ' ';
      }
      if (valueNew.length >= 6) {
         if (
            amOrPm === 'AM' ||
            amOrPm === 'PM' ||
            amOrPm === 'A' ||
            amOrPm === 'P'
         ) {
            valueFormatted += amOrPm;
         }
      }
   }
   return valueFormatted;
};

/** End DateTimeUtils */
