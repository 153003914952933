import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const LogoItem = styled.div`
   padding: 0 0 0.5em 1.1em;
   cursor: pointer;
   display: flex;
   flex-direction: row;
   gap: 25px;
   align-items: center;
   width: 100%;
   height: 38px;
   color: #fff;
`;

const MenuItem = styled.h4`
   padding: 1em 0 1em;
   color: #fff;
   margin: 0 15px;
   display: flex;
`;

export const UserRegistration = ({ logout, Logo }) => {
   return (
      <>
         <div className="appbar box">
            <div style={{ float: 'right' }}>
               <div style={{ marginLeft: '1em' }}>
                  <Link
                     to="/login"
                     onClick={logout}
                     style={{
                        color: '#bdc9e8',
                        height: '30px',
                        lineHeight: '30px',
                     }}
                  >
                     Logout
                  </Link>
               </div>
            </div>
         </div>
         <div id="menu" className="channels">
            <div>
               <LogoItem>
                  <Logo />
               </LogoItem>
               <MenuItem id="menu-item" className="menu-item">
                  <span className="menu-item-text">
                     User Registration
                  </span>
               </MenuItem>
            </div>
         </div>
      </>
   );
};
