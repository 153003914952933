import React from 'react';
import PropTypes from 'prop-types';
import {
   hasLoggedInUser,
   logoutCurrentUser,
   getCurrentUser,
   getCustomData,
   signupUser,
   loginUser,
   sendResetPasswordEmail,
   resetPassword,
   confirmEmail,
} from './../realm/authentication';
import { getBrowserMetadata } from './Common/ActivityData/activityDataUtils';
import { getDefaultClientCode } from './Common/whitelabel';
import {
   LOGIN_EVENTS,
   USER_BASIC_INFORMATION_EVENTS,
   PAGE_NAMES,
} from './Common/ActivityData/ActivityDataEvents';

// Create a React Context that lets us expose and access auth state
// without passing props through many levels of the component tree
const StitchAuthContext = React.createContext();

// Create a React Hook that lets us get data from our auth context
export function useStitchAuth() {
   const context = React.useContext(StitchAuthContext);
   if (!context) {
      throw new Error(
         `useStitchAuth must be used within a StitchAuthProvider`,
      );
   }
   return context;
}

// Create a component that controls auth state and exposes it via
// the React Context we created.
export function StitchAuthProvider(props) {
   const [authState, setAuthState] = React.useState({
      isLoggedIn: hasLoggedInUser(),
      currentUser: getCurrentUser(),
      userCustomData: null,
      clientName: getDefaultClientCode(),
      otherUsers: [],
      db: {
         members: null,
         users: null,
         groups: null,
         downloads: null,
         providers: null,
      },
   });

   // We useMemo to improve performance by eliminating some re-renders
   const authInfo = React.useMemo(() => {
      // Authentication Actions
      const handleLogout = async () => {
         const { isLoggedIn } = authState;
         if (isLoggedIn) {
            await logoutCurrentUser();
            setAuthState({
               ...authState,
               isLoggedIn: false,
               currentUser: null,
               userCustomData: null,
               otherUsers: [],
            });
         } else {
            console.log(
               `can't handleLogout when no user is logged in`,
            );
         }
      };

      const handleSignup = async (email, password) => {
         await signupUser(email, password);
      };

      const handleUserAdd = async (
         name,
         namePublic,
         idx,
         userCustomData,
      ) => {
         const customData = {
            name: name,
            namePublic: namePublic,
            timeZone: userCustomData.timeZone,
            role: 'user',
            group_id: userCustomData.group_id,
            id: authState.currentUser.id + '::' + idx,
            emailNotifications: {
               periodicAP: false,
               periodicMP: false,
               realtimeAP: false,
               realtimeMP: false,
            },
            email: 'not.available',
         };
         await authState.db.users.insertOne(customData);
      };

      const handleUserAccountConfirmation = async (
         data,
         password,
      ) => {
         try {
            const recipient = [data.email];
            const emailBody = `
        Your account has been generated for ${data.name}'s Pathways by ApolloMed platform.
        You can access the platform at
        <a href="https://providers.apaaco.net/login">
          https://providers.apaaco.net/login
        </a>
        <br/>
        <label>Below are your login credentials:</label>
        <br />
        <ul style="list-style-type: none">
          <li><b>Username: ${data.email}</b></li>
          <li><b>Password: ${password}</b></li>
        </ul>
        <br />
        Please change your password at <a href="https://providers.apaaco.net/passwordreset">https://providers.apaaco.net/passwordreset</a> as soon as you receive this email.
        <br />
        <br />Thank you!`;

            currentUser.functions.sendEmailAws(
               'Pathways by ApolloMed <noreply-pathways@apollomed.net>',
               recipient,
               `Your Enrollment Information for Pathways by ApolloMed Platform`,
               emailBody,
            );
            return true;
         } catch {
            return false;
         }
      };

      const handleBulkSignup = async (bulkUserData) => {
         const registrationData = {
            bulkUserData: bulkUserData,
         };

         try {
            const response =
               await currentUser.functions.handleUserRegistration(
                  authState.userCustomData,
                  registrationData,
                  getBrowserMetadata(), // Send browser metadata for downstream activity data
               );

            return response;
         } catch (error) {
            console.log(error);
            return {
               success: false,
               errorMsg: 'failed to create users',
            };
         }
      };

      const handleSingleSignup = async (userData, password) => {
         const registrationData = {
            singleUserData: userData,
            userPassword: password,
         };

         try {
            const response =
               await currentUser.functions.handleUserRegistration(
                  authState.userCustomData,
                  registrationData,
                  getBrowserMetadata(), // Send browser metadata for downstream activity data
               );

            return response;
         } catch (error) {
            console.log(error);
            return {
               success: false,
               errorMsg: 'failed to create user',
            };
         }
      };

      const handleConfirmEmail = async (token, tokenId) => {
         confirmEmail(token, tokenId);
      };

      const handleEmailPasswordLogin = async (email, password) => {
         const loggedInUser = await loginUser(email, password);
         setAuthState({
            ...authState,
            isLoggedIn: true,
            currentUser: loggedInUser,
            db: {
               members: loggedInUser
                  .mongoClient('mongodb-atlas')
                  .db('pathwaysAco')
                  .collection('members'),
               users: loggedInUser
                  .mongoClient('mongodb-atlas')
                  .db('pathwaysAco')
                  .collection('users'),
               groups: loggedInUser
                  .mongoClient('mongodb-atlas')
                  .db('pathwaysAco')
                  .collection('groups'),
               downloads: loggedInUser
                  .mongoClient('mongodb-atlas')
                  .db('pathwaysAco')
                  .collection('downloads'),
               providers: loggedInUser
                  .mongoClient('mongodb-atlas')
                  .db('pathwaysAco')
                  .collection('pathways_providers'),
            },
         });

         handleLogActivityData(
            {
               name: LOGIN_EVENTS.LOGIN_SUCCESS,
               pageName: PAGE_NAMES.LOGIN,
            },
            loggedInUser,
         );
      };

      const handleResetPasswordSend = async (email) => {
         sendResetPasswordEmail(email);
      };

      const handleResetPassword = async (
         token,
         tokenId,
         newPassword,
      ) => {
         resetPassword(token, tokenId, newPassword);
      };

      const handleRefreshCustomData = async (clientCode) => {
         const customData = await getCustomData(clientCode);
         if (customData) {
            const otherUsers = await getOtherUsers(
               customData.group_id,
            );

            setAuthState({
               ...authState,
               userCustomData: customData,
               clientName: customData.clientName
                  ? customData.clientName
                  : 'orma',
               otherUsers: otherUsers,
               db: {
                  members: authState.currentUser
                     .mongoClient('mongodb-atlas')
                     .db('pathwaysAco')
                     .collection('members'),
                  users: authState.currentUser
                     .mongoClient('mongodb-atlas')
                     .db('pathwaysAco')
                     .collection('users'),
                  groups: authState.currentUser
                     .mongoClient('mongodb-atlas')
                     .db('pathwaysAco')
                     .collection('groups'),
                  providers: authState.currentUser
                     .mongoClient('mongodb-atlas')
                     .db('pathwaysAco')
                     .collection('providers'),
                  downloads: authState.currentUser
                     .mongoClient('mongodb-atlas')
                     .db('pathwaysAco')
                     .collection('downloads'),
               },
            });
         }
      };

      const updateCustomData = async (customData) => {
         setAuthState({
            ...authState,
            userCustomData: {
               ...authState.userCustomData,
               ...customData,
            },
            clientName: customData.clientName
               ? customData.clientName
               : 'orma',
         });
         await authState.db.users.findOneAndUpdate(
            { id: authState.currentUser.id },
            { $set: customData },
         );
      };

      const updateAgreement = async (agreement) => {
         await authState.db.groups.findOneAndUpdate(
            { group_id: authState.userCustomData.group_id },
            { $set: agreement },
         );

         await authState.db.users.findOneAndUpdate(
            { id: authState.currentUser.id },
            {
               $set: {
                  name: agreement.agreement.contactName,
                  namePublic: agreement.agreement.contactName,
               },
            },
         );

         setAuthState({
            ...authState,
            userCustomData: {
               ...authState.userCustomData,
               ...agreement,
               name: agreement.agreement.contactName,
               namePublic: agreement.agreement.contactName,
            },
         });
      };

      const handleUserRegistration = async (newUser) => {
         try {
            const response =
               await authState.db.users.findOneAndUpdate(
                  {
                     id: authState.currentUser.id,
                  },
                  {
                     $set: {
                        ...newUser,
                        confirmedBasicInfo: true,
                     },
                  },
               );
            if (!response?.error && !response?.errorMsg) {
               // Log event data when user has form validation success
               handleLogActivityData({
                  name: USER_BASIC_INFORMATION_EVENTS.SUBMIT_REQUEST_SUCCESS,
                  pageName: PAGE_NAMES.USER_BASIC_INFORMATION_PAGE,
               });

               await handleRefreshCustomData();
               return { success: true, errorMsg: null };
            }
         } catch (error) {
            // Log event data when user has form validation error
            handleLogActivityData({
               name: USER_BASIC_INFORMATION_EVENTS.SUBMIT_REQUEST_FAILURE,
               pageName: PAGE_NAMES.USER_BASIC_INFORMATION_PAGE,
            });
            return { success: false, errorMsg: { ...error } };
         }
      };

      // TODO: Verify what other users is used for
      const getOtherUsers = async (groupId) => {
         const usersDb = await authState.currentUser
            .mongoClient('mongodb-atlas')
            .db('pathwaysAco')
            .collection('users');

         return await usersDb.find({ group_id: groupId });
      };

      const handleWorkspaceChange = async (groupIdNew) => {
         await authState.db.users.updateOne(
            { id: authState.currentUser.id },
            { $set: { group_id: groupIdNew } },
         );
         await handleRefreshCustomData();
      };

      const handlePersonaChange = async (selectedPersona) => {
         await authState.db.users.updateOne(
            { id: authState.currentUser.id },
            { $set: { currentPersona: selectedPersona } },
         );
         await handleRefreshCustomData();
      };

      const handleDocDownload = async (filename, category) => {
         const timeNow = new Date();
         const newDownload = {
            id: userCustomData.id,
            email: userCustomData.email,
            npi: userCustomData.npi,
            path: filename,
            category: category,
            group_id: userCustomData.group_id,
            timeDownloaded: timeNow.toISOString(),
         };

         await authState.db.downloads.insertOne(newDownload);
         await handleRefreshCustomData();
      };

      /**
       * Takes in a string input and calls the search
       * function to find matches with member name or MBI
       * @param {String} searchStr
       * @returns {Object} containing array searchResults
       */
      const handleMemberSearchProvider = async (searchStr) => {
         try {
            const searchResponse =
               await authState.currentUser.functions.handleMemberSearchProvider(
                  searchStr,
               );
            return searchResponse;
         } catch (searchError) {
            console.error(searchError);
            return { searchResults: [] };
         }
      };

      /**
       * Takes in a formatted searchRequest and calls
       * back-end ADT fetch function to retrieve ADT
       * documents for the ADT feed
       * @param {Object} searchRequest
       * @returns {Object} containing search data / error
       */
      const handleFetchAdtFeed = async (searchRequest) => {
         try {
            const adtRequest =
               await authState.currentUser.functions.fetchAdts(
                  searchRequest,
               );

            if (adtRequest.errorMsg) {
               throw adtRequest.errorMsg;
            }

            return { success: true, data: adtRequest.response };
         } catch (fetchError) {
            console.error(fetchError);
            return { success: false };
         }
      };

      /**
       * Takes in a formatted searchRequest and calls
       * back-end member fetch function to retrieve member
       * documents for AWV Chase List
       * @param {Object} searchRequest
       * @returns {Object} containing search data / error
       */
      const handleFetchMembers = async (searchRequest) => {
         try {
            const memberRequest =
               await authState.currentUser.functions.fetchMembers(
                  searchRequest,
               );

            if (memberRequest.errorMsg) {
               throw memberRequest.errorMsg;
            }

            return { success: true, data: memberRequest.response };
         } catch (fetchError) {
            console.error(fetchError);
            return {
               success: false,
            };
         }
      };

      /**
       * Takes in a string member ID and calls
       * back-end member fetch function to retrieve a member
       * document for the Member Profile
       * @param {String} memberId
       * @returns {Object} containing search data / error
       */
      const handleFetchMemberSingle = async (memberId) => {
         try {
            const memberRequest =
               await authState.currentUser.functions.fetchMemberSingle(
                  memberId,
               );

            if (memberRequest.errorMsg) {
               throw memberRequest.errorMsg;
            }

            return { success: true, data: memberRequest.response };
         } catch (fetchError) {
            console.error(fetchError);
            return {
               success: false,
            };
         }
      };

      /**
       * Takes in a string awvOption (complete / incomplete) and calls
       * back-end member fetch function to retrieve a paf
       * document for all members for a provider
       * @param {String} awvOption
       * @returns {Object} containing search data / error
       */
      const handleFetchPafAllMembers = async (requestQuery) => {
         try {
            const pafRequest =
               await authState.currentUser.functions.fetchPafAllMembers(
                  requestQuery,
               );

            if (pafRequest.errorMsg) {
               throw pafRequest.errorMsg;
            }

            return { success: true, data: pafRequest.response };
         } catch (fetchError) {
            console.error(fetchError);
            return {
               success: false,
            };
         }
      };

      /**
       * Takes in a string memberId and calls
       * back-end member fetch function to retrieve a paf
       * document for the given member
       * @param {String} memberId
       * @returns {Object} containing search data / error
       */
      const handleFetchPafSingleMember = async (memberId) => {
         try {
            const pafRequest =
               await authState.currentUser.functions.fetchPafSingleMember(
                  memberId,
               );

            if (pafRequest.errorMsg) {
               throw pafRequest.errorMsg;
            }

            return { success: true, data: pafRequest.response };
         } catch (fetchError) {
            console.error(fetchError);
            return {
               success: false,
            };
         }
      };

      /**
       * Takes in nothing and calls
       * back-end member fetch function to retrieve a paf
       * document for the given member
       * @returns {Array} containing search data / error
       */
      const handleFetchProviders = async () => {
         try {
            const providerRequest =
               await authState.currentUser.functions.fetchProviders();

            if (providerRequest.errorMsg) {
               throw providerRequest.errorMsg;
            }

            return { success: true, data: providerRequest.response };
         } catch (fetchError) {
            console.error(fetchError);
            return {
               success: false,
            };
         }
      };

      /**
       *
       * @param {object} eventData - {name: "eventName", pageName: "pageName"}
       * @param {*} currentUser - Defaults to auth state. Used when user hasn't been logged in yet and / or
       * the authState variable is not set due to asynchronous state updates
       * @returns {success: true, data: request response}
       */
      const handleLogActivityData = async (
         eventData,
         currentUser = authState.currentUser,
      ) => {
         const newActivityData = {
            ...eventData,
            ...getBrowserMetadata(),
         };
         try {
            const logActivityRequest =
               await currentUser.functions.handleLogActivityData(
                  newActivityData,
               );

            if (logActivityRequest.errorMsg) {
               throw logActivityRequest.errorMsg;
            }

            return {
               success: true,
               data: logActivityRequest.response,
            };
         } catch (postError) {
            console.error(postError);
            return {
               success: false,
            };
         }
      };

      /**
       *
       * This is a separate function from handleLogActivityData because it occurs
       * before the user has logged in
       *
       * @param {object} eventData - {name: "eventName", pageName: "pageName"}
       * @returns {success: true, data: request response}
       */
      const handleLogPasswordReset = async (
         eventData,
         currentUser = authState.currentUser,
      ) => {
         const newActivityData = {
            ...eventData,
            ...getBrowserMetadata(),
         };
         try {
            const logActivityRequest =
               await currentUser.functions.handleLogPasswordReset(
                  newActivityData,
               );

            if (logActivityRequest.errorMsg) {
               throw logActivityRequest.errorMsg;
            }

            return {
               success: true,
               data: logActivityRequest.response,
            };
         } catch (postError) {
            console.error(postError);
            // Given current authentication settings, this should fail
            return {
               success: true,
            };
         }
      };
      const {
         isLoggedIn,
         currentUser,
         userCustomData,
         clientName,
         otherUsers,
         db,
      } = authState;
      const value = {
         isLoggedIn,
         currentUser,
         userCustomData,
         clientName,
         otherUsers,
         db,
         actions: {
            handleLogout,
            handleSignup,
            handleConfirmEmail,
            handleEmailPasswordLogin,
            handleResetPasswordSend,
            handleResetPassword,
            handleRefreshCustomData,
            updateCustomData,
            updateAgreement,
            handleUserRegistration,
            getOtherUsers,
            handleBulkSignup,
            handleSingleSignup,
            handleUserAdd,
            handleUserAccountConfirmation,
            handleDocDownload,
            handleMemberSearchProvider,
            handleWorkspaceChange,
            handlePersonaChange,
            handleFetchAdtFeed,
            handleFetchMembers,
            handleFetchMemberSingle,
            handleFetchPafAllMembers,
            handleFetchPafSingleMember,
            handleFetchProviders,
            handleLogActivityData,
            handleLogPasswordReset,
         },
      };
      return value;
   }, [authState]);
   return (
      <StitchAuthContext.Provider value={authInfo}>
         {props.children}
      </StitchAuthContext.Provider>
   );
}
StitchAuthProvider.propTypes = {
   children: PropTypes.element,
};
