import React from 'react';
import { Modal } from 'semantic-ui-react';

const SettingsChangedModal = ({ changed, setChanged }) => {
   return (
      <Modal
         open={changed}
         basic
         onClose={() => setChanged(false)}
         closeIcon
         size="small"
      >
         <Modal.Header style={{ textAlign: 'center' }}>
            Your settings have been updated!
         </Modal.Header>
      </Modal>
   );
};

export default SettingsChangedModal;
